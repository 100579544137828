import styled from 'styled-components';
import ASSETS from 'assets';
import NotFound from './NotFound';

const SavedProfiles = () => {
  return (
    <Container className='2xl:pt-[33px] pt-5'>
      {false ? (
        <NotFound
          icon={ASSETS.saved}
          message='You don’t have any saved profiles yet'
        />
      ) : (
        <JobsSection>
          <Job className='rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'>
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.user1} alt='' />
            </div>
            <div>
              <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                Winsel Viene
              </p>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                Graphic Designer
              </p>
              <p className='2xl:text-base text-sm'>5 hours ago</p>
            </div>
          </Job>
          <Job className='rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'>
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.video} alt='' />
            </div>
            <div>
              <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                John Doe
              </p>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                UX UI Designer
              </p>
              <p className='2xl:text-base text-sm'>2 days ago</p>
            </div>
          </Job>
          <Job className='rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'>
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.other} alt='' />
            </div>
            <div>
              <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                David Lin
              </p>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                UX UI Expert
              </p>
              <p className='2xl:text-base text-sm'>3 days ago</p>
            </div>
          </Job>
          <Job className='rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'>
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={ASSETS.user2} alt='' />
            </div>
            <div>
              <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                Jacob Carter
              </p>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                Senior UX UI Designer
              </p>
              <p className='2xl:text-base text-sm'>3 days ago</p>
            </div>
          </Job>
        </JobsSection>
      )}
    </Container>
  );
};

export default SavedProfiles;

const Container = styled.div`
  max-width: 820px;
  width: 100%;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const JobsSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Job = styled.div`
  display: flex;
  align-items: center;
`;

import React, { useEffect, useId, useState } from 'react';

import dayjs from 'dayjs';
import ClickAwayListener from 'react-click-away-listener';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { uploadJobDocs } from 'http/jobService';

const UploadDocuments = () => {
  const resumePicker = useId();
  const coverLetterPicker = useId();

  const navigate = useNavigate();

  const [resume, setResume] = useState<File | null | string>(null);
  const [resumeMenu, setResumeMenu] = useState(false);
  const [coverLetter, setCoverLetter] = useState<File | null | string>(null);
  const [coverLetterMenu, setCoverLetterMenu] = useState(false);
  const [loading, setLoading] = useState(false);

  const { job } = useSelector((state: RootState) => state.job);
  const { user } = useSelector((state: RootState) => state.auth);

  const toggleResumeMenu = () => setResumeMenu(!resumeMenu);
  const closeResumeMenu = () => setResumeMenu(false);
  const toggleCoverLetterMenu = () => setCoverLetterMenu(!coverLetterMenu);
  const closeCoverLetterMenu = () => setCoverLetterMenu(false);

  const handleDeleteResume = () => {
    setResume(null);
    closeResumeMenu();
  };

  const handleResume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setResume(file);
      e.currentTarget.value = '';
    }
  };

  const handleCoverLetter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setCoverLetter(file);
      e.currentTarget.value = '';
    }
  };

  const handleDeleteCoverLetter = () => {
    setCoverLetter(null);
    closeCoverLetterMenu();
  };

  const handleNext = async () => {
    try {
      setLoading(true);
      if (job) {
        const id = job.id;
        const formData = new FormData();
        if (user?.role !== 'A') {
          if (resume) formData.append('resume', resume);
          if (coverLetter) formData.append('cover', coverLetter);
        }
        formData.append('job_id', id);
        await uploadJobDocs(formData);
        navigate('/apply/job-questions');
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.role === 'A') {
      setResume(user.userDetails?.cv_url || null);
      setCoverLetter(user.userDetails?.cover_url || null);
    }
  }, [user]);

  return (
    <div className='w-full flex-grow'>
      <div className='mx-auto w-full max-w-[776px] flex flex-col'>
        <div className='border-[0.5px] border-grey-600 rounded-[12px] bg-white 2xl:py-11 py-6 2xl:px-[30px] px-5'>
          {resume ? (
            <div className='flex items-center justify-between w-full 2xl:mb-[83px] mb-0'>
              <div>
                <p className='2xl:text-xl text-base font-medium'>
                  {typeof resume !== 'string'
                    ? resume?.name
                    : `${user?.first_name}${user?.last_name}(${user?.job_title}).pdf`}
                </p>
                <p className='text-sm text-placeholder mt-3'>
                  Uploaded on{' '}
                  {dayjs(user?.userDetails?.created_at).format('MMM DD, YYYY')}
                </p>
              </div>
              <div className=' relative'>
                <div
                  className='wrapper cursor-pointer'
                  onClick={toggleResumeMenu}
                >
                  <img src={ASSETS.menu} alt='' />
                </div>
                {resumeMenu && (
                  <ClickAwayListener onClickAway={closeResumeMenu}>
                    <div
                      className='absolute rounded right-0 cursor-pointer bg-white w-[171px] flex items-center gap-2 px-6 h-11 shadow-popup'
                      onClick={handleDeleteResume}
                    >
                      <img src={ASSETS.delete} alt='' />
                      <p className='text-sm font-medium'>Delete </p>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
          ) : (
            <p className='2xl:text-xl text-lg font-medium'>Resume Upload*</p>
          )}
          <div className='flex flex-col items-center'>
            <label
              htmlFor={
                !!resume || (user?.role === 'A' && !!resume) ? '' : resumePicker
              }
              className='w-full max-w-[344px] mx-auto mt-7 cursor-pointer'
            >
              <Button
                label='Upload Document'
                className='2xl:!text-22 !text-lg 2xl:!h-14 !w-full  !pointer-events-none '
                disabled={(user?.role === 'A' && !!resume) || !!resume}
              />
              <input
                type='file'
                name=''
                id={resumePicker}
                className='hidden'
                accept='.doc,.docx,.pdf'
                onChange={handleResume}
              />
            </label>
            <p className='2xl:text-xl text-base font-medium mt-4'>
              DOC, DOCX, PDF (5MB)
            </p>
          </div>
        </div>
        <div className='bg-white border-[0.5px] border-grey-600 p-9 flex flex-col items-center  rounded-[12px] mt-3'>
          {coverLetter ? (
            <div className='2xl:mt-[30px] mt-0 flex items-center justify-between w-full'>
              <div>
                <p className='2xl:text-xl text-lg font-medium'>
                  {typeof coverLetter !== 'string'
                    ? coverLetter?.name
                    : `${user?.first_name}${user?.last_name}(${user?.job_title}).pdf`}
                </p>
                <p className='text-sm text-placeholder mt-3'>
                  Uploaded on {dayjs().format('MMM DD, YYYY')}
                </p>
              </div>
              <div className=' relative'>
                <div
                  className='wrapper cursor-pointer'
                  onClick={toggleCoverLetterMenu}
                >
                  <img src={ASSETS.menu} alt='' />
                </div>
                {coverLetterMenu && (
                  <ClickAwayListener onClickAway={closeCoverLetterMenu}>
                    <div
                      className='absolute rounded right-0 cursor-pointer bg-white w-[171px] flex items-center gap-2 px-6 h-11 shadow-popup'
                      onClick={handleDeleteCoverLetter}
                    >
                      <img src={ASSETS.delete} alt='' />
                      <p className='text-sm font-medium'>Delete </p>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
          ) : (
            <p className='2xl:text-xl text-lg w-full font-medium'>
              Cover Letter / attachment{' '}
              <span className='text-placeholder italic'>(Optional)</span>
            </p>
          )}

          <label
            htmlFor={
              !!coverLetter || (user?.role === 'A' && !!coverLetter)
                ? ''
                : coverLetterPicker
            }
            className='w-full max-w-[344px] mx-auto mt-7 cursor-pointer'
          >
            <Button
              label='Upload Document'
              className='2xl:!text-22 !text-lg 2xl:!h-14 !w-full !pointer-events-none '
              disabled={(user?.role === 'A' && !!coverLetter) || !!coverLetter}
            />
            <input
              type='file'
              name=''
              id={coverLetterPicker}
              className='hidden'
              accept='.doc,.docx,.pdf'
              onChange={handleCoverLetter}
            />
          </label>
          <p className='2xl:text-xl text-base font-medium mt-4'>
            DOC, DOCX, PDF (5MB)
          </p>
        </div>
        <div className='2xl:mt-[32px] mt-4 px-12'>
          <Button
            label='Next'
            className='!w-full 2xl:!h-[56px] 2xl:!text-22 !text-lg'
            onClick={handleNext}
            disabled={!resume}
            loading={loading}
          />
        </div>
        <div className='2xl:h-11 h-0'></div>
      </div>
    </div>
  );
};

export default UploadDocuments;

import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import DialogLayout from 'Dialogs/DialogLayout';
import Button from 'components/Button/Button';
import ASSETS from 'assets';

const ActionDialog = () => {
  const dispatch = useDispatch<Dispatch>();

  const { actionDialog, actionLoading } = useSelector(
    (state: RootState) => state.utils
  );

  const closeDialog = () => dispatch.utils.closeActionDialog();
  return (
    <DialogLayout open={actionDialog?.open || false}>
      <div className='w-full 2xl:max-w-[484px] max-w-[430px] bg-grey-100 shadow-popup rounded-[16px] 2xl:py-[30px] p-6 2xl:px-6'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={closeDialog}
            alt=''
          />
        </div>
        <p className='2xl:text-32 text-2xl text-primary text-center font-medium'>
          {actionDialog?.title}
        </p>
        <p className='2xl:text-lg text-base mt-[18px] text-center'>
          {actionDialog?.message}
        </p>
        <Button
          label={actionDialog?.buttonTitle || ''}
          className='w-full 2xl:mt-10 mt-8 2xl:!text-22 !text-lg'
          onClick={actionDialog?.onClick}
          loading={actionLoading}
        />
      </div>
    </DialogLayout>
  );
};

export default ActionDialog;

import classNames from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';
import { ClipLoader } from 'react-spinners';

import styled from 'styled-components';

type variant = 'primary' | 'outlined';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  variant?: variant;
  className?: string;
  leftIcon?: string;
  leftIconClassName?: string;
  loading?: boolean;
}

const Button: FC<IProps> = props => {
  const {
    label,
    variant = 'primary',
    className,
    leftIcon,
    leftIconClassName,
    loading,
    disabled,
    ...rest
  } = props;
  return (
    <StyledButton
      className={classNames(
        'flex items-center gap-3 justify-center disabled:cursor-not-allowed disabled:!opacity-[0.46] 2xl:h-[56px] h-[48px] 2xl:text-base text-sm',
        variant,
        className
      )}
      {...rest}
      disabled={loading || disabled}
    >
      {loading ? (
        <>
          <ClipLoader size={25} color='#ffffff' />
        </>
      ) : (
        <>
          {leftIcon && (
            <img src={leftIcon} className={leftIconClassName} alt='' />
          )}
          {label}
        </>
      )}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  padding: 0px 30px;
  font-weight: 500;
  border-radius: 100vh;
  &.primary {
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    &:focus {
      border-color: ${({ theme }) => theme.colors.primary};
    }
  }
  &.outlined {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    border: 2px solid ${({ theme }) => theme.colors.secondary};
  }
`;

import React, { FC, useEffect, useState } from 'react';
import DialogLayout from 'Dialogs/DialogLayout';

import { TimeClock, TimeView } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { convertTo12HourFormat } from 'utils';
import classNames from 'classnames';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTime: React.Dispatch<React.SetStateAction<string>>;
}

const TimePicker: FC<IProps> = ({ open, setOpen, setTime }) => {
  const [value, setValue] = useState(dayjs());
  const [view, setView] = useState<TimeView>('hours');
  const [hour, setHour] = useState<number>(0);
  const [minute, setMinute] = useState<number>(0);
  const [ampm, setAmpm] = useState<'am' | 'pm'>('am');

  const breakPoint = useCurrentBreakpoint();

  const handleView = (v: TimeView) => {
    setView(v);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    const time = `${hour}:${
      minute < 10 ? `0${minute}` : minute
    } ${ampm.toUpperCase()}`;
    setTime(time);
    handleClose();
  };

  useEffect(() => {
    const adjustedHours = convertTo12HourFormat(value.hour());
    setHour(adjustedHours.hour);
    setAmpm(adjustedHours.period as typeof ampm);
    setMinute(value.minute());
  }, [value]);

  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[490px] max-w-[420px] bg-white 2xl:p-9 p-5 rounded-md'>
        <p className='text-xl mb-[35px]'>Select time</p>
        <div className='flex items-center justify-between'>
          <button
            onClick={() => handleView('hours')}
            className='2xl:h-[120px] h-[100px] 2xl:w-[143px] w-[110px] bg-[#21212114] 2xl:text-[84px] text-[56px] text-center rounded-[6px]'
          >
            {hour}
          </button>
          <span className='2xl:text-[84px] text-[56px]'>:</span>
          <button
            onClick={() => handleView('minutes')}
            className='2xl:h-[120px] h-[100px] 2xl:w-[143px] w-[110px] bg-[#21212114] 2xl:text-[84px] text-[56px] text-center rounded-[6px]'
          >
            {minute < 10 ? `0${minute}` : minute}
          </button>
          <div className='w-[77px] 2xl:h-[120px] h-[100px]  rounded-md border border-grey-400 flex flex-col ml-[13px]'>
            <div
              className={classNames(
                'grid place-items-center  flex-grow cursor-pointer',
                ampm === 'am' && 'bg-[#21212114]'
              )}
              onClick={() => setAmpm('am')}
            >
              <p
                className={classNames(
                  'text-xl font-medium text-[#00000099]',
                  ampm === 'am' && '!text-primary'
                )}
              >
                AM
              </p>
            </div>
            <div
              className={classNames(
                'grid place-items-center border-t border-t-grey-400 flex-grow cursor-pointer',
                ampm === 'pm' && 'bg-[#21212114]'
              )}
              onClick={() => setAmpm('pm')}
            >
              <p
                className={classNames(
                  'text-xl font-medium text-[#00000099]',
                  ampm === 'pm' && '!text-primary'
                )}
              >
                PM
              </p>
            </div>
          </div>
        </div>
        <div className='2xl:my-20 my-8'>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimeClock
              value={value}
              onChange={newValue => setValue(newValue)}
              view={view}
              onViewChange={handleView}
              sx={{ scale: breakPoint === 'xl' ? '1.1' : '1.5' }}
              ampm
            />
          </LocalizationProvider>
        </div>
        <div className='flex justify-end gap-2'>
          <button
            className='px-[7px] min-w-[95px] text-primary font-medium 2xl:text-2xl text-xl'
            onClick={handleClose}
          >
            CANCEL
          </button>
          <button
            className='px-[7px] min-w-[95px] text-primary font-medium 2xl:text-2xl text-xl'
            onClick={handleOk}
          >
            OK
          </button>
        </div>
      </div>
    </DialogLayout>
  );
};

export default TimePicker;

import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  IApplyJobPayload,
  ICreateJobPayload,
  IGetJobByIdPayload,
  IJobDetails,
} from 'types/modals';
import { IJobQuestion } from 'pages/CreateJob/JobQuestions';
import { applyJob, createJob, getJobById } from 'http/jobService';
import { jobApplyQuestions, jobCreationQuestions } from 'app.config';
import { IJob, JobDetails } from 'types/interfaces';
import { chatAuth } from 'http/authService';

interface IState {
  loading: boolean;
  details: IJobDetails | null;
  questions: IJobQuestion | null;
  selectedJob: string;
  job: IJob | null;
  applyQuestionDetails: JobDetails | null;
  applicant_id: string;
}

export const job = createModel<RootModel>()({
  name: 'job',
  state: {
    loading: false,
    details: null,
    questions: null,
    selectedJob: '',
    job: null,
    applyQuestionDetails: null,
    applicant_id: '',
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setDetails(state, payload: IJobDetails | null) {
      state.details = payload;
    },
    setQuestions(state, payload: IJobQuestion | null) {
      state.questions = payload;
    },
    setSelectedJob(state, payload: string) {
      state.selectedJob = payload;
    },
    setJob(state, payload: IJob | null) {
      state.job = payload;
    },
    setAppyJobQuestionDetails(state, payload: JobDetails | null) {
      state.applyQuestionDetails = payload;
    },
    setApplicantId(state, payload: string) {
      state.applicant_id = payload;
    },
  },
  effects: dispatch => ({
    async handleCreateJob(payload: ICreateJobPayload) {
      const { navigate, values } = payload;
      try {
        dispatch.job.setLoading(true);
        const { data } = await createJob(values);
        const job_id = data.data.id;
        dispatch.job.setSelectedJob(job_id);
        dispatch.utils.setPath('create-job');
        dispatch.video.setData(jobCreationQuestions);
        navigate('/create-video');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.job.setLoading(false);
      }
    },
    async handleGetJobById(payload: IGetJobByIdPayload) {
      const { id, onSuccess } = payload;
      try {
        dispatch.job.setLoading(true);
        const { data } = await getJobById(id);
        dispatch.job.setJob(data.data);
        if (onSuccess) onSuccess();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.job.setLoading(false);
      }
    },
    async handleApplyJob(payload: IApplyJobPayload, state) {
      const { navigate, values } = payload;
      const id = state.job.applicant_id;
      const user = state.auth.user;
      try {
        dispatch.job.setLoading(true);
        if (!user?.agora_user_name) {
          await chatAuth();
        }
        const { data } = await applyJob(values, id);
        dispatch.utils.setPath('apply');
        dispatch.video.setData(jobApplyQuestions);
        dispatch.job.setApplicantId(data.data.id);
        navigate('/create-video');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.job.setLoading(false);
      }
    },
  }),
});

import ASSETS from 'assets';
import React from 'react';

const SelectedApplication = () => {
  return (
    <div className='h-full'>
      <p className='2xl:text-xl text-lg font-bold 2xl:mt-9 mt-6'>
        Received Applications -&gt; John Doe
      </p>
      <div className='attachment mt-4'>
        <p className='2xl:text-lg text-base font-medium leading-7'>Resume</p>
        <div className='border-[0.5px] mt-2 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px] bg-white'>
          <img src={ASSETS.pdf} className='w-6 2xl:w-auto' alt='' />
          <p className='text-sm font-medium'>John_Doe (UX UI Designer). pdf</p>
        </div>
      </div>
      <div className='attachment mt-[11px]'>
        <p className='2xl:text-lg text-base font-medium leading-7'>
          Cover Letter/ Attachment
        </p>
        <div className='border-[0.5px] mt-2 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px] bg-white'>
          <img src={ASSETS.word} className='w-6 2xl:w-auto' alt='' />
          <p className='text-sm font-medium'>John_Doe (Cover Letter). doc</p>
        </div>
      </div>
      <div className='flex items-center justify-between mt-6'>
        <p className='text-base font-medium'>Expected Salary Range</p>
        <p className='text-base font-medium w-[12ch]'>$100k-$350k</p>
      </div>
      <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
      <div className='flex flex-col gap-[18px]'>
        <p className='text-base font-medium leading-5'>Work Authorization</p>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Are you authorized to work in the United States?
          </p>
          <p className='text-base font-medium leading-5 w-[12ch]'>Yes</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Do you now or in the future require a working visa sponsorship?
          </p>
          <p className='text-base font-medium leading-5 w-[12ch]'>No</p>
        </div>
      </div>
      <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
      <div className='flex flex-col gap-[18px]'>
        <p className='text-base font-medium leading-5'>Government Employment</p>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Are you now or a former government employee?
          </p>
          <p className='text-base font-medium leading-5 w-[12ch]'>No</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Are you now or a former government employee?
          </p>
          <p className='text-base font-medium leading-5 w-[12ch]'>No</p>
        </div>
      </div>
      <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
      <div className='flex flex-col gap-[18px]'>
        <p className='text-base font-medium leading-5'>Diversity & Inclusion</p>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Do you have any disability?
          </p>
          <p className='text-base font-medium leading-5  w-[12ch]'>
            Prefer not to say
          </p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Do you identify as a veteran?
          </p>
          <p className='text-base font-medium leading-5  w-[12ch]'>No</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            What is your racial background?
          </p>
          <p className='text-base font-medium leading-5  w-[12ch]'>Asian</p>
        </div>
      </div>
      <div className='mt-[21px] mb-[14px] h-[1px] bg-grey-600'></div>
      <div className='flex flex-col gap-[18px]'>
        <p className='text-base font-medium leading-5 '>Employment History</p>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Have you previously worked for our company?
          </p>
          <p className='text-base font-medium leading-5  w-[12ch]'>Yes</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Have you worked for any affiliate companies?
          </p>
          <p className='text-base font-medium leading-5  w-[12ch]'>No</p>
        </div>
        <div className='flex justify-between items-center'>
          <p className='text-base font-medium leading-5'>
            Please enter name of referral
          </p>
          <p className='text-base font-medium leading-5 w-[12ch]'>
            Nielson Garmen
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectedApplication;

import React from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Button from 'components/Button/Button';

import ASSETS from 'assets';
import { introQuestions } from 'app.config';

const IntroVideo = () => {
  const navigate = useNavigate();
  const dispatch: Dispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);

  const handleIntroVideo = () => {
    if (!user?.introVideos?.length) {
      dispatch.utils.setPath('profile');
      dispatch.video.setData(introQuestions);
      navigate('/create-video');
    }
  };

  const handleNext = () => {
    navigate('/make-profile/step-3');
  };
  return (
    <Container className='2xl:pb-[50px] pb-2'>
      <div className='bg-white flex flex-col items-center 2xl:px-11 px-6 2xl:pt-11 pt-4 pb-4 border-[0.5px] border-grey-600 rounded-[12px] '>
        <div className='2xl:size-[180px] size-[120px] rounded-full overflow-hidden border-[2px] border-black'>
          <img
            src={user?.image_url || ASSETS.userImg}
            className='w-full h-full object-cover'
            alt=''
          />
        </div>
        <p className='2xl:text-2xl text-lg font-medium 2xl:mt-11 mt-3 2xl:mb-5 mb-2'>
          Video Introduction{' '}
          <span className='text-placeholder italic'>(Optional)</span>
        </p>
        {user?.introVideos && user?.introVideos.length > 0 ? (
          <div className='w-full 2xl:max-w-[416px] 2xl:h-[470px] max-w-[280px] h-[300px] border  border-placeholder rounded-[12px] overflow-hidden'>
            <video
              src={user.introVideos[0].url}
              className='w-full h-full object-contain'
              controls
            ></video>
          </div>
        ) : (
          <div
            className='w-full 2xl:max-w-[416px] 2xl:h-[470px] max-w-[280px] h-[300px] border border-dashed border-placeholder rounded-[12px] bg-grey-100 flex justify-center items-center cursor-pointer'
            onClick={handleIntroVideo}
          >
            <img
              src={ASSETS.plusCircle}
              className='cursor-pointer w-10'
              alt=''
            />
          </div>
        )}
        <Button
          label='Next'
          className='!w-full 2xl:!h-[56px] 2xl:!text-22 !text-lg 2xl:mt-[20px] mt-4'
          onClick={handleNext}
        />
      </div>
    </Container>
  );
};

export default IntroVideo;

const Container = styled.div`
  width: 100%;
  max-width: 776px;
  margin-inline: auto;
`;

// import SortByDialog from './SortByDialog';
// import AreaOfExperties from './AreaOfExperties';
// import CompanySize from './CompanySize';
// import JobLevel from './JobLevel';
// import JobType from './JobType';
// import SalleryRange from './SalleryRange';
// import Location from './Location';
// import JobTitle from './JobTitle';
// import Tags from './Tags';
// import CompanyName from './CompanyName';

const FilterDialogs = () => {
  return (
    <>
      {/* <CompanySize />
      <JobLevel />
      <JobType />
      <SalleryRange />
      <Location />
      <JobTitle />
      <Tags /> */}
      {/* <CompanyName /> */}
    </>
  );
};

export default FilterDialogs;

import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { IBlockedUser, IReportedJob } from 'types/api';
import { getBlockedUsers, getReportedUsers } from 'http/userService';
import { getReportedJobs } from 'http/jobService';

interface IState {
  type: 'user' | 'company' | 'job';
  id: string;
  blockedUsers: IBlockedUser[];
  reportedUser: IBlockedUser[];
  reportedJobs: IReportedJob[];
  loading: boolean;
}

export const reports = createModel<RootModel>()({
  name: 'reports',
  state: {
    type: 'user',
    id: '',
    blockedUsers: [],
    reportedUser: [],
    reportedJobs: [],
    loading: false,
  } as IState,
  reducers: {
    setType(state, payload: 'user' | 'company' | 'job') {
      state.type = payload;
    },
    setId(state, payload: string) {
      state.id = payload;
    },
    setBlockedUsers(state, payload: IBlockedUser[]) {
      state.blockedUsers = payload;
    },
    setReportedUser(state, payload: IBlockedUser[]) {
      state.reportedUser = payload;
    },
    setReportedJobs(state, payload: IReportedJob[]) {
      state.reportedJobs = payload;
    },
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
  effects: dispatch => ({
    async handleGetBlockedUsers() {
      try {
        dispatch.reports.setLoading(true);
        const { data } = await getBlockedUsers();
        dispatch.reports.setBlockedUsers(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.reports.setLoading(false);
      }
    },
    async handleGetReportedUsers() {
      try {
        dispatch.reports.setLoading(true);
        const { data } = await getReportedUsers();
        dispatch.reports.setReportedUser(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.reports.setLoading(false);
      }
    },
    async handleGetReportedJobs() {
      try {
        dispatch.reports.setLoading(true);
        const { data } = await getReportedJobs();
        dispatch.reports.setReportedJobs(data.data);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.reports.setLoading(false);
      }
    },
  }),
});

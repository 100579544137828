import React, { FC } from 'react';

import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';
import Button from 'components/Button/Button';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SaveProgress: FC<IProps> = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <DialogLayout open={open}>
      <div className='w-full max-w-[504px] bg-grey-100 rounded-[16px] p-6'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={handleClose}
            alt=''
          />
        </div>
        <p className='text-center text-primary text-32 font-medium mt-6'>
          Are you sure you want to leave?{' '}
        </p>
        <p className='text-lg text-center mt-[10px]'>
          You can save your progress and continue later
        </p>
        <Button
          label='Save Video'
          className='w-full !text-22 mt-[30px] mb-[35px]'
          onClick={handleClose}
        />
      </div>
    </DialogLayout>
  );
};

export default SaveProgress;

import React, { FC, useEffect, useId, useState } from 'react';

import ASSETS from 'assets';

interface IProps {
  image?: File | string | null;
  setImage?: React.Dispatch<React.SetStateAction<File | string | null>>;
}

const ImagePicker: FC<IProps> = ({ image, setImage }) => {
  const picker = useId();
  const [img, setImg] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      const file = files[0];
      if (setImage) {
        setImage(file);
      } else {
        setImg(URL.createObjectURL(file));
      }
    }
  };

  useEffect(() => {
    if (image) {
      if (typeof image === 'string') {
        setImg(image);
      } else {
        setImg(URL.createObjectURL(image));
      }
    }
  }, [image]);

  return (
    <label
      htmlFor={picker}
      className='2xl:max-w-[162px] 2xl:max-h-[162px] max-w-[130px] max-h-[130px]   rounded-full w-full !aspect-square'
    >
      <div className='w-full h-full rounded-full relative '>
        <img
          src={
            img
              ? typeof img === 'string'
                ? img
                : URL.createObjectURL(img)
              : ASSETS.preview
          }
          className='size-full aspect-square object-cover rounded-full bg-grey-400'
          alt=''
        />
        <div className='absolute border-[2px] border-secondary bg-white h-7 w-7 rounded-full grid place-items-center right-[10px] bottom-[10px] cursor-pointer'>
          <img src={ASSETS.plusYellow} alt='' />
        </div>
      </div>
      <input
        type='file'
        id={picker}
        onChange={handleChange}
        className='hidden'
        accept='image/jpg, image/png, image/jpeg'
      />
    </label>
  );
};

export default ImagePicker;

import React, { FC, useId } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import DialogLayout from 'Dialogs/DialogLayout';
import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { useNavigate } from 'react-router-dom';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onRecordFromCamera: () => void;
}

const UploadOrRecordVideo: FC<IProps> = ({
  open,
  setOpen,
  onRecordFromCamera,
}) => {
  const navigate = useNavigate();
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch<Dispatch>();

  const { selected } = useSelector((state: RootState) => state.video);

  const picker = useId();

  const handleRecordFromCamera = () => {
    onRecordFromCamera();
    handleClose();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files?.length) {
      const file = files[0];
      console.log(file);
      if (selected) {
        dispatch.video.setMedia({ id: selected.id, value: file });
        navigate('/video-trimmer');
      }
    }
  };

  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[484px] max-w-[440px] bg-grey-100 rounded-[12px] 2xl:p-6 p-5 2xl:pb-9 pb-7'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={handleClose}
            alt=''
          />
        </div>
        <p className='2xl:text-32 text-2xl text-primary font-medium text-center 2xl:mt-8 mt-4'>
          Upload or Record a Video
        </p>
        <div className='mt-7'>
          <label htmlFor={picker} className=''>
            <div className='max-w-[339px] mx-auto rounded-[30px] 2xl:h-[56px] h-12 flex items-center  bg-primary gap-3 2xl:px-[68px] px-[75px] cursor-pointer'>
              <img src={ASSETS.gallery} alt='' />
              <p className='text-white 2xl:text-base text-sm'>
                Upload from Gallery
              </p>
            </div>
            <input
              type='file'
              name=''
              id={picker}
              className='hidden'
              accept='video/*'
              onChange={handleChange}
            />
          </label>
        </div>
        <Button
          label='Record Video'
          leftIcon={ASSETS.camera}
          variant='outlined'
          className='w-full max-w-[339px] mx-auto mt-3 2xl:!px-[68px] !px-[75px] !justify-start 2xl:gap-3 gap-5'
          onClick={handleRecordFromCamera}
        />
      </div>
    </DialogLayout>
  );
};

export default UploadOrRecordVideo;

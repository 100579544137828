import ASSETS from 'assets';
import Button from 'components/Button/Button';
import React from 'react';

const BlockedUser = () => {
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center gap-5'>
        <img
          src={ASSETS.userImg}
          className='2xl:size-[50px] size-[45px] object-cover rounded-full'
          alt=''
        />
        <p className='2xl:text-xl text-lg font-medium'>Annette Black</p>
      </div>
      <Button
        variant='outlined'
        label='UNBLOCK'
        className='2xl:!h-10 !h-9 !text-sm'
      />
    </div>
  );
};

const BlockedUsers = () => {
  return (
    <div className='flex-grow 2xl:mt-11 mt-6 h-0 overflow-auto flex flex-col gap-4'>
      {Array.from({ length: 7 }, (_, i) => i).map(i => (
        <BlockedUser key={i} />
      ))}
    </div>
  );
};

export default BlockedUsers;

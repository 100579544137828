const SETTINGS_ICONS = {
  language: require('./lang.svg').default,
  languageActive: require('./lang-active.svg').default,
  about: require('./about.svg').default,
  aboutActive: require('./about-active.svg').default,
  safety: require('./safety.svg').default,
  safetyActive: require('./safety-active.svg').default,
  faq: require('./faq.svg').default,
  faqActive: require('./faq-active.svg').default,
  helpCenter: require('./help-center.svg').default,
  helpCenterActive: require('./help-center-active.svg').default,
  email: require('./email.svg').default,
  emailActive: require('./email-active.svg').default,
  block: require('./block.svg').default,
  blockActive: require('./block-active.svg').default,
  warning: require('./warning.svg').default,
  warningActive: require('./warning-active.svg').default,
};

export default SETTINGS_ICONS;

import React from 'react';
import ASSETS from 'assets';
import Accordion from './Accordion';

const config = [
  'Profile & Account',
  'Billng & Subscriptions',
  'Safety & Privacy',
  'Features',
  'Tips & Tricks',
  'How to navigate through the application',
];

const HelpCenter = () => {
  return (
    <div className='2xl:px-6 px-4 2xl:py-9 py-5'>
      <p className='2xl:text-2xl text-xl font-bold'>Help Center</p>
      <div className='flex flex-col gap-[5px] mt-4'>
        {config.map(p => (
          <div
            key={p}
            className='flex items-center justify-between 2xl:h-12 h-10 border-b-[0.5px] border-grey-600 px-2 bg-grey-100 cursor-pointer'
          >
            <p className='2xl:text-lg text-base font-medium'>{p}</p>
            <img src={ASSETS.arrowRight} alt='' />
          </div>
        ))}
      </div>
      <p className='text-lg font-medium 2xl:mt-[34px] mt-5 2xl:mb-[38px] mb-6'>
        Profile & Account
      </p>
      <div className='flex flex-col 2xl:gap-5 gap-4'>
        <Accordion
          question='Upload a Photo'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
        <Accordion
          question='Why was my photo removed'
          answer='Your photo was removed from Final Round due to a violation of our content guidelines. Our platform maintains strict standards to ensure a safe and respectful environment for all users. Any content uploaded by users, such as resumes or job postings, must comply with copyright laws and not infringe on the rights of others.'
        />
        <Accordion
          question='Updating your location'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
        <Accordion
          question='Updating Profile'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
        <Accordion
          question='Profile Tips'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
      </div>
    </div>
  );
};

export default HelpCenter;

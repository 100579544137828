import styled from 'styled-components';
import NotFound from './NotFound';
import ASSETS from 'assets';
import Button from 'components/Button/Button';

const Interviews = () => {
  return (
    <Container className='2xl:pt-[33px] pt-5'>
      {false ? (
        <NotFound message='A Final Round interview has not been set up yet. Keep applying!' />
      ) : (
        <JobsSection>
          {false ? (
            <div className='bg-white shadow-mm rounded-lg w-full max-w-[587px] mx-auto pt-10 py-14 flex flex-col items-center'>
              <img src={ASSETS.handshake} alt='' />
              <p className='text-32 text-primary font-medium'>
                You’re all set!
              </p>
              <p className='text-base mt-6'>
                Employer Name:{' '}
                <span className='font-medium'>Crabite Games</span>
              </p>
              <p className='text-base mt-3 mb-4'>
                Candidate Name: <span className='font-medium'>John Doe</span>
              </p>
              <p className='text-base'>
                Job Title: <span className='font-medium'>UX UI Designer</span>
              </p>
              <div className='flex items-center gap-5 mt-[29px]'>
                <div className='flex items-center gap-2'>
                  <img src={ASSETS.calender} alt='' />
                  <p className='text-13 font-medium'>09/12/22</p>
                </div>
                <div className='flex items-center gap-2'>
                  <img src={ASSETS.clock} alt='' />
                  <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
                </div>
              </div>
              <Button
                leftIcon={ASSETS.calenderLight}
                variant='outlined'
                label='Add to Calender'
                className='mt-[51px]'
              />
            </div>
          ) : (
            <>
              <Job className='rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4'>
                <p className='2xl:text-22 text-lg text-primary font-medium'>
                  Dragon Zone
                </p>
                <p className='2xl:text-base text-sm font-medium my-1'>
                  UX UI Designer{' '}
                </p>
                <p className='2xl:text-base text-sm font-medium'>
                  Final Round{' '}
                  <span className='text-success'>
                    <i className='text-inherit'>Scheduled</i>
                  </span>
                </p>
                <div className='flex items-center gap-5 mt-2'>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.calender} alt='' />
                    <p className='text-13 font-medium'>09/12/22</p>
                  </div>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.clock} alt='' />
                    <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
                  </div>
                </div>
              </Job>
              <Job className='rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4'>
                <p className='2xl:text-22 text-lg text-primary font-medium'>
                  Gamer Skull
                </p>
                <p className='2xl:text-base text-sm font-medium my-1'>
                  UX UI Designer{' '}
                </p>
                <p className='2xl:text-base text-sm font-medium'>
                  Final Round{' '}
                  <span className='text-warning'>
                    <i className='text-inherit'>Pending</i>
                  </span>
                </p>
                <div className='flex items-center gap-5 mt-2'>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.calender} alt='' />
                    <p className='text-13 font-medium'>09/12/22</p>
                  </div>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.clock} alt='' />
                    <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
                  </div>
                </div>
              </Job>
              <Job className='rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4'>
                <p className='2xl:text-22 text-lg text-primary font-medium'>
                  Gamer Skull
                </p>
                <p className='2xl:text-base text-sm font-medium my-1'>
                  UX UI Designer{' '}
                </p>
                <p className='2xl:text-base text-sm font-medium'>
                  Final Round{' '}
                  <span className=''>
                    <i className='text-inherit'>Completed</i>
                  </span>
                </p>
                <div className='flex items-center gap-5 mt-2'>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.calender} alt='' />
                    <p className='text-13 font-medium'>09/12/22</p>
                  </div>
                  <div className='flex items-center gap-2'>
                    <img src={ASSETS.clock} alt='' />
                    <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
                  </div>
                </div>
              </Job>
            </>
          )}
        </JobsSection>
      )}
    </Container>
  );
};

export default Interviews;

const Container = styled.div`
  max-width: 820px;
  width: 100%;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const JobsSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Job = styled.div``;

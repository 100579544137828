import { FC } from 'react';

import DialogLayout from 'Dialogs/DialogLayout';
import Button from 'components/Button/Button';
import ASSETS from 'assets';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeactivateAccount: FC<IProps> = ({ open, setOpen }) => {
  const handleClose = () => setOpen(false);

  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[484px] max-w-[440px] bg-grey-100 rounded-[16px] 2xl:p-6 p-5'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            alt=''
            onClick={handleClose}
          />
        </div>
        <p className='text-center text-primary 2xl:text-32 text-2xl font-medium'>
          Account Deactivation
        </p>
        <p className='2xl:text-lg text-base  font-normal text-center max-w-[25ch] mx-auto'>
          Are you sure you want to deactivate your account?
        </p>
        <Button
          label='Confirm'
          className='w-full 2xl:!text-22 !text-lg my-6 xl:mb-4'
        />
      </div>
    </DialogLayout>
  );
};

export default DeactivateAccount;

import ASSETS from 'assets';
import React from 'react';

const ReportedUser = () => {
  return (
    <div className='flex items-center gap-5'>
      <img
        src={ASSETS.userImg}
        className='2xl:size-[50px] size-[45px] object-cover rounded-full'
        alt=''
      />
      <p className='text-lg font-medium'>John Green</p>
    </div>
  );
};

const ReportedUsers = () => {
  return (
    <div className='flex-grow 2xl:mt-11 mt-6 h-0 overflow-auto flex flex-col gap-4'>
      {Array.from({ length: 7 }, (_, i) => i).map(i => (
        <ReportedUser key={i} />
      ))}
    </div>
  );
};

export default ReportedUsers;

import { createModel } from '@rematch/core';
import { RootModel } from '.';
import {
  updateApplicantDetails,
  updateProfile,
  updateProfileImage,
  updateRole,
  uploadFiles,
} from 'http/userService';
import {
  IUpdateApplicantDetailsPayload,
  IUpdateProfilePayload,
  IUpdateRole,
  IUploadFiles,
} from 'types/modals';
import { getUserCompanyByRole } from 'http/companyService';
import { getCurrentUser } from 'http/authService';

interface IState {
  loading: boolean;
}

export const user = createModel<RootModel>()({
  name: 'user',
  state: {
    loading: false,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
  },
  effects: dispatch => ({
    async handleUpdateRole(payload: IUpdateRole, state) {
      const user = state.auth.user;
      const { role, navigate } = payload;
      try {
        dispatch.companies.setSelected(null);
        dispatch.user.setLoading(true);
        await updateRole(role);
        dispatch.utils.setRoleModal(false);
        const { data } = await getUserCompanyByRole(role);
        if (role === 'A' || role === 'RA' || role === 'SA') {
          if (!user?.introVideos?.length) {
            navigate('/create-video');
          } else {
            navigate('/explore');
          }
        } else if (role === 'E' || role === 'RE' || role === 'SE') {
          if (role === 'E') {
            if (data.data.length) {
              dispatch.companies.setSelected(data.data[0]);
            }
            navigate('/create-company');
          } else {
            navigate('/select-company');
          }
        }
        dispatch.auth.handleGetCurrentUser();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.user.setLoading(false);
      }
    },
    async handleUpdateProfile(payload: IUpdateProfilePayload) {
      const { values, profile, navigate, afterUpdate } = payload;
      try {
        dispatch.user.setLoading(true);
        await updateProfile(values);
        if (profile) {
          await updateProfileImage(profile);
        }
        const { data } = await getCurrentUser();
        dispatch.auth.setUser(data.data);
        if (navigate) {
          if (data.data.introVideos?.length) {
            navigate('/make-profile/step-3');
          } else {
            navigate('/make-profile/step-2');
          }
        }
        if (afterUpdate) {
          afterUpdate();
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.user.setLoading(false);
      }
    },
    async handleUploadFiles(payload: IUploadFiles) {
      try {
        dispatch.user.setLoading(true);
        await uploadFiles(payload.files);
        payload.navigate('/make-profile/step-4');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.user.setLoading(false);
      }
    },
    async handleUpdateApplicantProfile(
      payload: IUpdateApplicantDetailsPayload
    ) {
      const { values, navigate } = payload;
      try {
        dispatch.user.setLoading(true);
        await updateApplicantDetails(values);
        dispatch.auth.handleGetCurrentUser();
        navigate('/profile');
        dispatch.auth.handleGetCurrentUser();
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.user.setLoading(false);
      }
    },
  }),
});

import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Accordion from './Accordion';
import { processCities } from 'app.config';
import ASSETS from 'assets';

const options = processCities();

const Location = () => {
  const dispatch = useDispatch<Dispatch>();

  const { location } = useSelector((state: RootState) => state.filters);

  const [search, setSearch] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleSelect = (op: string) => {
    dispatch.filters.setListFilters({ name: 'location', value: op });
    setSearch('');
  };

  return (
    <Accordion label='Location'>
      <div className='px-2 pb-2 flex flex-col gap-1'>
        <div className='flex items-center gap-1 flex-wrap'>
          {location.map(s => (
            <div className='bg-secondary px-[7px] h-7 flex items-center rounded'>
              <p className='text-[10px] font-medium'>{s}</p>
              <img
                src={ASSETS.cross}
                className='w-3 h-3 cursor-pointer'
                onClick={() => handleSelect(s)}
                alt=''
              />
            </div>
          ))}
        </div>

        <div className='border border-grey-400 h-9 rounded-md bg-white overflow-hidden relative'>
          <input
            type='search'
            name=''
            placeholder='Search'
            className='w-full h-full text-sm outline-none border-none pl-5 pr-3'
            id=''
            value={search}
            onChange={handleChange}
          />
          <div className='absolute left-0 top-0 h-full w-[20px] aspect-square grid place-items-center'>
            <img src={ASSETS.search} className='w-4' alt='' />
          </div>
        </div>
        <div className='flex flex-col rounded bg-white max-h-[300px] empty:hidden overflow-auto'>
          {options.map(op => {
            if (op.toLowerCase().includes(search.toLowerCase())) {
              return (
                <div
                  key={op}
                  className='flex items-center py-2 gap-2 px-2 border-b border-b-grey-400
                  last-of-type:border-b-0
                  '
                  onClick={() => handleSelect(op)}
                >
                  <img
                    src={
                      location.includes(op)
                        ? ASSETS.checkboxCheck
                        : ASSETS.checkbox
                    }
                    className='w-5'
                    alt=''
                  />
                  <p className='text-sm'>{op}</p>
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    </Accordion>
  );
};

export default Location;

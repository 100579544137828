import api from './index';
import { IApiResponse } from 'types/api';
import { IVideo } from 'types/interfaces';

export const getAllVideos = (query: string) =>
  api.get<IApiResponse<{ videos: IVideo[] }>>(`/v1/video${query}`);

export const uploadVideo = (data: FormData) =>
  api.post('/v1/video', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const deleteVideo = (id: string) => api.delete(`/v1/video/${id}`);

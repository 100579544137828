import React, { useState, FC } from 'react';

import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';
import Button from 'components/Button/Button';
import classNames from 'classnames';

const ACCEPT_MESSAGES = [
  'Hello dear, Congratulations you have made it to the Final Round 🎉',
  "Congratulations! You've been hired for the position! 🎉",
  "🌟 Exciting news! You've been selected for the position! ",
  '🌟 Exciting news! You have made it to the next Round. ',
];

const REJECT_MESSAGES = [
  'We regret to inform you that your application has not been successful this time. 😔',
  "We appreciate your interest. Unfortunately, this time, we won't be moving forward with your application. Keep up the great work! 🌻",
  'We are sorry to let you know that we won’t be able to move further with your application. However we have saved your resume for future references. ',
];

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AutomatedMessages: FC<IProps> = ({ open, setOpen }) => {
  const [action, setAction] = useState<'' | 'accept' | 'reject'>('');
  const [selected, setSelected] = useState('');

  const handleClick = (val: typeof action) => {
    setAction(val);
  };

  const handleClose = () => setOpen(false);

  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[576px] max-w-[480px] rounded-[16px] 2xl:p-[30px] p-5 bg-grey-100'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            onClick={handleClose}
            className='cursor-pointer'
            alt=''
          />
        </div>
        <p
          className={classNames(
            '2xl:text-32 text-2xl text-primary font-medium text-center leading-10',
            action ? 'mt-[-5px]' : '2xl:mt-[28px] mt-4'
          )}
        >
          Automated Messages
        </p>
        {action ? (
          <>
            <div className='flex flex-col gap-[10px] mt-[26px]'>
              {action === 'accept' ? (
                <>
                  {ACCEPT_MESSAGES.map((message, index) => (
                    <div
                      key={index}
                      className={classNames(
                        'w-full 2xl:p-5 p-4 bg-white rounded-lg hover:shadow-[0px_4px_4px_0px_#00000040] cursor-pointer transition duration-300',
                        selected === message &&
                          'shadow-[0px_4px_4px_0px_#00000040]'
                      )}
                      onClick={() => setSelected(message)}
                    >
                      <p className='text-sm'>{message}</p>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {REJECT_MESSAGES.map((message, index) => (
                    <div
                      key={index}
                      className={classNames(
                        'w-full 2xl:p-5 p-4 bg-white rounded-lg hover:shadow-[0px_4px_4px_0px_#00000040] cursor-pointer transition duration-300',
                        selected === message &&
                          'shadow-[0px_4px_4px_0px_#00000040]'
                      )}
                      onClick={() => setSelected(message)}
                    >
                      <p className='text-sm'>{message}</p>
                    </div>
                  ))}
                </>
              )}
              <Button
                label='Send'
                className='mt-[29px] !text-lg'
                onClick={handleClose}
              />
            </div>
          </>
        ) : (
          <>
            <p className='2xl:text-lg text-base text-center 2xl:mt-[34px] mt-5'>
              How would you like to move forward with the application?
            </p>
            <div className='flex flex-col justify-center items-center 2xl:mt-[46px] mt-8 gap-3'>
              <Button
                label='Proceed with Application'
                className='max-w-[339px] w-full'
                onClick={() => handleClick('accept')}
              />
              <Button
                label='Decline Application'
                variant='outlined'
                className='max-w-[339px] w-full'
                onClick={() => handleClick('reject')}
              />
            </div>
          </>
        )}
      </div>
    </DialogLayout>
  );
};

export default AutomatedMessages;

import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import VideoRecorder from 'react-video-recorder';
import CountDown from './CountDown';
import ASSETS from 'assets';
import Button from 'components/Button/Button';

const Controls = (props: any) => {
  const {
    isVideoInputSupported,
    isInlineRecordingSupported,
    thereWasAnError,
    isRecording,
    isCameraOn,
    streamIsReady,
    isConnecting,
    isRunningCountdown,
    // isReplayingVideo,
    // countdownTime,
    // timeLimit,
    // showReplayControls,
    // replayVideoAutoplayAndLoopOff,
    // useVideoInput,

    onTurnOnCamera,
    // onTurnOffCamera,
    onOpenVideoInput,
    onStartRecording,
    onStopRecording,
    // onPauseRecording,
    // onResumeRecording,
    // onStopReplaying,
    // onConfirm,
    setComplete,
  } = props;

  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isRecording) {
      return (
        <button
          type='button'
          onClick={onStopRecording}
          data-qa='stop-recording'
          className='absolute left-[50%] bottom-[68px] translate-x-[-50%]'
        >
          <div className='flex items-center gap-2'>
            <span className='2xl:size-4 size-3 rounded-full bg-green-500'></span>
            <p className='2xl:text-[37px] text-2xl text-white font-bold'>
              STOP
            </p>
          </div>
        </button>
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <button
          type='button'
          onClick={onStartRecording}
          data-qa='start-recording'
          className='absolute left-[50%] bottom-[68px] translate-x-[-50%]'
        >
          <div className='flex items-center gap-2'>
            <span className='2xl:size-4 size-3 rounded-full bg-red'></span>
            <p className='2xl:text-[37px] text-2xl text-white font-bold'>REC</p>
          </div>
        </button>
      );
    }

    return shouldUseVideoInput ? (
      <button type='button' onClick={onOpenVideoInput} data-qa='open-input'>
        Record a video
      </button>
    ) : (
      <button
        type='button'
        className='absolute top-[28px] right-[28px] z-10 cursor-pointer'
        onClick={() => {
          onTurnOnCamera();
          setComplete(false);
        }}
        data-qa='turn-on-camera'
      >
        <div className='2xl:size-[54px] size-[35px] rounded-full bg-[#0000001a] grid place-items-center'>
          <img
            src={ASSETS.retake}
            className='w-5 2xl:w-auto 2xl:mt-[-6px] mt-[-3px]'
            alt=''
          />
        </div>
      </button>
    );
  };

  return (
    <div className='absolute bg-transparent w-full h-full'>
      <div className='relative w-full h-full'>
        {/* {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />} */}
        {isRunningCountdown && <CountDown />}
        {renderContent()}
      </div>
    </div>
  );
};

const RecordVideo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { selected } = useSelector((state: RootState) => state.video);

  const [complete, setComplete] = useState(false);
  const [video, setVideo] = useState<File | null>(null);

  const handleClose = () => navigate(-1);

  const handleUpload = () => {
    if (selected) {
      dispatch.video.setMedia({ id: selected?.id, value: video });
    }
    navigate(-1);
  };

  return (
    <div className='flex-grow  w-full 2xl:max-w-[620px] max-w-[410px] 2xl:pb-[100px] pb-2 mx-auto'>
      <div className='h-full relative aspect-[0.75/1]'>
        {/* @ts-ignore */}
        <VideoRecorder
          onRecordingComplete={(videoBlob: Blob) => {
            setComplete(true);
            const file = new File([videoBlob], 'example.mp4', {
              type: 'video/mp4',
            });
            setVideo(file);
          }}
          isOnInitially
          isFlipped={false}
          timeLimit={(selected?.duration as number) * 1000}
          renderActions={(props: any) => (
            <Controls {...props} setComplete={setComplete} />
          )}
        />
        <div className='absolute top-0 2xl:p-[28px] p-4 left-0 w-full flex items-center justify-between'>
          <div
            className='2xl:size-[54px] size-[35px] rounded-full bg-[#0000001a] grid place-items-center cursor-pointer'
            onClick={handleClose}
          >
            <img src={ASSETS.cross} className='w-5 2xl:w-auto' alt='' />
          </div>
          <p className='2xl:text-32 text-xl text-white font-bold'>
            {selected?.question}
          </p>
          <div className='2xl:w-[54px] w-[35px]'></div>
        </div>
        {complete && (
          <div className='absolute bottom-5 w-full px-5'>
            <Button
              label='Upload'
              className='w-full 2xl:!text-22 !text-lg'
              onClick={handleUpload}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default RecordVideo;

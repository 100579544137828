import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import ClickAwayListener from 'react-click-away-listener';
import ASSETS from 'assets';

const Notification = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleMenu = () => setOpen(!open);
  const closeMenu = () => setOpen(false);

  const handleNavigate = () => {
    navigate('/notifications');
    closeMenu();
  };
  return (
    <Container>
      <div className='cursor-pointer' role='button' onClick={toggleMenu}>
        <img src={ASSETS.notification} className='w-9 2xl:w-[45px]' alt='' />
      </div>
      {open && (
        <ClickAwayListener onClickAway={closeMenu}>
          <div className='menu shadow-popup bg-white w-[100vw] max-w-[640px] absolute right-0 bottom-0 translate-y-[calc(100%_+_0px)] rounded py-3 z-10 '>
            <div className='header 2xl:px-[25px] px-4'>
              <p className='2xl:text-32 text-2xl font-medium'>Notifications</p>
            </div>
            <div className='unread-messages 2xl:mt-[20px] mt-4'>
              <p className='2xl:text-base text-sm font-medium text-placeholder 2xl:px-[31px] pb-1 px-6'>
                Unread
              </p>
              <div className='notification bg-grey-200 2xl:py-[18px] py-3 flex items-center gap-[11px] 2xl:px-[29px] px-5 w-full border-b border-b-grey-400'>
                <div className='2xl:size-10 size-8 grid place-items-center rounded-full bg-primary'>
                  <img
                    src={ASSETS.bookmarkWhite}
                    className='w-3 2xl:w-auto'
                    alt=''
                  />
                </div>
                <div>
                  <p className='2xl:text-lg text-base font-medium'>
                    Your video is saved by Codes Burst Systems.{' '}
                  </p>
                  <p className='2xl:text-base text-sm font-medium text-placeholder'>
                    Today at 9:42 AM
                  </p>
                </div>
              </div>
              <div className='notification bg-grey-200 2xl:py-[18px] py-3 flex items-center gap-[11px] 2xl:px-[29px] px-5 w-full border-b border-b-grey-400'>
                <div className='2xl:size-10 size-8 grid place-items-center rounded-full bg-primary'>
                  <img
                    src={ASSETS.bookmarkWhite}
                    className='w-3 2xl:w-auto'
                    alt=''
                  />
                </div>
                <div>
                  <p className='2xl:text-lg text-base font-medium'>
                    Your video is saved by Codes Burst Systems.{' '}
                  </p>
                  <p className='2xl:text-base text-sm font-medium text-placeholder'>
                    Today at 9:42 AM
                  </p>
                </div>
              </div>
            </div>
            <div className='unread-messages'>
              <p className='text-base font-medium text-placeholder px-[31px] pt-3'>
                Read
              </p>
              <div className='notification  2xl:py-[18px] pb-3 pt-1 flex items-center gap-[11px] 2xl:px-[29px] px-5 w-full border-b border-b-grey-400'>
                <div className='2xl:size-10 size-8 grid place-items-center rounded-full bg-primary'>
                  <img
                    src={ASSETS.bookmarkWhite}
                    className='w-3 2xl:w-auto'
                    alt=''
                  />
                </div>
                <div>
                  <p className='2xl:text-lg text-base font-medium'>
                    Your video is saved by Codes Burst Systems.{' '}
                  </p>
                  <p className='2xl:text-base text-sm font-medium text-placeholder'>
                    Today at 9:42 AM
                  </p>
                </div>
              </div>{' '}
              <div className='notification  2xl:py-[18px] py-3 flex items-center gap-[11px] 2xl:px-[29px] px-5 w-full border-b border-b-grey-400'>
                <div className='2xl:size-10 size-8 grid place-items-center rounded-full bg-primary'>
                  <img
                    src={ASSETS.bookmarkWhite}
                    className='w-3 2xl:w-auto'
                    alt=''
                  />
                </div>
                <div>
                  <p className='2xl:text-lg text-base font-medium'>
                    Your video is saved by Codes Burst Systems.{' '}
                  </p>
                  <p className='2xl:text-base text-sm font-medium text-placeholder'>
                    Today at 9:42 AM
                  </p>
                </div>
              </div>
              <p
                className='2xl:text-base text-sm 2xl:px-[30px] px-5 pt-[10px] cursor-pointer'
                role='button'
                onClick={handleNavigate}
              >
                See all notifications
              </p>
            </div>
          </div>
        </ClickAwayListener>
      )}
    </Container>
  );
};

export default Notification;

const Container = styled.div`
  position: relative;
`;

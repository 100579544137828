import React, { FC, useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import ASSETS from 'assets';
import classNames from 'classnames';

interface IProps {
  error?: string;
}

const CompanySelector: FC<IProps> = ({ error }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<Dispatch>();
  const { companies } = useSelector((state: RootState) => state.companies);

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const toggleMenu = () => {
    setOpen(!open);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch.companies.handleGetCompanies();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className='w-full' ref={containerRef}>
      <p className='2xl:text-lg text-base font-medium mb-[2px]'>
        Company Name*
      </p>
      <div className=''>
        <div
          className={classNames(
            '2xl:h-[70px] h-12 border-[0.5px] border-grey-400 rounded-lg flex items-center justify-between gap-3 has-[&_input:focus]:!border-red pl-[28px] pr-[15.5px] relative',
            error && 'border-red'
          )}
        >
          <input
            type='text'
            className='outline-none border-none h-full flex-grow text-placeholder 2xl:text-lg text-base rounded-lg'
            placeholder='Search and Choose'
            value={search}
            onChange={e => setSearch(e.target.value)}
            onClick={toggleMenu}
          />
          <img src={ASSETS.chevronDown} alt='' />
          {open && (
            <div className='absolute w-full bottom-0 bg-white left-0 translate-y-[calc(100%_+_8px)] border border-grey-400 rounded divide-y max-h-[350px] overflow-auto'>
              {companies.map((c, index: number) => {
                if (c.name.toLowerCase().includes(search.toLowerCase())) {
                  return (
                    <div
                      key={index}
                      className='px-[22px] pt-[10px] pb-[15px] cursor-pointer'
                      onClick={() => {
                        setSearch(c.name);
                        dispatch.companies.setSelected(c);
                        handleClose();
                      }}
                    >
                      <p className='2xl:text-lg text-base font-medium'>
                        {c.name}
                      </p>
                      <p className='2xl:text-base text-sm text-placeholder'>
                        {c.location}
                      </p>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </div>
          )}
        </div>
        {error && (
          <div className='flex items-center gap-1 mt-1'>
            <img src={ASSETS.errorInfo} alt='' />
            <p className='text-xs text-error'>{error}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanySelector;

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *,*::before,*::after{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: "DM Sans", sans-serif;
        color: #110C22;
    }

    .row{
        display: flex;
        align-items: center;
    }

    .space-between{
        justify-content: space-between;
    }

    .justify-center{
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }

    .clickable{
        cursor: pointer;
    }

::-webkit-scrollbar {
  width: 9px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}
 
::-webkit-scrollbar-thumb {
  background: #8D8A95; 
  border-radius: 10px;
}


& .scroll-view::-webkit-scrollbar {
    width: 8px;
}

& .scroll-view::-webkit-scrollbar-track {
    border-radius: 10px;
    background: transparent;
}

& .scroll-view::-webkit-scrollbar-thumb {
    background: black;
    border-radius: 10px;
}
`;

import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import Button from 'components/Button/Button';
import { Range, getTrackBackground } from 'react-range';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';

const SaleryRange = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const breakpoint = useCurrentBreakpoint();
  const [values, setValues] = useState([10, 500]);
  const { applyQuestionDetails, job, loading } = useSelector(
    (state: RootState) => state.job
  );
  const handleNext = () => {
    dispatch.job.handleApplyJob({
      values: {
        ...applyQuestionDetails,
        salary_range_from: values[0] * 1000,
        salary_range_to: values[1] * 1000,
        job_id: job!.id,
      },
      navigate,
    });
  };
  return (
    <div className='w-full mx-auto max-w-[776px] flex-grow bg-white border-[0.5px] border-grey-600 rounded 2xl:py-[100px] py-[50px] 2xl:px-11 px-6 flex flex-col justify-between'>
      <div>
        <p className='2xl:text-32 text-2xl text-primary font-medium'>
          Salary Range
        </p>
        <p className='2xl:text-lg text-base text-placeholderColor'>
          Expected salary range help us understand your expectations better
        </p>
      </div>
      <div className='flex-grow 2xl:my-5 my-0 flex justify-center items-center px-4'>
        <Range
          values={values}
          onChange={values => setValues(values)}
          min={10}
          max={500}
          step={5}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: breakpoint === 'xl' ? '8px' : '11px',
                width: '100%',
                background: getTrackBackground({
                  values,
                  colors: ['#DAD9DB', '#008080', '#DAD9DB'],
                  min: 10,
                  max: 500,
                }),
                borderRadius: '22px',
                alignSelf: 'center',
              }}
            >
              {children}
              <div className='flex justify-between 2xl:mt-[26px] mt-5'>
                <span className='2xl:text-2xl text-xl text-placeholder'>
                  $10k
                </span>
                <span className='2xl:text-2xl text-xl text-placeholder'>
                  $500k
                </span>
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged, index }) => (
            <div
              {...props}
              style={{
                ...props.style,
              }}
              className='2xl:size-[34px] size-7 outline-none rounded-full bg-primary flex justify-center'
            >
              <div className='absolute 2xl:top-[-40px] top-[-35px] text-black font-medium 2xl:text-[26px] text-xl'>
                {`$${values[index]}`}k
              </div>
            </div>
          )}
        />
      </div>
      <Button
        label='Next'
        className='2xl:!text-22 !text-lg !w-full'
        onClick={handleNext}
        loading={loading}
      />
    </div>
  );
};

export default SaleryRange;

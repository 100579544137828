import React from 'react';
import ASSETS from 'assets';

const EmployerInterViews = () => {
  return (
    <div className='flex flex-col'>
      <div className='2xl:pt-[33px] pt-5'>
        <div className='flex items-center 2xl:gap-[29px] gap-4 rounded-lg shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'>
          <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
            <img src={ASSETS.job} alt='' />
          </div>
          <div>
            <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
              Wardiere Software
            </p>
            <p className='2xl:text-lg text-base font-medium leading-6'>
              UX UI Designer
            </p>
            <p className='2xl:text-base text-sm'>Interviews: (3)</p>
          </div>
        </div>
        <div className='2xl:mt-[34px] mt-6 flex flex-col gap-2'>
          <div className='rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4'>
            <p className='2xl:text-22 text-lg text-primary font-medium'>
              John Doe
            </p>
            <p className='2xl:text-base text-sm font-medium my-1'>
              UX UI Designer{' '}
            </p>
            <p className='2xl:text-base text-sm font-medium'>
              Final Round{' '}
              <span className='text-success'>
                <i className='text-inherit'>Scheduled</i>
              </span>
            </p>
            <div className='flex items-center gap-5 mt-2'>
              <div className='flex items-center gap-2'>
                <img src={ASSETS.calender} alt='' />
                <p className='text-13 font-medium'>09/12/22</p>
              </div>
              <div className='flex items-center gap-2'>
                <img src={ASSETS.clock} alt='' />
                <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
              </div>
            </div>
          </div>
          <div className='rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4'>
            <p className='2xl:text-22 text-lg text-primary font-medium'>
              David Lin
            </p>
            <p className='2xl:text-base text-sm font-medium my-1'>
              UX UI Designer{' '}
            </p>
            <p className='2xl:text-base text-sm font-medium'>
              Final Round{' '}
              <span className='text-warning'>
                <i className='text-inherit'>Pending</i>
              </span>
            </p>
            <div className='flex items-center gap-5 mt-2'>
              <div className='flex items-center gap-2'>
                <img src={ASSETS.calender} alt='' />
                <p className='text-13 font-medium'>09/12/22</p>
              </div>
              <div className='flex items-center gap-2'>
                <img src={ASSETS.clock} alt='' />
                <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
              </div>
            </div>
          </div>
          <div className='rounded-lg border border-grey-600 overflow-auto bg-white py-[10px] px-4'>
            <p className='2xl:text-22 text-lg text-primary font-medium'>
              Vienna Winselt
            </p>
            <p className='2xl:text-base text-sm font-medium my-1'>
              UX UI Designer{' '}
            </p>
            <p className='2xl:text-base text-sm font-medium'>
              Final Round{' '}
              <span className=''>
                <i className='text-inherit'>Completed</i>
              </span>
            </p>
            <div className='flex items-center gap-5 mt-2'>
              <div className='flex items-center gap-2'>
                <img src={ASSETS.calender} alt='' />
                <p className='text-13 font-medium'>09/12/22</p>
              </div>
              <div className='flex items-center gap-2'>
                <img src={ASSETS.clock} alt='' />
                <p className='text-13 font-medium'>1:00 PM - 2:3O PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerInterViews;

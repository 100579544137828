import { FC, useEffect, useState } from 'react';

import styled from 'styled-components';
import OTP from 'react-otp-input';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useNavigate } from 'react-router-dom';

import DialogLayout from 'Dialogs/DialogLayout';
import Button from 'components/Button/Button';

import ASSETS from 'assets';
import classNames from 'classnames';
import useCountdown from 'hooks/useCountDown';

interface IProps {
  isPassword?: boolean;
  isEmail?: boolean;
}

const EmailVerification: FC<IProps> = ({ isPassword, isEmail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { time, resetTimer, startTimer } = useCountdown(180);

  const { emailVerification } = useSelector((state: RootState) => state.utils);
  const { loading, authType, phone, email } = useSelector(
    (state: RootState) => state.auth
  );

  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');

  const renderTime = () => {
    const minutes = Math.floor(time / 60);
    const seconds = time - Math.floor(time / 60) * 60;

    return `0${minutes}:${seconds >= 10 ? seconds : `0${seconds}`}`;
  };

  const handleResendCode = () => {
    if (emailVerification.type === 'forgot-password') {
      resetTimer();
      return dispatch.auth.handleForgotPassword({ email, formik: null });
    }
    if (emailVerification.type === 'forgot-email') {
      resetTimer();
      return dispatch.auth.handleForgotEmail({ phone, setError });
    }
    if (authType === 'E') {
      dispatch.auth.handleResenCode({ type: 'E', email });
    }
    if (authType === 'P') {
      dispatch.auth.HandlePhoneSignUp({ values: { mobile: phone, type: 'P' } });
    }

    resetTimer();
  };

  const handleBack = () => {
    const type = emailVerification.type;

    switch (type) {
      case 'forgot-password':
        dispatch.utils.setForgotPassword(true);
        break;
      case 'forgot-email':
        dispatch.utils.setForgotEmail(true);
        break;
      default:
        dispatch.utils.setemailVerification({ open: false, type: 'email' });
        break;
    }
  };

  const handleVerify = () => {
    const type = emailVerification.type;
    setError('');
    if (otp.length !== 6) {
      return setError('Incorrect code, please try again');
    }
    switch (type) {
      case 'forgot-password':
        dispatch.auth.handleVerifyForgotPasswordOtp({
          code: otp,
          navigate,
          setError,
        });
        break;
      case 'forgot-email':
        dispatch.auth.handleRecoverEmail({ code: otp, setError });
        break;
      case 'email':
        dispatch.auth.handleEmailVerification({ code: otp, setError });
        // dispatch.utils.setEmailVerified({
        //   open: true,
        //   type: 'email',
        // });
        break;
      case 'phone':
        dispatch.auth.HandlePhoneVerification({
          values: { code: otp },
          navigate,
          setError,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    startTimer();
    // eslint-disable-next-line
  }, []);

  return (
    <DialogLayout open={true}>
      <Container className='2xl:max-w-[484px] max-w-[420px] 2xl:py-[26px] 2xl:px-7 p-5'>
        <BackButton className='cursor-pointer bg-grey-400' onClick={handleBack}>
          <img src={ASSETS.chevronLeft} alt='' />
        </BackButton>
        <p className='2xl:text-32 text-2xl 2xl:mt-[30px] mt-[20px] mb-[10px] text-primary font-medium'>
          {emailVerification.type === 'forgot-password'
            ? 'Verification'
            : emailVerification.type === 'forgot-email' || 'phone'
            ? 'Verification'
            : 'Email Verification'}
        </p>
        <p className='2xl:text-lg text-base text-placeholder'>
          Sent to:{' '}
          <span className='font-medium'>
            {emailVerification.type === 'forgot-email' && phone}
            {emailVerification.type === 'phone' && phone}
            {emailVerification.type === 'email' && email}
            {emailVerification.type === 'forgot-password' && email}
          </span>
        </p>
        <OTPInput className='mt-[20px] 2xl:mb-[70px] mb-[40px]'>
          <OTP
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span className='w-[10px]'></span>}
            renderInput={props => (
              <input
                {...props}
                className={classNames(
                  'flex-grow aspect-[1/1] border-2 border-grey-400 rounded-lg outline-none focus:border-primary 2xl:text-32 text-2xl  !my-0',
                  error && 'error'
                )}
              />
            )}
            shouldAutoFocus
          />
          {error && (
            <p className='text-sm text-error font-medium text-center w-full'>
              Incorrect code, please try again.
            </p>
          )}
        </OTPInput>
        <Button
          label='Verify'
          className='w-full 2xl:!text-xl !text-lg'
          onClick={handleVerify}
          disabled={otp.length !== 6}
          loading={loading}
        />
        {time === 0 ? (
          <p className='2xl:text-base text-sm text-center mt-4'>
            Didn’t receive your code?{' '}
            <span
              className='underline text-placeholder font-normal cursor-pointer'
              onClick={handleResendCode}
            >
              Resend Code
            </span>
          </p>
        ) : (
          <p className='2xl:text-base text-sm text-center mt-4'>
            <span className='font-medium'>Resend Code</span>{' '}
            <span className='text-placeholder'>{renderTime()}</span>
          </p>
        )}
      </Container>
    </DialogLayout>
  );
};

export default EmailVerification;

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors['grey-100']};
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

const BackButton = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
`;

const OTPInput = styled.div`
  width: 100%;
  position: relative;

  & input.error {
    border-color: ${({ theme }) => theme.colors.error};
  }

  & .text-error {
    position: absolute;
    bottom: -18px;
    left: 50%;
    transform: translate(-50%, 100%);
  }
`;

import React, { FC, useEffect, useState } from 'react';

import TimePicker from 'Dialogs/TimePicker/TimePicker';

import ASSETS from 'assets';
import classNames from 'classnames';

interface IProps {
  label: string;
  placeholder: string;
  onChange: (val: string) => void;
  error?: string;
}

const TimeField: FC<IProps> = ({ error, onChange, label, placeholder }) => {
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState('');

  const handleOpenPicker = () => setOpen(true);

  useEffect(() => {
    onChange(time);
    // eslint-disable-next-line
  }, [time]);

  return (
    <div className='w-full'>
      <p className='text-base font-medium'>{label}</p>
      <div
        className={classNames(
          'flex items-center gap-1 h-[56px] border border-grey-400 rounded-md pl-[17px] justify-between pr-[13px] cursor-pointer',
          error && 'border-red'
        )}
        onClick={handleOpenPicker}
      >
        <p className={classNames('text-base', !time && 'opacity-45')}>
          {time || placeholder}
        </p>
        <img src={ASSETS.clock} className='w-6 h-6' alt='' />
      </div>
      {error && (
        <div className='flex items-center gap-1 mt-1'>
          <img src={ASSETS.errorInfo} alt='' />
          <p className='text-xs text-error'>{error}</p>
        </div>
      )}
      {open && <TimePicker open={open} setOpen={setOpen} setTime={setTime} />}
    </div>
  );
};

export default TimeField;

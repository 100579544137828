import React from 'react';

import styled from 'styled-components';
import { useFormik } from 'formik';
import { object, string, ref, InferType } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { useNavigate } from 'react-router-dom';

import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';
import ASSETS from 'assets';
// import EmailVerified from 'Dialogs/EmailVerified/EmailVerified';

const schema = object({
  password: string()
    .min(8, 'Password must be at least 8 characters')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
    .matches(/[\W_]/, 'Password must contain at least one special character')
    .required('Required field'),
  confirm_password: string()
    .oneOf([ref('password'), ''], 'Passwords must match')
    .required('Required field'),
});

interface IResetPasswordData extends InferType<typeof schema> {}

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { loading } = useSelector((state: RootState) => state.auth);

  const handleBack = () => {
    navigate('/sign-in');
  };

  const formik = useFormik<IResetPasswordData>({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    onSubmit: values => {
      dispatch.auth.handleRecoverPassword({ values, navigate });
    },
    validationSchema: schema,
  });
  return (
    <Container className='bg-grey-100 2xl:p-6 p-5'>
      <BackButton className='bg-gray-300 cursor-pointer' onClick={handleBack}>
        <img src={ASSETS.chevronLeft} alt='' />
      </BackButton>
      <p className='2xl:text-32 text-2xl text-primary font-medium 2xl:mt-[35px] 2xl:mb-[45px] mt-[20px] mb-[30px]'>
        Password Reset
      </p>
      <Form onSubmit={e => e.preventDefault()} className='2xl:gap-[26px] gap-5'>
        <TextField
          type='password'
          name='password'
          placeholder='Create Password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          error={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : undefined
          }
        />
        <TextField
          type='password'
          placeholder='Confirm Password'
          name='confirm_password'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirm_password}
          error={
            formik.touched.confirm_password && formik.errors.confirm_password
              ? formik.errors.confirm_password
              : undefined
          }
        />
        <Button
          label='Reset Password'
          className='2xl:!text-xl !text-lg w-full mt-2'
          type='submit'
          onClick={() => formik.handleSubmit()}
          loading={loading}
        />
      </Form>
      {/* <EmailVerified /> */}
    </Container>
  );
};

export default ResetPassword;

const Container = styled.div`
  width: 100%;
  max-width: 484px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const BackButton = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

import React, { useState } from 'react';
import Button from 'components/Button/Button';
import ASSETS from 'assets';

const Languages = [
  'English',
  'Arabic',
  'Chinese',
  'French',
  'Italian',
  'Japanese',
  'Korean',
  'Portuguese',
  'Russian',
  'Spanish',
  'Hindi',
] as const;

type LANGUAGES = (typeof Languages)[number];

const Language = () => {
  const [lang, setLang] = useState<LANGUAGES>('English');
  const handleLanguage = (newLang: LANGUAGES) => {
    console.log(newLang);
    setLang(newLang);
  };

  return (
    <div>
      {Languages.map(lag => (
        <div
          key={lag} // Corrected here
          className='flex justify-between items-center 2xl:h-[56px] h-10 2xl:px-11 px-6 border-b-[0.5px] border-b-grey-600 cursor-pointer'
          onClick={() => handleLanguage(lag)}
        >
          <p className='2xl:text-lg text-base font-medium'>{lag}</p>
          {lag === lang && (
            <img src={ASSETS.check} className='2xl:w-[25px] w-5' alt='' />
          )}
        </div>
      ))}
      <div className='flex justify-between items-center 2xl:px-11 px-6 2xl:py-6 py-[22px]'>
        <Button
          label='Confirm Language'
          className='w-full 2xl:!text-22 !text-lg'
        />
      </div>
    </div>
  );
};

export default Language;

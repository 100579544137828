import classNames from 'classnames';
import React, { FC } from 'react';

interface IProps {
  message: string;
  isMine: boolean;
}

const Message: FC<IProps> = ({ message, isMine }) => {
  return (
    <div
      className={classNames(
        'w-full flex justify-start',
        isMine && 'justify-end'
      )}
    >
      <div
        className={classNames(
          'w-[70%] p-3 bg-[#00808040] rounded-[12px] relative pb-5',
          isMine && 'bg-[#FBBC0540]'
        )}
      >
        <p className='text-sm'>{message}</p>
        <p className='text-[10px] absolute font-medium text-end right-2 bottom-1'>
          12:03 AM
        </p>
      </div>
    </div>
  );
};

export default Message;

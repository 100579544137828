import { Models } from '@rematch/core';

import { utils } from './utils';
import { filters } from './filters';
import { video } from './video';
import { auth } from './auth';
import { companies } from './companies';
import { search } from './search';
import { posts } from './posts';
import { user } from './user';
import { job } from './job';
import { reports } from './reports';

export interface RootModel extends Models<RootModel> {
  utils: typeof utils;
  filters: typeof filters;
  video: typeof video;
  auth: typeof auth;
  search: typeof search;
  companies: typeof companies;
  posts: typeof posts;
  user: typeof user;
  job: typeof job;
  reports: typeof reports;
}

export const models: RootModel = {
  utils,
  filters,
  video,
  auth,
  search,
  companies,
  posts,
  user,
  job,
  reports,
};

import React, { useRef, FC } from 'react';

import ClickAwayListener from 'react-click-away-listener';

import ASSETS from 'assets';

interface IProps {
  question: string;
  answer: string;
}

const Accordion: FC<IProps> = ({ question, answer }) => {
  const panel = useRef<HTMLDivElement | null>(null);
  const arrow = useRef<HTMLImageElement>(null);

  const handleAccordion = () => {
    if (panel.current && arrow.current) {
      if (panel.current.style.maxHeight > '0px') {
        panel.current.style.maxHeight = '0px';
        panel.current.classList.remove('open');
        arrow.current.style.rotate = '0deg';
      } else {
        panel.current.style.maxHeight = panel.current.scrollHeight + 'px';
        panel.current.classList.add('open');
        arrow.current.style.rotate = '180deg';
      }
    }
  };

  const handleClose = () => {
    if (panel.current) {
      panel.current.style.maxHeight = '0px';
      panel.current.classList.remove('open');
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className='border-b-[0.5px] border-b-grey-600 has-[.panel.open]:border-b-0 last-of-type:border-b-0'>
        <div
          className='flex items-center justify-between pl-2 pr-3 pb-2 cursor-pointer'
          onClick={handleAccordion}
        >
          <p className='2xl:text-lg text-base font-medium '>{question}</p>
          <img
            src={ASSETS.chevronRight}
            ref={arrow}
            className='rotate-90 transition duration-300'
            alt=''
          />
        </div>
        <div
          ref={panel}
          className='panel max-h-0 overflow-hidden transition-all duration-300 px-2'
        >
          <p className='2xl:text-base text-sm mb-2'>{answer}</p>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Accordion;

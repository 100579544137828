import { baseURL, CONSTANTS } from 'app.config';
import axios from 'axios';

const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    const token = localStorage.getItem(CONSTANTS.TOKEN);
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error?.response && error?.response?.status === 401) {
      localStorage.clear();
      if (window.location.pathname !== '/sign-in')
        window.location.pathname = '/sign-in';
    }
    return Promise.reject(error);
  }
);

export default api;

import styled from 'styled-components';

import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { useFormik } from 'formik';
import { object, string, InferType } from 'yup';

import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';

import ASSETS from 'assets';

const schema = object({
  email: string()
    .email()
    .matches(/@/, "The email must contain '@'")
    .required('Required field')
    .label('Email'),
  password: string().required('Required field').label('Password'),
});

export interface ISignInInitialValues extends InferType<typeof schema> {}

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { loading } = useSelector((state: RootState) => state.auth);

  const formik = useFormik<ISignInInitialValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      dispatch.auth.HandleLogin({
        ...values,
        formik,
        navigate,
      });
    },
  });

  const handleForgoteEmail = () => {
    dispatch.utils.setForgotEmail(true);
  };

  const handleForgotPassword = () => {
    dispatch.utils.setForgotPassword(true);
  };

  return (
    <Container className='bg-grey-100 2xl:p-6 2xl:pb-[50px] p-5'>
      <BackButton className='bg-gray-300 cursor-pointer 2xl:size-[35px] size-[30px]'>
        <img src={ASSETS.chevronLeft} className='w-2 2xl:w-auto ' alt='' />
      </BackButton>
      <p className='2xl:text-32 text-2xl text-primary font-medium 2xl:mt-[35px] 2xl:mb-[45px] mt-[20px] mb-[15px]'>
        Welcome Back!
      </p>
      <Form onSubmit={e => e.preventDefault()} className='2xl:gap-[21px] gap-3'>
        <div>
          <TextField
            name='email'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            type='email'
            placeholder='Email address'
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ''
            }
          />
          <div className='flex justify-end mt-1'>
            <p
              className='text-sm underline cursor-pointer'
              onClick={handleForgoteEmail}
            >
              Forgot Email?
            </p>
          </div>
        </div>
        <div>
          <TextField
            type='password'
            name='password'
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ''
            }
            placeholder='Password'
          />
          <div className='flex justify-end mt-1'>
            <p
              className='text-sm underline cursor-pointer'
              onClick={handleForgotPassword}
            >
              Forgot Password?
            </p>
          </div>
        </div>
        <Button
          label='Sign In'
          className='2xl:!text-xl !text-lg'
          type='submit'
          onClick={() => formik.handleSubmit()}
          loading={loading}
        />
      </Form>
      <p className='text-base font-medium text-center mt-[14px]'>
        Don’t have an account?{' '}
        <Link to='/'>
          <span className='text-blue'>Sign Up</span>
        </Link>
      </p>
      <OrText className='2xl:mt-[45px]  2xl:mb-[30px] mt-[30px] mb-[20px]'>
        <span className='text-base font-medium'>Or</span>
      </OrText>
      <div className='flex flex-col w-full gap-4'>
        <Button
          leftIcon={ASSETS.google}
          label='Sign Up with Google'
          className='2xl:!text-xl !text-lg !bg-red'
        />
        <Button
          leftIcon={ASSETS.facebook}
          label='Sign Up with Facebook'
          className='2xl:!text-xl !text-lg !bg-blue'
        />
      </div>
    </Container>
  );
};

export default Signin;

const Container = styled.div`
  width: 100%;
  max-width: 484px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const BackButton = styled.div`
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const OrText = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors['grey-400']};
  position: relative;

  & span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.colors['grey-100']};
    padding-inline: 10px;
  }
`;

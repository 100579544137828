import React, { useEffect, FC } from 'react';

import useCountdown from 'hooks/useCountDown';

const CountDown: FC = () => {
  const { time, startTimer } = useCountdown(3);
  useEffect(() => {
    startTimer();
    // eslint-disable-next-line
  }, []);
  return (
    <div className='absolute left-[50%] bottom-[200px] translate-x-[-50%]'>
      <p className='2xl:text-[128px] text-[68px] font-medium text-white'>
        {time}
      </p>
    </div>
  );
};

export default CountDown;

import React, { useEffect, useState } from 'react';

import ClickAwayListener from 'react-click-away-listener';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import ActionDialog from 'Dialogs/ActionDialog/ActionDialog';
import ASSETS from 'assets';
import { IntorVideo, IUser } from 'types/interfaces';
import { getUserById } from 'http/authService';
import { employerRoles } from 'app.config';
import { blockUser } from 'http/userService';

const UserProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { actionDialog } = useSelector((state: RootState) => state.utils);
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => setOpenMenu(!openMenu);
  const closeMenu = () => setOpenMenu(false);

  const [bLoading, setBLoading] = useState(false);

  const [user, setUser] = useState<IUser | null>(null);
  const [, setLoading] = useState(false);

  const { user: currentUser } = useSelector((state: RootState) => state.auth);

  const handleMessage = () => {
    navigate('/chat');
  };

  const handleBlock = () => {
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Block Profile',
      message: 'Are you sure you want to block this profile?',
      buttonTitle: 'Block',
      loading: bLoading,
      onClick: async () => {
        try {
          setBLoading(true);
          dispatch.utils.closeActionDialog();
          if (user) {
            await blockUser(user.id);
          }
        } catch (err: any) {
          console.log(err.message);
        } finally {
          setBLoading(false);
        }
      },
    });
  };

  const handleReport = () => {
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Report Profile',
      message: 'Are you sure you want to report this profile?',
      buttonTitle: 'Report',
      onClick: () => {
        navigate('/report');
        dispatch.utils.closeActionDialog();
      },
    });
  };

  const handleGetUser = async () => {
    try {
      setLoading(true);
      const { data } = await getUserById(id!);
      setUser(data.data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetUser();
    }
  }, [id]);

  return (
    <div className='w-full flex-grow'>
      <div className='w-full max-w-[776px] mx-auto'>
        <div className='profile bg-primary p-5 flex flex-col items-center relative'>
          <div className='2xl:size-[162px] size-[140px] rounded-full overflow-hidden'>
            <img
              src={user?.image_url || ASSETS.preview}
              className='w-full h-full object-cover'
              alt=''
            />
          </div>
          <p className='2xl:text-2xl text-xl font-bold text-white mt-2'>
            {user?.first_name} {user?.last_name}
          </p>
          <p className='2xl:text-lg text-base text-white font-bold my-2'>
            {user?.job_title}
          </p>
          <p className='2xl:text-base text-sm text-white font-bold'>
            {user?.location}
          </p>
          {(currentUser?.role === 'E' ||
            currentUser?.role === 'RE' ||
            currentUser?.role === 'SE') && (
            <div className='flex items-center justify-center gap-[7px] mt-3'>
              <button
                className='h-10 pl-[10px] pr-[20px] bg-white rounded-3xl flex items-center gap-[6px]'
                onClick={handleMessage}
              >
                <img src={ASSETS.sendGreen} alt='' />
                <p className='text-lg font-medium text-primary'>Message</p>
              </button>
              <div
                className='w-10 h-10 rounded-full border border-secondary grid place-items-center bg-white cursor-pointer'
                role='button'
              >
                <img src={ASSETS.bookmarkYellow} alt='' />
              </div>
            </div>
          )}
          {employerRoles.includes(currentUser?.role || '') && (
            <>
              <div className='absolute right-10 top-10'>
                <img
                  src={ASSETS.menuYellow}
                  className='cursor-pointer'
                  onClick={toggleMenu}
                  alt=''
                />
              </div>
              {openMenu && (
                <ClickAwayListener onClickAway={closeMenu}>
                  <div className='menu absolute  w-[171px] shadow-lg rounded-[8px] bg-white right-10 top-[65px] flex flex-col gap-[2px] overflow-hidden'>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[10px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleBlock}
                    >
                      <img src={ASSETS.blockCircle} alt='' />
                      <p className='text-base font-medium'>Block </p>
                    </div>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[10px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleReport}
                    >
                      <img src={ASSETS.flag} alt='' />
                      <p className='text-base font-medium'>Report </p>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </>
          )}
        </div>
        {/* video intro */}
        {user?.introVideos?.length && (
          <div
            className='bg-white border-[0.5px] border-grey-600 rounded-[12px] p-12
flex flex-col items-center mt-5'
          >
            <p className='2xl:text-2xl text-xl font-medium'>
              Video Introduction
            </p>
            <div className='w-full 2xl:max-w-[416px] max-w-[300px] 2xl:h-[470px] h-[320px] rounded-[10px] relative mt-5 overflow-hidden'>
              {user?.introVideos?.length && (
                <video
                  src={(user?.introVideos[0] as IntorVideo).url}
                  className='w-full h-full object-cover'
                  poster={(user?.introVideos[0] as IntorVideo).thumbnail}
                  controls
                  loop
                  disablePictureInPicture
                  controlsList='nodownload noplaybackrate'
                ></video>
              )}
            </div>
          </div>
        )}
        {/* Information */}
        <div className='bg-white border-[0.5px] border-grey-600 rounded-[12px] px-10 py-10 mt-5'>
          <div className='flex items-center justify-center gap-3'>
            <p className='2xl:text-2xl text-xl font-medium'>
              Education & Employment
            </p>
          </div>
          <div className='flex flex-col 2xl:mt-12 mt-6 gap-3'>
            {user?.userDetails?.company_name && (
              <div className='w-full flex items-center justify-between'>
                <p className='2xl:text-22 text-lg font-medium'>
                  Current Employer
                </p>
                <p className='2xl:text-22 text-lg font-medium text-placeholder'>
                  {user.userDetails.company_name}
                </p>
              </div>
            )}
            {user?.userDetails?.start_date && (
              <div className='w-full flex items-center justify-between'>
                <p className='2xl:text-22 text-lg font-medium'>Start Date</p>
                <p className='2xl:text-22 text-lg font-medium text-placeholder'>
                  May, 25, 2022
                </p>
              </div>
            )}
            {user?.userDetails?.end_date && (
              <div className='w-full flex items-center justify-between'>
                <p className='2xl:text-22 text-lg font-medium'>End Date</p>
                <p className='2xl:text-22 text-lg font-medium text-placeholder'>
                  Jan, 22, 2023
                </p>
              </div>
            )}
            {user?.userDetails?.previous_company && (
              <div className='w-full flex items-center justify-between'>
                <p className='2xl:text-22 text-lg font-medium'>
                  Previous Employer
                </p>
                <p className='2xl:text-22 text-lg font-medium text-placeholder'>
                  {user.userDetails.previous_company}
                </p>
              </div>
            )}
            {user?.userDetails?.years_of_exp && (
              <div className='w-full flex items-center justify-between'>
                <p className='2xl:text-22 text-lg font-medium'>
                  Years of Experience
                </p>
                <p className='2xl:text-22 text-lg font-medium text-placeholder'>
                  {user.userDetails.years_of_exp} years
                </p>
              </div>
            )}
            {user?.userDetails?.degree && (
              <div className='w-full flex items-center justify-between'>
                <p className='2xl:text-22 text-lg font-medium'>Degree</p>
                <p className='2xl:text-22 text-lg font-medium text-placeholder'>
                  {user.userDetails.degree}
                </p>
              </div>
            )}
            {user?.userDetails?.graduation_year && (
              <div className='w-full flex items-center justify-between'>
                <p className='2xl:text-22 text-lg font-medium'>
                  Graduation Year
                </p>
                <p className='2xl:text-22 text-lg font-medium text-placeholder'>
                  {user.userDetails.graduation_year}
                </p>
              </div>
            )}
            {user?.userDetails?.school && (
              <div className='w-full flex items-center justify-between'>
                <p className='2xl:text-22 text-lg font-medium'>School </p>
                <p className='2xl:text-22 text-lg font-medium text-placeholder'>
                  {user?.userDetails?.school}
                </p>
              </div>
            )}
          </div>
        </div>
        <div className='h-11'></div>
      </div>
      {actionDialog && actionDialog.open && <ActionDialog />}
    </div>
  );
};

export default UserProfile;

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useFormik } from 'formik';
import { string, object } from 'yup';

import Button from 'components/Button/Button';
import ImagePicker from 'components/ImagePicker/ImagePicker';
import TextField from 'components/TextField/TextField';

import JobTitleSelector from 'components/JobTitleSelector/JobTitleSelector';
import LocationPicker from 'components/LocationPicker/LocationPicker';
const schema = object({
  first_name: string().required('Required field').label('First Name'),
  last_name: string().required('Required field').label('Last Name'),
  job_title: string().required('Required field').label('Job Title'),
  location: string().required('Required field').label('Location'),
});

const ProfileAndLocation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [image, setImage] = useState<File | string | null>(null);

  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.user);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      job_title: '',
      location: '',
    },
    onSubmit: values => {
      if (image && typeof image !== 'string') {
        const formData = new FormData();
        formData.append('profile', image);
        dispatch.user.handleUpdateProfile({
          values,
          navigate,
          profile: formData,
        });
      } else {
        dispatch.user.handleUpdateProfile({ values, navigate });
      }
    },
    validationSchema: schema,
  });

  const onLocationChange = (location: string) => {
    formik.setFieldValue('location', location);
  };

  useEffect(() => {
    if (user) {
      formik.setValues({
        first_name: user.first_name,
        last_name: user.last_name,
        job_title: user.job_title || '',
        location: user.location || '',
      });
      setImage(user.image_url);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Container className='bg-white 2xl:p-11 p-5 border-[0.5px] border-grey-600 rounded-[12px]'>
      <div className='flex justify-center'>
        <ImagePicker image={image} setImage={setImage} />
      </div>
      <form
        onSubmit={e => e.preventDefault()}
        className='flex flex-col 2xl:gap-[34px] gap-5 w-full 2xl:mt-[51px] mt-6'
      >
        <div className='grid grid-cols-12 gap-5'>
          <div className='col-span-6'>
            <TextField
              containerClassName='2xl:!h-[70px]'
              inputClassName='2xl:!text-lg'
              placeholder='First Name*'
              name='first_name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              error={
                formik.touched.first_name && formik.errors.first_name
                  ? formik.errors.first_name
                  : ''
              }
            />
          </div>
          <div className='col-span-6'>
            <TextField
              containerClassName='2xl:!h-[70px]'
              inputClassName='2xl:!text-lg'
              placeholder='Last Name*'
              name='last_name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              error={
                formik.touched.last_name && formik.errors.last_name
                  ? formik.errors.last_name
                  : ''
              }
            />
          </div>
        </div>

        <JobTitleSelector
          value={formik.values.job_title}
          onChange={(val: string) => formik.setFieldValue('job_title', val)}
          error={
            formik.touched.job_title && formik.errors.job_title
              ? (formik.errors.job_title as string)
              : ''
          }
        />
        <LocationPicker
          containerClassName='2xl:!h-[70px]'
          inputClassName='2xl:!text-lg'
          name='location'
          placeholder='Location*'
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.location}
          error={
            formik.touched.location && formik.errors.location
              ? formik.errors.location
              : ''
          }
          onValueChange={onLocationChange}
        />
        <Button
          label='Next'
          className='!w-full 2xl:!h-[56px] 2xl:!text-22 !text-lg 2xl:mt-16 mt-6'
          onClick={() => formik.handleSubmit()}
          type='submit'
          loading={loading}
        />
      </form>
    </Container>
  );
};

export default ProfileAndLocation;

const Container = styled.div`
  width: 100%;
  max-width: 776px;
  margin-inline: auto;
`;

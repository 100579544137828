import React, { FC } from 'react';
import ASSETS from 'assets';

interface IProps {
  message: string;
  icon?: string;
  children?: React.ReactNode;
}

const NotFound: FC<IProps> = ({ message, icon, children }) => {
  return (
    <div className='w-full min-h-[295px] bg-white border border-grey-600 rounded-[19px] flex flex-col justify-center items-center gap-[25px]'>
      <img src={icon || ASSETS.edit} alt='' />
      <p className='text-22 font-medium text-placeholder'>{message}</p>
      {children}
    </div>
  );
};

export default NotFound;

import React, { useEffect, useState } from 'react';

import { Range, getTrackBackground } from 'react-range';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Accordion from './Accordion';

const SalaryRange = () => {
  const dispatch = useDispatch<Dispatch>();
  const { min, max } = useSelector((state: RootState) => state.filters);
  const [values, setValues] = useState([10, 10]);

  useEffect(() => {
    setValues([min, max]);
  }, []);

  return (
    <Accordion label='Salary Range'>
      <div className='px-6 pb-1 flex flex-col gap-1'>
        <div className='my-10'>
          <Range
            values={values}
            onChange={values => {
              setValues(values);
              dispatch.filters.setSalaryRangeFilter(values as [number, number]);
            }}
            min={10}
            max={500}
            step={5}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: '4px',
                  width: '100%',
                  background: getTrackBackground({
                    values,
                    colors: ['#DAD9DB', '#008080', '#DAD9DB'],
                    min: 10,
                    max: 500,
                  }),
                  borderRadius: '16px',
                  alignSelf: 'center',
                }}
              >
                {children}
                <div className='flex justify-between mt-1 pt-3'>
                  <span className='text-sm text-placeholder'>$10k</span>
                  <span className='text-sm text-placeholder'>$500k</span>
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged, index }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                }}
                className='size-4 outline-none rounded-full bg-primary flex justify-center'
              >
                <div className='absolute top-[-28px] text-black font-medium text-xs'>
                  {`$${values[index]}`}k
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default SalaryRange;

import React from 'react';

const About = () => {
  return (
    <div className='2xl:px-6 px-4 2xl:py-9 py-5'>
      <p className='2xl:text-2xl text-xl font-bold'>About</p>
      <p className='2xl:mt-8 mt-4  font-medium'>Introduction</p>
      <p className='2xl:text-base text-sm 2xl:mt-3 mt-2'>
        Welcome to Final Round, the premier platform connecting job seekers,
        recruiters, companies, and staffing agents in one dynamic ecosystem.
        Whether you're searching for your next career move, looking to hire top
        talent, or seeking the best candidates for your clients, Final Round
        simplifies and enhances the recruitment process for everyone involved.
      </p>
      <p className='mt-8 text-lg font-medium'>Vision</p>
      <p className='2xl:text-base text-sm 2xl:mt-3 mt-2'>
        At Final Round, we envision a world where the job search and recruitment
        processes are seamless, efficient, and mutually beneficial for all
        parties. We aim to become the go-to platform that revolutionizes how job
        seekers and employers connect, fostering opportunities and empowering
        careers across industries.
      </p>
      <p className='mt-8 text-lg font-medium'>Mission</p>
      <p className='2xl:text-base text-sm 2xl:mt-3 mt-2'>
        Our mission is to bridge the gap between talent and opportunity by
        providing a user-friendly, innovative platform that meets the needs of
        job seekers, recruiters, companies, and staffing agents. We are
        committed to:
      </p>
      <p className='2xl:text-base text-sm 2xl:mt-3 mt-2'>
        Empowering Job Seekers: Offering tools, resources, and connections to
        help candidates find their perfect fit and advance their careers.
        Supporting Recruiters: Streamlining the recruitment process with
        advanced search capabilities, comprehensive candidate profiles, and
        efficient communication tools. Enabling Companies: Assisting
        organizations in building strong, diverse teams by connecting them with
        the best talent available. Assisting Staffing Agents: Enhancing their
        ability to match clients with top candidates through a robust, intuitive
        platform.
      </p>
    </div>
  );
};

export default About;

import { FC, useEffect, useState } from 'react';

import styled from 'styled-components';

import ASSETS from '../../assets';

interface IProps {
  className?: string;
  value?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: FC<IProps> = ({ className, onChange, value }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = () => {
    setChecked(!checked);
    if (typeof onChange === 'function') onChange(!checked);
  };

  useEffect(() => {
    if (value) {
      setChecked(value);
    } else {
      setChecked(false);
    }
  }, [value]);
  return (
    <Container className={className}>
      <div className='box'>
        <label htmlFor='checkbox'>
          <img
            src={checked ? ASSETS.checkboxCheck : ASSETS.checkbox}
            alt=''
            onClick={handleChange}
          />
        </label>
      </div>
    </Container>
  );
};

export default Checkbox;

const Container = styled.div`
  & label {
    line-height: 14px;
    height: 14px;
    width: 14px;
    cursor: pointer;
  }

  & input {
    display: none;
  }
`;

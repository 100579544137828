import React, { useRef, useState, useEffect, FC, useCallback } from 'react';
import classNames from 'classnames';
import ASSETS from 'assets';
import { getJobTitles } from 'http/jobService';
import { IJobTitle } from 'types/interfaces';
import debounce from 'utils';

interface IProps {
  error?: string;
  onChange?: (val: string) => void;
  value?: string;
}

const JobTitleSelector: FC<IProps> = ({ error, value, onChange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(value || '');
  const [titles, setTitles] = useState<IJobTitle[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSelect = (val: string) => {
    setSearch(val);
    if (typeof onChange === 'function') {
      setSearch(val);
    }
    setOpen(false);
  };

  const fetchTitles = async (search: string) => {
    try {
      const { data } = await getJobTitles(search);
      setTitles(data.data.job_titles);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  // eslint-disable-next-line
  const debouncedFetchTitles = useCallback(
    debounce((searchTerm: string) => fetchTitles(searchTerm), 300),
    []
  );

  const handleFetchTitles = (search: string) => {
    debouncedFetchTitles(search);
  };

  const toggleMenu = () => setOpen(!open);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleFetchTitles(search);
    if (typeof onChange === 'function') {
      onChange(search);
    }
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className='w-full max-w-[800px]' ref={containerRef}>
      {/* <p className='text-base font-medium mb-1'>Job Tags Selector</p> */}
      <div className='w-full relative'>
        <div
          className={classNames(
            'flex items-center h-11 border rounded-lg border-grey-400 px-[15px] gap-2',
            error && 'border-red'
          )}
          onClick={toggleMenu}
        >
          <img src={ASSETS.search} className='h-[18px]' alt='' />
          <input
            type='text'
            value={search}
            onChange={handleChange}
            className='flex-grow h-full outline-none border-none rounded-lg placeholder:opacity-85 placeholder:text-black'
            placeholder='Job Title*'
          />
          {search && (
            <img
              src={ASSETS.check}
              //   onClick={handleAddTag}
              className='z-10 cursor-pointer'
              alt=''
            />
          )}
          <img src={ASSETS.chevronDown} alt='' />
        </div>
        {open && (
          <div className='border-[0.5px] flex-grow border-grey-400 bg-white rounded mt-[5px] shadow-mm max-h-[313px] overflow-auto empty:hidden z-10 absolute bottom-0 w-full translate-y-[calc(100%_+_8px)] '>
            {titles.map(title => (
              <div
                key={title.title}
                className='flex cursor-pointer items-center gap-[13px] py-[10px] px-4 border-b-[0.5px] border-b-grey-400 last-of-type:border-b-0 '
                onClick={() => {
                  handleSelect(title.title);
                }}
              >
                <p className='text-sm font-medium'>{title.title}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {error && (
        <div className='flex items-center gap-1 mt-1'>
          <img src={ASSETS.errorInfo} alt='' />
          <p className='text-xs text-error'>{error}</p>
        </div>
      )}
    </div>
  );
};

export default JobTitleSelector;

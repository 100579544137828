import React, { FC, useEffect, useRef, useState } from 'react';
import ASSETS from 'assets';
import ClickAwayListener from 'react-click-away-listener';
import Message from './Message';
import ActionMessage from './ActionMessage';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { useNavigate } from 'react-router-dom';
import AutomatedMessages from 'Dialogs/AutomatedMessages/AutomatedMessages';

const Messages: FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const ref = useRef<HTMLTextAreaElement | null>(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [value, setValue] = useState('');
  const [miniMenu, setMiniMenu] = useState(false);
  const navigate = useNavigate();

  const [openAutoMessages, setOpenAutoMessages] = useState(false);

  const { role } = useSelector((state: RootState) => state.auth);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const toggleMiniMenu = () => setMiniMenu(!miniMenu);
  const closeMiniMenu = () => setMiniMenu(false);

  const handleBlock = () => {
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Block User',
      message: 'Are you sure you want to block this user?',
      buttonTitle: 'Block',
      onClick: () => dispatch.utils.closeActionDialog(),
    });
  };

  const handleReport = () => {
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Report User',
      message: 'Are you sure you want to report this user?',
      buttonTitle: 'Report',
      onClick: () => {
        navigate('/report');
        dispatch.utils.closeActionDialog();
      },
    });
  };

  const handleSchedular = () => {
    navigate('/chat/schedule');
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.style.height = '0px';
      const scroll = ref.current.scrollHeight;
      ref.current.style.height = `${scroll}px`;
    }
    // eslint-disable-next-line
  }, [value]);
  return (
    <div className='flex-grow bg-white flex flex-col'>
      <div className='2xl:h-[80px] h-[65px] border-b-[0.5px] border-b-grey-600 flex items-center justify-between px-6'>
        <div className='flex items-center 2xl:gap-6 gap-4'>
          <img
            src={ASSETS.company}
            className='2xl:size-[55px] size-[50px] rounded-full'
            alt=''
          />
          <p className='2xl:text-xl text-lg font-medium'>Dragon Zone Studio</p>
        </div>
        <div className='flex items-center gap-3'>
          <img src={ASSETS.videoIcon} className='w-6 2xl:w-auto' alt='' />
          {role === 'employer' && (
            <div className='cursor-pointer' onClick={handleSchedular}>
              <img src={ASSETS.schedule} className='w-5 2xl:w-auto' alt='' />
            </div>
          )}
          <div className='relative'>
            <img
              src={ASSETS.menu}
              className='cursor-pointer h-5 2xl:h-auto'
              onClick={toggleMenu}
              alt=''
            />
            {openMenu && (
              <ClickAwayListener onClickAway={closeMenu}>
                <div className='menu absolute z-10  w-[150px] shadow-popup rounded-[8px] bg-white right-0 flex flex-col gap-[2px] overflow-hidden 2xl:top-9 top-6'>
                  <div
                    className='px-4 flex items-center gap-[9px] py-2 hover:bg-grey-100 cursor-pointer'
                    onClick={() => {
                      handleBlock();
                      closeMenu();
                    }}
                  >
                    <img src={ASSETS.blockCircle} alt='' />
                    <p className='text-base font-medium'>Block </p>
                  </div>
                  <div
                    className='px-4 flex items-center gap-[9px] py-2 hover:bg-grey-100 cursor-pointer'
                    onClick={() => {
                      handleReport();
                      closeMenu();
                    }}
                  >
                    <img src={ASSETS.flag} alt='' />
                    <p className='text-base font-medium'>Report User </p>
                  </div>
                  <div className='px-4 flex items-center gap-[9px] py-2 hover:bg-grey-100 cursor-pointer'>
                    <img src={ASSETS.bin} alt='' />
                    <p className='text-base font-medium'>Delete Chat </p>
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
        </div>
      </div>
      <div className='flex-grow flex flex-col py-5 overflow-auto h-0 2xl:px-[32px] px-5 gap-5'>
        <Message
          message='We need to discuss about our salary package, are you available at the moment'
          isMine={false}
        />
        <Message
          message='Hi, I am doing well too, Yes, you can see my resume I applied through'
          isMine={true}
        />
        <Message
          message='Yes, sure. I can send you the weblink Hope that works for you? '
          isMine={false}
        />

        <Message
          message='Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae harum ipsa odio, quibusdam velit voluptate corrupti, sint sequi recusandae, rerum rem deleniti! Odit esse inventore quae quis, dignissimos illum perspiciatis.'
          isMine={true}
        />
        <ActionMessage />
      </div>
      {/* Chat input */}
      <div className='2xl:pb-[30px] 2xl:px-[32px] pb-5 px-5 pt-2'>
        <div className='w-full bg-white rounded-[28px] 2xl:min-h-[60px] min-h-[50px] shadow-popup flex items-center gap-[10px] px-[10px] py-[10px]'>
          <div className='relative'>
            <img
              src={ASSETS.addCircle}
              onClick={toggleMiniMenu}
              className='cursor-pointer'
              alt=''
            />
            {miniMenu && (
              <ClickAwayListener onClickAway={closeMiniMenu}>
                <div
                  className='absolute w-[49px] py-5  top-0 translate-y-[calc(-100%_-_27px)] rounded-3xl flex flex-col gap-[11px] left-0 translate-x-[-20%] bg-grey-100 
            shadow-[0px_4px_4px_0px_#00000040]'
                >
                  <div className='grid place-items-center cursor-pointer'>
                    <img src={ASSETS.img} alt='' />
                  </div>
                  <div className='grid place-items-center cursor-pointer'>
                    <img src={ASSETS.file} alt='' />
                  </div>
                  <div className='grid place-items-center cursor-pointer'>
                    <img src={ASSETS.gif} alt='' />
                  </div>
                  <div className='grid place-items-center cursor-pointer'>
                    <img src={ASSETS.emoji} alt='' />
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
          <textarea
            name=''
            className='flex-grow resize-none outline-none 2xl:text-lg text-sm 2xl:h-7 h-6 max-h-[150px] 2xl:leading-[24px] leading-5'
            placeholder='Write a message....'
            id=''
            ref={ref}
            value={value}
            onChange={e => setValue(e.currentTarget.value)}
          ></textarea>
          <div>
            <img
              src={ASSETS.quickMessage}
              onClick={() => setOpenAutoMessages(true)}
              className='cursor-pointer'
              alt=''
            />
          </div>
          <div className='2xl:size-10 size-9 rounded-full grid place-items-center bg-primary'>
            <img src={ASSETS.sendWhite} className='2xl:w-auto w-5' alt='' />
          </div>
        </div>
      </div>
      {openAutoMessages && (
        <AutomatedMessages
          open={openAutoMessages}
          setOpen={setOpenAutoMessages}
        />
      )}
    </div>
  );
};

export default Messages;

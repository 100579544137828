import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ISearchData } from 'types/interfaces';
import { searchUsers } from 'http/userService';

interface IState {
  loading: boolean;
  data: ISearchData | null;
}

interface ISearchPayload {
  search: string;
  location: string;
}

export const search = createModel<RootModel>()({
  state: {
    loading: false,
    data: null,
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setData(state, payload: ISearchData | null) {
      state.data = payload;
    },
  },
  effects: dispatch => ({
    async handleSearch(payload: ISearchPayload) {
      try {
        if (payload.search.trim() === '' && payload.location?.trim() === '') {
          dispatch.search.setData(null);
        }
        dispatch.search.setLoading(true);
        const { data } = await searchUsers({
          text: payload.search,
          location: payload.location,
        });
        dispatch.search.setData(data.data);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch.search.setLoading(false);
      }
    },
  }),
});

import React, { FC, useEffect, useState } from 'react';
import ASSETS from 'assets';

import styled from 'styled-components';
import ClickAwayListener from 'react-click-away-listener';
import { SelectOption } from 'types/types';
import classNames from 'classnames';

interface IProps {
  selected: string;
  options: Array<SelectOption>;
  onChange: (option: string) => void;
  disabled?: boolean;
  label?: string;
  containerClassName?: string;
  error?: string;
  placeholder?: string;
}

const Select: FC<IProps> = ({
  options,
  selected,
  onChange,
  disabled = false,
  label,
  containerClassName,
  error,
  placeholder,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<null | SelectOption>(
    null
  );
  const toggleMenu = () => {
    if (disabled) return;
    setOpen(!open);
  };
  const closeMenu = () => setOpen(false);

  const handleSelect = (option: SelectOption) => {
    if (selected !== option.value) {
      setSelectedOption(option);
      onChange(option.value as string);
    }
    closeMenu();
  };

  useEffect(() => {
    const option = options.find(opt => opt.value === selected);
    if (option) {
      setSelectedOption(option);
    } else {
      setSelectedOption(null);
    }
    // eslint-disable-next-line
  }, [selected, options]);

  return (
    <Container className='relative'>
      {label && <p className='text-base font-medium mb-[2px]'>{label}</p>}
      <div className='relative w-full'>
        <div
          className={classNames(
            'wrapper bg-white border border-grey-400 rounded-lg flex relative',
            disabled && '!bg-grey-400',
            open && 'border-secondary',
            error && 'border-red'
          )}
        >
          <div
            className={classNames(
              'flex 2xl:min-h-12 min-h-11 justify-between items-center w-full h-full pl-4 pr-[13px] cursor-pointer ',
              disabled && 'cursor-not-allowed',
              containerClassName
            )}
            onClick={toggleMenu}
          >
            <p className='text-base font-normal opacity-85'>
              {selectedOption?.label || placeholder || 'Select'}
            </p>
            <img src={ASSETS.chevronDown} alt='' />
          </div>
        </div>

        {open && (
          <ClickAwayListener onClickAway={closeMenu}>
            <div className='menu bg-white shadow-popup absolute bottom-0 translate-y-[calc(100%_+_10px)] z-20  w-full left-0 rounded'>
              {options.map(opt => (
                <div
                  key={opt.value}
                  className='flex items-center justify-between h-10 px-4 rounded cursor-pointer'
                  onClick={() => handleSelect(opt)}
                >
                  <p className='text-16'>{opt.label}</p>
                  {selectedOption?.value === opt.value && (
                    <img src={ASSETS.check} alt='' />
                  )}
                </div>
              ))}
            </div>
          </ClickAwayListener>
        )}
      </div>

      {error && <p className='text-xs text-error mt-1'>{error}</p>}
    </Container>
  );
};

export default Select;

const Container = styled.div`
  width: 100%;
  max-width: 900px;
`;

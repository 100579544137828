import React, { FC, InputHTMLAttributes, useState } from 'react';
import TextField from 'components/TextField/TextField';
import LocationDialog from 'Dialogs/LocationDialog/LocationDialog';
import ASSETS from 'assets';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  onValueChange: (val: string) => void;
  containerClassName?: string;
  inputClassName?: string;
}

const LocationPicker: FC<IProps> = ({
  error,
  onValueChange,
  containerClassName,
  inputClassName,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TextField
        containerClassName={containerClassName}
        inputClassName={inputClassName}
        icon={ASSETS.location}
        onFocus={() => setOpen(true)}
        {...rest}
        error={error}
      />
      {open && (
        <LocationDialog
          open={open}
          setOpen={setOpen}
          onChange={onValueChange}
        />
      )}
    </>
  );
};

export default LocationPicker;

import Switch from 'components/Switch/Switch';
import React from 'react';

const EmailNotification = () => {
  return (
    <div className='2xl:p-9 p-5 flex flex-col gap-[5px]'>
      <div className='flex items-center justify-between h-12 border-b-[0.5px] border-b-grey-600 px-[18px]'>
        <p className='2xl:text-lg text-base font-medium'>Notifications</p>
        <Switch />
      </div>
      <div className='flex items-center justify-between h-12 border-b-[0.5px] border-b-grey-600 px-[18px]'>
        <p className='2xl:text-lg text-base font-medium'>Messages</p>
        <Switch />
      </div>
      <div className='flex items-center justify-between h-12 border-b-[0.5px] border-b-grey-600 px-[18px]'>
        <p className='2xl:text-lg text-base font-medium'>
          Final Round Invitations
        </p>
        <Switch />
      </div>
      <div className='flex items-center justify-between h-12 border-b-[0.5px] border-b-grey-600 px-[18px]'>
        <p className='2xl:text-lg text-base font-medium'>
          Received Applications
        </p>
        <Switch />
      </div>
    </div>
  );
};

export default EmailNotification;

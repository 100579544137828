const ASSETS = {
  chevronLeft: require('./icons/chevron-left.svg').default,
  show: require('./icons/show.svg').default,
  showSlash: require('./icons/show-slash.svg').default,
  errorInfo: require('./icons/error-info.svg').default,
  google: require('./icons/google.svg').default,
  facebook: require('./icons/facebook.svg').default,
  verified: require('./icons/verified.svg').default,
  close: require('./icons/close.svg').default,
  logo: require('./icons/logo.svg').default,
  notification: require('./icons/notification.svg').default,
  userImg: require('./icons/userImg.svg').default,
  addBtn: require('./icons/add-btn.svg').default,
  search: require('./icons/search.svg').default,
  location: require('./icons/location.svg').default,
  users: require('./icons/users.svg').default,
  bag: require('./icons/bag.svg').default,
  chat: require('./icons/chat.svg').default,
  home: require('./icons/home.svg').default,
  homeActive: require('./icons/homeActive.svg').default,
  usersActive: require('./icons/usersActive.svg').default,
  chatActive: require('./icons/chatActive.svg').default,
  bagActive: require('./icons/bagActive.svg').default,
  filters: require('./icons/filters.svg').default,
  user: require('./icons/user.svg').default,
  settings: require('./icons/settings.png'),
  bars: require('./icons/bars.svg').default,
  premium: require('./icons/premium.svg').default,
  logout: require('./icons/logout.svg').default,
  post: require('./img/post.png'),
  postIcon: require('./img/post-icon.png'),
  info: require('./icons/info.svg').default,
  menu: require('./icons/menu.svg').default,
  bookmark: require('./icons/bookmark.svg').default,
  flag: require('./icons/flag.svg').default,
  gameLogo: require('./img/game-logo.png'),
  job: require('./img/job.png'),
  notFound: require('./img/not-found.png'),
  edit: require('./icons/edit.svg').default,
  eyeYellow: require('./icons/eyeYellow.svg').default,
  saved: require('./icons/saved.svg').default,
  calender: require('./icons/calender.svg').default,
  clock: require('./icons/clock.svg').default,
  handshake: require('./img/handshake.png'),
  calenderLight: require('./icons/calendarLight.svg').default,
  bookmarkWhite: require('./icons/boolmarkWhite.svg').default,
  chevronRight: require('./icons/chevron-right.svg').default,
  cross: require('./icons/cross.svg').default,
  circle: require('./icons/circle.svg').default,
  circleFilled: require('./icons/circle-filled.svg').default,
  checkbox: require('./icons/checkbox.svg').default,
  checkboxCheck: require('./icons/checkbox-check.svg').default,
  plusYellow: require('./icons/plus-yellow.svg').default,
  editCircle: require('./icons/edit-circle.svg').default,
  chevronDown: require('./icons/chevron-down.svg').default,
  video: require('./img/video.png'),
  editRound: require('./icons/edit-round.svg').default,
  noApplications: require('./img/no-applications.png'),
  profilePreview: require('./img/profile-preview.png'),
  plusCircle: require('./icons/plus-circle.svg').default,
  doc: require('./icons/doc.png'),
  delete: require('./icons/delete.svg').default,
  mapButton: require('./icons/map-button.svg').default,
  check: require('./icons/check.svg').default,
  closeSmall: require('./icons/close-small.svg').default,
  loginBg: require('./img/login-bg.png'),
  email: require('./icons/email.svg').default,
  googleLogo: require('./icons/googleLogo.svg').default,
  plusIcon: require('./icons/plus-icon.svg').default,
  gallery: require('./icons/gallery.svg').default,
  camera: require('./icons/camera.svg').default,
  draghandle: require('./icons/drag-handle.svg').default,
  other: require('./img/other.png'),
  menuYellow: require('./icons/menuYellow.svg').default,
  blockCircle: require('./icons/blockCircle.svg').default,
  arrowRight: require('./icons/arrow-right.svg').default,
  company: require('./img/company.png'),
  send: require('./icons/send.svg').default,
  bookmarkYellow: require('./icons/bookmark-yellow.svg').default,
  tips: require('./icons/tips.svg').default,
  checkCircle: require('./icons/checkCircle.svg').default,
  retake: require('./icons/retake.svg').default,
  videoIcon: require('./icons/video.svg').default,
  bin: require('./icons/bin.svg').default,
  addCircle: require('./icons/addYellow.svg').default,
  sendWhite: require('./icons/send-white.svg').default,
  merge: require('./img/merge.png'),
  submit: require('./icons/submit.svg').default,
  applicantPic: require('./img/applicant-pic.png'),
  pdf: require('./icons/pdf.svg').default,
  word: require('./icons/word.svg').default,
  user1: require('./img/user1.png'),
  user2: require('./img/user2.png'),
  img: require('./icons/img.svg').default,
  file: require('./icons/file.svg').default,
  gif: require('./icons/gif.svg').default,
  emoji: require('./icons/emoji.svg').default,
  schedule: require('./icons/schedule.svg').default,
  quickMessage: require('./icons/quick-message.svg').default,
  editBlack: require('./icons/edit-black.svg').default,
  trash: require('./icons/trash.svg').default,
  circleGrey: require('./icons/circle-grey.svg').default,
  emojiPlus: require('./icons/emoji-plus.svg').default,
  sendGreen: require('./icons/send-green.svg').default,
  checkP: require('./icons/check-p.svg').default,
  calenderD: require('./icons/calenderDark.svg').default,
  editPen: require('./icons/edit-pen.svg').default,

  job1: require('./icons/job-1.svg').default,
  job2: require('./icons/job-2.svg').default,
  job3: require('./icons/job-3.svg').default,
  job4: require('./icons/job-4.svg').default,
  job5: require('./icons/job-5.svg').default,
  job6: require('./icons/job-6.svg').default,
  arrowForward: require('./icons/arrow-forward.svg').default,
  arrowForwardActive: require('./icons/arrow-forward-active.svg').default,
  preview: require('./img/preview.png'),
  no_job: require('./icons/no-job.svg').default,
  play: require('./img/play.png'),
  pause: require('./img/pause.png'),
};

export default ASSETS;

import React, { useState } from 'react';

const Switch = () => {
  const [isOn, setIsOn] = useState(false);

  const toggleSwitch = () => {
    setIsOn(prevState => !prevState);
  };

  return (
    <div
      className={`w-[41px] h-[21px] flex items-center rounded-full p-[2.7px] cursor-pointer transition-colors duration-300 ${
        isOn ? 'bg-teal-600' : 'bg-placeholderColor'
      }`}
      onClick={toggleSwitch}
    >
      <div
        className={`bg-white w-[15.5px] h-[15.5px] rounded-full shadow-md transform transition-transform duration-300 ${
          isOn ? 'translate-x-[20px]' : 'translate-x-0'
        }`}
      ></div>
    </div>
  );
};

export default Switch;

import React, { FC, useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import ClickAwayListener from 'react-click-away-listener';
import { Link, useNavigate } from 'react-router-dom';

import ASSETS from 'assets';
import { IUser, IVideo } from 'types/interfaces';
import { getUserById } from 'http/authService';

import dayjs from 'dayjs';

interface IProps {
  index?: number;
  post: IVideo;
}

const Applicant: FC<IProps> = ({ index, post }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [videoSrc, setVideoSrc] = useState<string | null>(null);

  const [user, setUser] = useState<IUser | null>(null);

  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const closeMenu = () => setOpenMenu(false);
  const toggleMenu = () => setOpenMenu(!openMenu);

  const handleGetUser = async () => {
    try {
      if (post.user_id) {
        const { data } = await getUserById(post.user_id);
        setUser(data.data);
      }
    } catch (err: any) {}
  };

  const handleReport = () => {
    closeMenu();
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Report profile',
      message: 'Are you sure you want to report this profile?',
      buttonTitle: 'Report',
      onClick: () => {
        navigate('/report');
        dispatch.utils.closeActionDialog();
      },
    });
  };

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleLoadData = () => {
    try {
      const videoElement = videoRef.current;

      if (videoElement && index === 0) {
        // Play the video immediately if it is the first video
        setVideoSrc(post.url || '');
        videoElement.play().catch((err: any) => {
          console.log('Immediate video playback failed: ', err);
        });
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    handleGetUser();
    // eslint-disable-next-line
  }, [post]);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= 0.7) {
          // Set the video source and play
          if (!videoSrc) {
            setVideoSrc(post.url || '');
          }
          (entry.target as HTMLVideoElement).play().catch(err => {
            console.log('Video playback failed: ', err);
            // (entry.target as HTMLVideoElement).muted = true;
            // (entry.target as HTMLVideoElement).play().catch(err => null);
          });
        } else {
          // Pause the video if less than 70% in view
          (entry.target as HTMLVideoElement).pause();
        }
      });
    };

    const observerOptions = {
      root: null, // Use the viewport as the root
      threshold: 0.7, // Trigger when 70% of the video is in view
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );
    const videoElement = videoRef.current;

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) {
        observer.unobserve(videoElement);
      }
    };
    // eslint-disable-next-line
  }, [videoSrc, post]);

  return (
    <div className='border-[0.5px] border-grey-600 h-full flex items-stretch mt-[22px] first-of-type:mt-0 rounded-lg overflow-hidden'>
      <div className='!flex-shrink-0 h-full   flex-[58%]   overflow-hidden'>
        <video
          src={videoSrc || ''}
          ref={videoRef}
          controls
          className='h-[100%] w-full object-contain bg-black'
          loop
          disablePictureInPicture
          controlsList='nodownload noplaybackrate'
          poster={post?.thumbnail}
          onLoadedData={handleLoadData}
        ></video>
      </div>
      <div className='flex-[42%]  pt-[29px] flex flex-col bg-white'>
        <div className='flex items-center gap-4 px-[26px]'>
          <img
            src={user?.image_url || ASSETS.preview}
            className='min-w-[100px] max-w-[100px] h-[100px] 2xl:min-w-[140px]  2xl:max-w-[100px] 2xl:h-[140px] object-cover rounded-full bg-grey-200'
            alt=''
          />
          <div className='flex items-start justify-between flex-grow'>
            <div>
              <p className='2xl:text-2xl text-22 font-bold'>
                <Link to={`/other-job-seeker/${post.user_id}`}>
                  {post.first_name} {post.last_name}
                </Link>
              </p>
              <>
                {post.user_job_title && (
                  <div className='flex items-center my-[5px]'>
                    <p className='text-sm 2xl:text-base text-placeholder font-bold'>
                      {post?.user_job_title}
                    </p>
                    {/* <img src={ASSETS.info} alt='' /> */}
                  </div>
                )}
                {post.location && (
                  <div className='flex items-center'>
                    <img
                      src={ASSETS.location}
                      className='ml-[-5px] w-5'
                      alt=''
                    />
                    <p className='text-sm 2xl:text-base font-medium'>
                      {post?.location}
                    </p>
                  </div>
                )}
              </>
            </div>
            <div className='menu relative'>
              <img
                src={ASSETS.menu}
                className='cursor-pointer'
                onClick={toggleMenu}
                alt=''
              />
              {openMenu && (
                <ClickAwayListener onClickAway={closeMenu}>
                  <div className='menu absolute  w-[171px] shadow-lg rounded-[8px] bg-white right-0 flex flex-col gap-[2px] overflow-hidden'>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[9px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={closeMenu}
                    >
                      <img src={ASSETS.bookmark} alt='' />
                      <p className='text-base font-medium'>Save profile</p>
                    </div>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[9px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleReport}
                    >
                      <img src={ASSETS.flag} alt='' />
                      <p className='text-base font-medium'>Report profile</p>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        </div>
        {post.videoType === 0 && (
          <>
            {' '}
            <p className='2xl:text-2xl text-xl font-bold 2xl:mt-[31px] mt-4 px-[26px]'>
              Applicant Details:
            </p>
            <div className='flex-grow px-[26px] pb-4 h-0 scroll-view overflow-auto 2xl:mt-[21px] mt-3  [&_.item]:mt-[17px]'>
              {user?.userDetails?.company_name && (
                <div className='flex items-center justify-between'>
                  <p className='2xl:text-[22px] text-lg font-bold'>
                    Current Employer
                  </p>
                  <p className='2xl:text-[22px] text-lg font-bold text-placeholder'>
                    {user?.userDetails?.company_name}
                  </p>
                </div>
              )}
              {user?.userDetails?.start_date && (
                <div className='flex items-center justify-between item'>
                  <p className='2xl:text-[22px] text-lg font-bold'>
                    Start Date
                  </p>
                  <p className='2xl:text-[22px] text-lg font-bold text-placeholder'>
                    {dayjs(user?.userDetails?.start_date).format(
                      'MMM, DD YYYY'
                    )}
                  </p>
                </div>
              )}
              {user?.userDetails?.end_date && (
                <div className='flex items-center justify-between item'>
                  <p className='2xl:text-[22px] text-lg font-bold'>End Date</p>
                  <p className='2xl:text-[22px] text-lg font-bold text-placeholder'>
                    {dayjs(user?.userDetails?.end_date).format('MMM, DD YYYY')}
                  </p>
                </div>
              )}
              {user?.userDetails?.previous_company && (
                <div className='flex items-center justify-between item'>
                  <p className='2xl:text-[22px] text-lg font-bold'>
                    Previous Employer
                  </p>
                  <p className='2xl:text-[22px] text-lg font-bold text-placeholder'>
                    {user?.userDetails?.previous_company}
                  </p>
                </div>
              )}

              {user?.userDetails?.years_of_exp && (
                <div className='flex items-center justify-between item'>
                  <p className='2xl:text-[22px] text-lg font-bold'>
                    Years of Experience
                  </p>
                  <p className='2xl:text-[22px] text-lg font-bold text-placeholder'>
                    {+user?.userDetails?.years_of_exp === 1
                      ? `${user?.userDetails?.years_of_exp} Year`
                      : `${user?.userDetails?.years_of_exp} Years`}
                  </p>
                </div>
              )}
              {user?.userDetails?.degree && (
                <div className='flex items-center justify-between item'>
                  <p className='2xl:text-[22px] text-lg font-bold'>Degree</p>
                  <p className='2xl:text-[22px] text-lg font-bold text-placeholder'>
                    {user?.userDetails?.degree}
                  </p>
                </div>
              )}
              {user?.userDetails?.graduation_year && (
                <div className='flex items-center justify-between item'>
                  <p className='2xl:text-[22px] text-lg font-bold'>
                    Graduation Year
                  </p>
                  <p className='2xl:text-[22px] text-lg font-bold text-placeholder'>
                    {user?.userDetails?.graduation_year}
                  </p>
                </div>
              )}
              {user?.userDetails?.school && (
                <div className='flex items-center justify-between item'>
                  <p className='2xl:text-[22px] text-lg font-bold'>School </p>
                  <p className='2xl:text-[22px] text-lg font-bold text-placeholder'>
                    {user?.userDetails?.school}
                  </p>
                </div>
              )}
              {user?.userDetails?.cv_url && (
                <div className='attachment mt-[21px]'>
                  <p className='2xl:text-lg text-base font-medium leading-[28px]'>
                    Resume
                  </p>
                  <a
                    href={user.userDetails.cv_url}
                    download
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='border-[0.5px] mt-1 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px]'>
                      <img src={ASSETS.pdf} className='w-6 2xl:w-auto' alt='' />
                      <p className='text-sm font-medium'>
                        {user?.userDetails?.cv_type}
                      </p>
                    </div>
                  </a>
                </div>
              )}
              {user?.userDetails?.cover_url && (
                <div className='attachment mt-[11px] mb-[29px]'>
                  <p className='2xl:text-lg text-base font-medium leading-[28px]'>
                    Cover Letter/ Attachment
                  </p>
                  <a
                    href={user.userDetails.cover_url}
                    download
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='border-[0.5px] mt-1 border-placeholderColor 2xl:h-[65px] h-[50px] rounded-lg flex items-center gap-2 px-[20px]'>
                      <img
                        src={ASSETS.word}
                        className='w-6 2xl:w-auto'
                        alt=''
                      />
                      <p className='text-sm font-medium'>
                        {user?.userDetails?.cover_type}
                      </p>
                    </div>
                  </a>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Applicant;

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ClickAwayListener from 'react-click-away-listener';

import ASSETS from 'assets';
import { IJob } from 'types/interfaces';
import { followCompany, getJobById } from 'http/jobService';
import { Tooltip } from 'react-tooltip';
import classNames from 'classnames';
import QuillViewer from 'components/QuillViewer/QuillViewer';
import { convertNumberToK } from 'utils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Button from 'components/Button/Button';
import { applicantRoles } from 'app.config';

dayjs.extend(relativeTime);

const JobDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch<Dispatch>();

  const [following, setIsFollowing] = useState(false);
  const [applied, setApplied] = useState(false);
  const [fLoading, setFLoading] = useState(false);

  const { user } = useSelector((state: RootState) => state.auth);

  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState<IJob | null>(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenu = () => setMenuOpen(false);

  const handleFollow = async () => {
    try {
      if (!following) {
        setFLoading(true);
        if (job?.company_details.id) {
          await followCompany(job?.company_details.id);
          setIsFollowing(true);
        }
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setFLoading(false);
    }
  };

  const handleGetJob = async () => {
    try {
      setLoading(true);
      if (id) {
        const { data } = await getJobById(id);
        setJob(data.data);
        console.log(data.data);
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleApply = () => {
    if (!user?.role) {
      return dispatch.utils.setRoleModal(true);
    } else if (!user.introVideos?.length) {
      return navigate('/create-video');
    } else if (!user.job_title) {
      return navigate('/make-profile/step-1');
    } else if (!user.userDetails?.cv_url) {
      return navigate('/make-profile/step-3');
    } else if (!user.userDetails.graduation_year) {
      return navigate('/make-profile/step-4');
    }
    if (job) {
      dispatch.job.handleGetJobById({
        id: job?.id,
        onSuccess: () => {
          navigate('/apply/upload-documents');
        },
      });
    }
  };

  const handleReport = () => {
    closeMenu();
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Report Job',
      message: 'Are you sure you want to report this job?',
      buttonTitle: 'Report',
      onClick: () => {
        navigate('/report');
        dispatch.utils.closeActionDialog();
      },
    });
  };

  useEffect(() => {
    handleGetJob();
  }, [id]);

  useEffect(() => {
    if (job) {
      if (job.video.is_applied && user?.role === 'A') {
        setApplied(true);
      }
      if (job.video.is_followed) {
        setIsFollowing(true);
      }
    }
  }, [job]);

  return (
    <div className='flex-grow overflow-auto w-full'>
      <div className='h-full w-full scroll-item border border-grey-600 flex items-stretch rounded-lg overflow-hidden'>
        <div
          className='img-container h-full !flex-shrink-0  flex-[0_0_58%] bg-center bg-cover bg-no-repeat'
          style={{ backgroundImage: `url(${ASSETS.post})` }}
        >
          <video
            src={job?.video.url}
            controls
            className={classNames('h-[100%] w-full bg-black', 'object-contain')}
            loop
            disablePictureInPicture
            controlsList='nodownload noplaybackrate'
            poster={job?.video.thumbnail}
          ></video>
        </div>
        <div className='post-info flex-[0_0_42%] px-[21px] py-[23px] flex flex-col bg-white'>
          <div className='flex items-start gap-[9px] relative'>
            <div className='menu absolute right-0 top-3'>
              <div className='menu-opener'>
                <img
                  src={ASSETS.menu}
                  className='cursor-pointer'
                  onClick={toggleMenu}
                  alt=''
                />
                {menuOpen && (
                  <ClickAwayListener onClickAway={closeMenu}>
                    <div className='menu absolute  w-[171px] shadow-lg rounded-[8px] bg-white right-0 flex flex-col gap-[2px] overflow-hidden'>
                      <div
                        className='pl-5 pr-2 flex items-center gap-[9px] py-3 hover:bg-grey-100 cursor-pointer'
                        onClick={closeMenu}
                      >
                        <img src={ASSETS.bookmark} alt='' />
                        <p className='text-base font-medium'>Save Job</p>
                      </div>
                      <div
                        className='pl-5 pr-2 flex items-center gap-[9px] py-3 hover:bg-grey-100 cursor-pointer'
                        onClick={handleReport}
                      >
                        <img src={ASSETS.flag} alt='' />
                        <p className='text-base font-medium'>Report Job</p>
                      </div>
                    </div>
                  </ClickAwayListener>
                )}
              </div>
            </div>
            <img
              src={job?.company_details.company_logo || ASSETS.preview}
              className='min-w-[100px] max-w-[100px] h-[100px] 2xl:min-w-[140px]  2xl:max-w-[100px] 2xl:h-[140px] object-cover rounded-full bg-grey-200'
              alt=''
            />
            <div>
              {/* <Link to={`/other-employer/${job?.company_details.id}`}> */}
              <p className='text-22 2xl:text-2xl font-bold mt-3 pl-[2.5px] '>
                {job?.company_details.name}
              </p>
              {/* </Link> */}
              <div className='flex items-center mt-[6px]  pl-[2.5px]'>
                <p className='text-sm 2xl:text-base text-placeholder font-medium'>
                  {job?.title}
                </p>

                <Tooltip
                  // style={{
                  //   maxWidth: '300px',
                  //   fontSize: '14px',
                  //   color: '#000000',
                  //   background: '#ffffff',
                  //   boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                  // }}
                  className='max-w-[300px] !bg-white shadow-[rgba(149,_157,_165,_0.2)_0px_8px_24px] !text-black break-words !opacity-100 !z-50'
                  anchorSelect={`#tooltip-${job?.id}`}
                  content={job?.title_description}
                ></Tooltip>
                <img src={ASSETS.info} id={`tooltip-${job?.id}`} alt='' />
              </div>
              <div className='flex items-center gap-[4px] my-[6px]'>
                <div className='w-6 flex justify-center'>
                  <img src={ASSETS.location} className='w-5' alt='' />
                </div>
                <p className='text-xs 2xl:text-sm text-placeholder font-medium'>
                  {job?.company_details?.location}{' '}
                  <span className='text-placeholderColor'>
                    <span className='text-placeholderColor italic'>
                      ({job?.job_type})
                    </span>
                  </span>
                </p>
              </div>
              {/* <div className='flex items-center gap-[4px]'>
              <div className='w-6 flex justify-center'>
                <img src={ASSETS.bag} className='w-5' alt='' />
              </div>
              <p className='text-xs 2xl:text-sm text-placeholder font-medium '>
                Entry Level{' '}
              </p>
              <div className='w-1 h-1 bg-placeholderColor rounded-full'></div>
              <span className='text-xs 2xl:text-sm text-placeholderColor'>
                Full Time
              </span>
            </div> */}
              {applicantRoles.includes(user?.role || '') && (
                <div className='flex gap-1 mt-4'>
                  <Button
                    label={applied ? 'Applied' : 'Apply'}
                    className='2xl:!h-10 xl:!h-10'
                    onClick={() => {
                      if (applied) {
                        return null;
                      }
                      handleApply();
                    }}
                    loading={loading}
                  />
                  <Button
                    label={following ? 'Following' : 'Follow'}
                    variant='outlined'
                    className={classNames(
                      '2xl:!h-10 xl:!h-10',
                      following && '!border-black'
                    )}
                    onClick={handleFollow}
                    loading={fLoading}
                  />
                </div>
              )}
            </div>
          </div>
          <div className='scroll-view flex-grow overflow-auto h-0 mt-4 overflow-x-hidden'>
            <div className='flex items-center justify-between'>
              <p className='text-lg font-medium'>{job?.company_details.name}</p>
            </div>
            {job?.description && (
              <QuillViewer description={job?.company_details.description} />
            )}
            {job?.description && (
              <>
                <p className='text-lg'>Job Responsibilites:</p>
                <QuillViewer description={job.description} />
              </>
            )}
            <p className='text-base leading-6 font-medium mt-3'>
              Salary Range: ${convertNumberToK(job?.salary_from || 0)}/yr - $
              {convertNumberToK(job?.salary_to || 0)}/yr
            </p>
            <p className='text-13 font-medium mt-2'>
              {dayjs(job?.created_at || null).fromNow()}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetail;

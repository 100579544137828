import React, { FC, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import DialogLayout from 'Dialogs/DialogLayout';
import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { uploadVideo } from 'http/videoService';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SubmitVideo: FC<IProps> = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const { path } = useSelector((state: RootState) => state.utils);
  const { finalVideo, data, thumbnail } = useSelector(
    (state: RootState) => state.video
  );
  const { user } = useSelector((state: RootState) => state.auth);
  const { selectedJob, applicant_id } = useSelector(
    (state: RootState) => state.job
  );

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    try {
      setLoading(true);

      const videoData = new FormData();

      data.forEach((e, index) => {
        let t = 0;
        let obj = {
          question: e.question,
          end_time: t + +e.duration,
          order: index,
        };
        videoData.append(`questions[${index}][question]`, obj.question);
        videoData.append(
          `questions[${index}][end_time]`,
          obj.end_time.toString()
        );
        videoData.append(`questions[${index}][order]`, obj.order.toString());
        t += +e.duration;
      });

      videoData.append('video', finalVideo as Blob);
      videoData.append('thumbnail', thumbnail as Blob);

      if (user?.role) {
        videoData.append('role', user!.role);
      }

      if (path === 'apply') {
        videoData.append('videoType', '2');
        videoData.append('application_id', applicant_id);
      } else if (path === 'create-job' && selectedJob) {
        videoData.append('videoType', '1');
        videoData.append('job_id', selectedJob);
      } else {
        videoData.append('videoType', '0');
      }

      await uploadVideo(videoData);
      dispatch.auth.handleGetCurrentUser();

      if (finalVideo) {
        dispatch.video.setFinalVideo(null);
      }

      if (path === 'apply') {
        navigate('/application-submit');
      }
      if (path === 'create-job') {
        if (!user?.company[0].company_logo) {
          navigate('/company-profile-making');
        } else {
          navigate('/profile');
        }
      } else {
        if (!user?.job_title) {
          navigate('/make-profile/step-1');
        } else if (!user?.userDetails?.id) {
          navigate('/make-profile/step-4');
        } else {
          navigate('/explore');
        }
      }
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[484px] max-w-[380px] bg-grey-100 2xl:p-6 p-5 rounded-[16px]'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={handleClose}
            alt=''
          />
        </div>
        <Button
          label={
            'Upload'
            // path === 'apply'
            //   ? 'Submit Application'
            //   : path === 'create-job'
            //   ? 'Submit Posting'
            //   : 'Submit Video'
          }
          className='w-full 2xl:!text-22 !text-lg 2xl:mb-[35px] mb-0 2xl:mt-8 mt-5'
          onClick={handleSubmit}
          loading={loading}
        />
      </div>
    </DialogLayout>
  );
};

export default SubmitVideo;

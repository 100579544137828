import classNames from 'classnames';
import React, { FC } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface IProps {
  description: string;
  width?: number;
}

const QuillViewer: FC<IProps> = ({ description, width }) => {
  return (
    <div className='quill-viewer w-full' style={{ width: width }}>
      <ReactQuill
        value={JSON.parse(description)}
        readOnly={true}
        theme={'bubble'}
        // formats={['delta']}
        className={classNames('!p-0', width && `max-w-[${width}px]`)}
      />
    </div>
  );
};

export default QuillViewer;

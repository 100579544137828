import ASSETS from 'assets';
import React from 'react';

const ActionMessage = () => {
  return (
    <div className='w-full 2xl:max-w-[320px] max-w-[300px] bg-grey-100 rounded-[12px] shadow-popup'>
      <div className='px-[17px] pt-[11px] pb-[5px]'>
        <p className='2xl:text-xl text-lg text-primary font-medium'>
          Crabite Games
        </p>
        <p className='2xl:text-sm text-xs font-medium'>UX UI Designer</p>
        <p className='2xl:text-sm text-xs font-medium mt-[3px]'>
          Final Round <span className='text-red'>(Pending)</span>
        </p>
        <div className='flex items-center gap-6 mt-2'>
          <div className='flex items-center gap-2'>
            <img src={ASSETS.calender} alt='' />
            <p className='text-[13px] font-medium'>10/12/22</p>
          </div>
          <div className='flex items-center gap-2'>
            <img src={ASSETS.clock} alt='' />
            <p className='text-[13px] font-medium'>2:00 PM - 2:3O PM</p>
          </div>
        </div>
      </div>
      <div className='border-t border-t-grey-400 flex'>
        <button className='h-[42px] flex-grow border-r border-r-grey-400 font-medium text-sm text-[#FE858C]'>
          Decline
        </button>
        <button className='h-[42px] flex-grow text-[#039855] font-medium text-sm'>
          Accept
        </button>
      </div>
    </div>
  );
};

export default ActionMessage;

import React from 'react';
import ASSETS from 'assets';

const Chats = () => {
  return (
    <div className='flex-grow  overflow-auto h-0'>
      <div className='w-full 2xl:px-[25px] px-4 2xl:h-[104px] h-[90px] flex items-center justify-between gap-2 border-b-[0.5px] border-b-grey-600 first-of-type:border-t-[0.5px] first-of-type:border-t-grey-600'>
        <div className='flex items-center flex-grow gap-[10px]'>
          <img
            src={ASSETS.company}
            className='2xl:size-[55px] size-[50px]'
            alt=''
          />
          <div>
            <p className='2xl:text-lg text-base font-medium'>
              Dragon Zone Studio
            </p>
            <p className='text-xs text-placeholder mt-[5px]'>
              Can’t wait to hear from you soon, Acknowledge our final round.{' '}
            </p>
          </div>
          <div className='flex flex-col items-end '>
            <p className='text-xs whitespace-nowrap mt-[-5px]'>Sat, Jan 11</p>
            <div className='text-xs font-medium text-white h-[28px] min-w-[28px] rounded-full mt-4 bg-primary grid place-items-center'>
              <span className='text-inherit'>2</span>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full 2xl:px-[25px] px-4 2xl:h-[104px] h-[90px] flex items-center justify-between gap-2 border-b-[0.5px] border-b-grey-600 bg-grey-200'>
        <div className='flex items-center flex-grow gap-[10px]'>
          <img
            src={ASSETS.company}
            className='2xl:size-[55px] size-[50px]'
            alt=''
          />
          <div>
            <p className='2xl:text-lg text-base font-medium'>
              Dragon Zone Studio
            </p>
            <p className='text-xs text-placeholder mt-[5px]'>
              Can’t wait to hear from you soon, Acknowledge our final round.{' '}
            </p>
          </div>
          <div className='flex flex-col items-end '>
            <p className='text-xs whitespace-nowrap mt-[-5px]'>Yesterday</p>
            <div className='text-xs font-medium text-white h-[28px] min-w-[28px] rounded-full mt-4 bg-transparent grid place-items-center'>
              <span className='text-inherit'></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chats;

import { createTheme } from '@mui/material';

const THEME_MUI = createTheme({
  palette: {
    primary: {
      main: '#008080',
    },
    common: {
      white: '#FFFFFF',
      black: '#0A0A0A',
    },
  },
});

export default THEME_MUI;

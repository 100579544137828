import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import Button from 'components/Button/Button';
import ImagePicker from 'components/ImagePicker/ImagePicker';
import TextField from 'components/TextField/TextField';
import ASSETS from 'assets';
import QuilEditor from 'components/QuillEditor/QuilEditor';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

const schema = object({
  name: string().required('Name is required'),
  location: string().required('Location is required'),
  description: string().required('Description is required'),
});

const CompanyProfile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [image, setImage] = useState<File | string | null>(null);
  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.companies);

  const formik = useFormik({
    initialValues: {
      name: '',
      location: '',
      description: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      const formData = new FormData();
      if (typeof image !== 'string' && image) {
        formData.append('logo', image);
        dispatch.companies.handleUpdateCompany({
          values,
          navigate,
          formData,
          id: user?.company[0].id,
        });
      } else {
        dispatch.companies.handleUpdateCompany({
          values,
          navigate,
          id: user?.company[0].id,
        });
      }
    },
  });

  useEffect(() => {
    if (user?.company?.length) {
      const company = user.company[0];
      formik.setValues({
        name: company.name,
        location: company.location,
        description: company.description,
      });
      setImage(company.company_logo);
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <div className='flex-grow'>
      <div className='w-full max-w-[776px] mx-auto'>
        <div className='bg-white border-[0.5px] border-grey-600 rounded-[12px] flex flex-col items-center 2xl:px-11 px-8 pb-[36px] pt-[18px]'>
          <ImagePicker image={image} setImage={setImage} />
          <form
            action=''
            className='flex flex-col gap-[21px] w-full 2xl:mt-12 mt-6'
          >
            <TextField
              label='Company Name*'
              containerClassName='2xl:!h-[70px]'
              inputClassName='2xl:!text-lg'
              placeholder='Name'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.name && formik.errors.name
                  ? formik.errors.name
                  : ''
              }
            />
            <TextField
              label='Location*'
              containerClassName='2xl:!h-[70px]'
              inputClassName='2xl:!text-lg'
              placeholder='Location'
              icon={ASSETS.location}
              name='location'
              value={formik.values.location}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.location && formik.errors.location
                  ? formik.errors.location
                  : ''
              }
            />
          </form>
        </div>
        <div className='bg-white border-[0.5px] border-grey-600 rounded-[12px] flex flex-col items-center 2xl:px-11 px-8 pb-[25px] pt-[37px] mt-3 w-full'>
          <div className='w-full'>
            <QuilEditor
              value={formik.values.description}
              onChange={val => formik.setFieldValue('description', val)}
              error={
                formik.touched.description && formik.errors.description
                  ? formik.errors.description
                  : ''
              }
            />
          </div>

          <Button
            label='Next'
            className='2xl:!text-22 !text-lg w-full mt-7'
            onClick={() => formik.handleSubmit()}
            loading={loading}
          />
        </div>
        <div className='h-10'></div>
      </div>
    </div>
  );
};

export default CompanyProfile;

import React, { FC } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store/index';

import ASSETS from 'assets';
import { CSS } from '@dnd-kit/utilities';
import classNames from 'classnames';
import { IVideoQuestion } from 'types/interfaces';

interface IProps {
  index: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  question: IVideoQuestion;
}

const Item: FC<IProps> = ({ index, question, setOpen }) => {
  const dispatch = useDispatch<Dispatch>();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: question.id });

  const handleClick = () => {
    dispatch.video.setSelected(question);
    setOpen(true);
  };

  const handleRemoveVideo = () => {
    dispatch.video.setMedia({ id: question.id, value: null });
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div
      className='group flex items-center gap-2 cursor-pointer'
      ref={setNodeRef}
      style={style}
    >
      <img
        src={ASSETS.draghandle}
        className='drag-handle hidden group-hover:inline-block'
        alt=''
        {...listeners}
        {...attributes}
      />
      <p className='text-base font-medium min-w-[1.7ch] group-hover:hidden'>
        {index + 1}.
      </p>
      <div
        className={classNames(
          'border bg-white border-black rounded-lg  flex-grow 2xl:h-[77px] h-[55px] flex gap-6 relative',
          isDragging && 'border-[3px] shadow-mm'
        )}
        onClick={handleClick}
      >
        <div className='h-full aspect-square bg-grey-500 rounded-l-[7px] grid place-items-center cursor-pointer overflow-hidden'>
          {question.media ? (
            <video
              src={URL.createObjectURL(question.media)}
              className='w-full h-full object-cover'
              onLoadedMetadata={e => {
                const height = e.currentTarget.videoHeight;
                const width = e.currentTarget.videoWidth;
                if (height > width) {
                  dispatch.video.setOrientation({
                    id: question.id,
                    value: 'portrait',
                  });
                } else {
                  dispatch.video.setOrientation({
                    id: question.id,
                    value: 'landscape',
                  });
                }
              }}
            ></video>
          ) : (
            <img src={ASSETS.plusIcon} className='w-5 2xl:w-auto' alt='' />
          )}
        </div>
        <input
          type='text'
          name=''
          value={question.question}
          className='outline-none flex-grow h-full rounded-lg 2xl:text-lg text-base font-medium cursor-pointer'
          id=''
          readOnly
        />
        {question.media && (
          <img
            className='absolute right-[-5px] top-[-7px] cursor-pointer'
            src={ASSETS.closeSmall}
            alt=''
            onClick={handleRemoveVideo}
          />
        )}
      </div>
    </div>
  );
};

export default Item;

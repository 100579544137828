import { FC } from 'react';

import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import NotFound from './NotFound';
import ASSETS from 'assets';
import { ClipLoader } from 'react-spinners';
import theme from 'theme';

const Peoples: FC = () => {
  const navigate = useNavigate();
  const { data, loading } = useSelector((state: RootState) => state.search);

  const handleNavigate = (id: string, role: string) => {
    navigate(`/other-job-seeker/${id}`);
  };

  return (
    <>
      {loading ? (
        <div className='flex justify-center py-10'>
          <ClipLoader size={70} color={theme.colors.primary} />
        </div>
      ) : (
        <>
          {data?.people.length === 0 ? (
            <NotFound />
          ) : (
            <Container className='w-full rounded-lg'>
              <div className='flex flex-col 2xl:gap-4 gap-3 w-full  max-w-[821px] mx-auto'>
                {data?.people?.map(p => (
                  <div
                    key={p.id}
                    className='flex items-center rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white cursor-pointer'
                    onClick={() => handleNavigate(p.id, p.role as string)}
                  >
                    <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                      <img
                        src={p.image_url || ASSETS.preview}
                        alt=''
                        className='w-full h-full object-cover'
                      />
                    </div>
                    <div>
                      <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                        {p.first_name} {p.last_name}
                      </p>
                      <p className='2xl:text-lg text-base font-medium leading-6'>
                        {p.job_title}
                      </p>
                      {/* <p className='2xl:text-base text-sm'>5 hours ago</p> */}
                    </div>
                  </div>
                ))}
              </div>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default Peoples;

const Container = styled.div`
  /* max-width: 821px; */
  width: 100%;
  margin-top: 22px;
  overflow: hidden;
  margin-inline: auto;
`;

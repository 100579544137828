import ASSETS from 'assets';
import classNames from 'classnames';
import { FC, InputHTMLAttributes, useState } from 'react';

import styled from 'styled-components';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  className?: string;
  label?: string;
  inputClassName?: string;
  containerClassName?: string;
  icon?: string;
}

const TextField: FC<IProps> = props => {
  const {
    type = 'text',
    error,
    className,
    label,
    inputClassName,
    containerClassName,
    icon,
    ...rest
  } = props;
  const [inputType, setInputType] = useState(type);

  const toggleType = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <Container className={className}>
      {label && <p className='2xl:text-lg text-base font-medium'>{label}</p>}
      <InputContainer
        className={classNames(
          'bg-white 2xl:h-12 h-11 2xl:px-5 px-4',
          containerClassName
        )}
      >
        {icon && <img src={icon} className='h-5 2xl:h-auto' alt='' />}
        <input
          type={inputType}
          {...rest}
          className={classNames(
            'text-base h-full bg-red-500 w-full outline-none border-none',
            error && 'error',
            inputClassName
          )}
        />
        {type === 'password' && (
          <div
            onClick={toggleType}
            className='absolute top-[50%] translate-y-[-50%] right-4'
          >
            <img
              src={inputType === 'password' ? ASSETS.showSlash : ASSETS.show}
              alt=''
            />
          </div>
        )}
      </InputContainer>
      {error && (
        <div className='flex items-center gap-1 mt-1'>
          <img src={ASSETS.errorInfo} alt='' />
          <p className='text-xs text-error'>{error}</p>
        </div>
      )}
    </Container>
  );
};

export default TextField;

const Container = styled.div`
  width: 100%;
  max-width: 800px;
`;

const InputContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors['grey-400']};
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  &:has(input:focus) {
    border-color: ${({ theme }) => theme.colors.secondary};
  }

  &:has(input.error) {
    border-color: ${({ theme }) => theme.colors.error};
  }

  & input {
    background-color: transparent;

    &::placeholder {
      color: ${({ theme }) => theme.colors.placeholder};
    }
  }
`;

import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import dayjs from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';

import ASSETS from 'assets';

dayjs.extend(customParseFormat);

const EducationAndEmployment = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.auth);

  const handleClick = () => {
    navigate('/make-profile/step-4');
  };
  return (
    <div className='bg-white border border-grey-600 rounded-[12px] 2xl:p-10 p-5 mt-5'>
      <div className='flex items-center justify-center gap-3'>
        <p className='2xl:text-2xl text-xl font-medium'>
          Education & Employment
        </p>
        <img
          src={ASSETS.editPen}
          className='cursor-pointer'
          alt=''
          onClick={handleClick}
        />
      </div>
      <div className='flex flex-col 2xl:mt-12 mt-6 2xl:gap-3 gap-2'>
        {user?.userDetails?.company_name && (
          <div className='w-full flex items-center justify-between'>
            <p className='2xl:text-xl text-lg font-medium'>Current Employer</p>
            <p className='2xl:text-xl text-lg font-medium text-placeholder'>
              {user?.userDetails?.company_name}
            </p>
          </div>
        )}
        {user?.userDetails?.start_date && (
          <div className='w-full flex items-center justify-between'>
            <p className='2xl:text-xl text-lg font-medium'>Start Date</p>
            <p className='2xl:text-xl text-lg font-medium text-placeholder'>
              {dayjs(user?.userDetails?.start_date).format('MMM, DD YYYY')}
            </p>
          </div>
        )}
        {user?.userDetails?.end_date && (
          <div className='w-full flex items-center justify-between'>
            <p className='2xl:text-xl text-lg font-medium'>End Date</p>
            <p className='2xl:text-xl text-lg font-medium text-placeholder'>
              {dayjs(user?.userDetails?.end_date).format('MMM, DD YYYY')}
            </p>
          </div>
        )}
        {user?.userDetails?.previous_company && (
          <div className='w-full flex items-center justify-between'>
            <p className='2xl:text-xl text-lg font-medium'>Previous Employer</p>
            <p className='2xl:text-xl text-lg font-medium text-placeholder'>
              {user?.userDetails?.previous_company}
            </p>
          </div>
        )}
        {user?.userDetails?.years_of_exp && (
          <div className='w-full flex items-center justify-between'>
            <p className='2xl:text-xl text-lg font-medium'>
              Years of Experience
            </p>
            <p className='2xl:text-xl text-lg font-medium text-placeholder'>
              {user?.userDetails?.years_of_exp} {/* @ts-ignore */}
              {user?.userDetails?.years_of_exp > 1 ? 'years' : 'year'}
            </p>
          </div>
        )}
        {user?.userDetails?.degree && (
          <div className='w-full flex items-center justify-between'>
            <p className='2xl:text-xl text-lg font-medium'>Degree</p>
            <p className='2xl:text-xl text-lg font-medium text-placeholder'>
              {user?.userDetails?.degree}
            </p>
          </div>
        )}
        {user?.userDetails?.graduation_year && (
          <div className='w-full flex items-center justify-between'>
            <p className='2xl:text-xl text-lg font-medium'>Graduation Year</p>
            <p className='2xl:text-xl text-lg font-medium text-placeholder'>
              {user?.userDetails?.graduation_year}
            </p>
          </div>
        )}
        {user?.userDetails?.school && (
          <div className='w-full flex items-center justify-between'>
            <p className='2xl:text-xl text-lg font-medium'>School </p>
            <p className='2xl:text-xl text-lg font-medium text-placeholder'>
              {user?.userDetails?.school}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EducationAndEmployment;

import React from 'react';

const HelpCenterHotline = () => {
  return (
    <div className='2xl:p-9 p-5 flex flex-col gap-[10px]'>
      <div className='border-b-[0.5px] border-b-grey-600 2xl:py-[11px] py-2'>
        <p className='2xl:text-lg text-base font-medium'>
          Employer Assistance and Resource Network (EARN)
        </p>
        <p className='text-sm'>
          1-855-275-3276 | <span className='text-blue'>askearn.org</span> |{' '}
          <span className='text-blue'>www.askearn.org</span>
        </p>
      </div>

      <div className='border-b-[0.5px] border-b-grey-600 2xl:py-[11px] py-2'>
        <p className='2xl:text-lg text-base font-medium'>
          Occupational Safety and Health Administration
        </p>
        <p className='text-sm'>
          1-800-321-6742 | <span className='text-blue'>www.osha.gov</span>
        </p>
      </div>
      <div className='border-b-[0.5px] border-b-grey-600 2xl:py-[11px] py-2'>
        <p className='2xl:text-lg text-base font-medium'>
          Department of Labor (DOL) Employer Assistance
        </p>
        <p className='text-sm'>
          1-866-4-USA-DOL (1-866-487-2365) |{' '}
          <span className='text-blue'>askearn.org</span>
        </p>
      </div>
      <div className='border-b-[0.5px] border-b-grey-600 2xl:py-[11px] py-2'>
        <p className='2xl:text-lg text-base font-medium'>
          Small Business Administration (SBA) Hotline
        </p>
        <p className='text-sm'>
          1-800-827-5722 | <span className='text-blue'>www.sba.gov</span>
        </p>
      </div>
    </div>
  );
};

export default HelpCenterHotline;

import React, { useId, useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import ClickAwayListener from 'react-click-away-listener';
import dayjs from 'dayjs';

import ASSETS from 'assets';
import Button from 'components/Button/Button';

const UploadDocuments = () => {
  const resumePicker = useId();
  const coverLetterPicker = useId();

  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.user);

  const [resume, setResume] = useState<File | null>(null);
  const [resumeMenu, setResumeMenu] = useState(false);
  const [coverLetter, setCoverLetter] = useState<File | null>(null);
  const [coverLetterMenu, setCoverLetterMenu] = useState(false);

  const toggleResumeMenu = () => setResumeMenu(!resumeMenu);
  const closeResumeMenu = () => setResumeMenu(false);
  const toggleCoverLetterMenu = () => setCoverLetterMenu(!coverLetterMenu);
  const closeCoverLetterMenu = () => setCoverLetterMenu(false);

  const handleNext = () => {
    const files = new FormData();
    if (resume) {
      files.append('resume', resume);
    }
    if (coverLetter) {
      files.append('cover', coverLetter);
    }
    dispatch.user.handleUploadFiles({ files, navigate });
  };

  const handleResume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setResume(file);
      e.currentTarget.value = '';
    }
  };

  const handleCoverLetter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setCoverLetter(file);
      e.currentTarget.value = '';
    }
  };

  const handleDeleteResume = () => {
    setResume(null);
    closeResumeMenu();
  };

  const handleDeleteCoverLetter = () => {
    setCoverLetter(null);
    closeCoverLetterMenu();
  };

  return (
    <Container>
      <div className='bg-white border-[0.5px] border-grey-400 2xl:p-9 p-5 flex flex-col items-center  rounded-[12px]'>
        <div className='2xl:size-[180px] size-[130px]  rounded-full overflow-hidden border-[2px] border-black'>
          <img
            src={user?.image_url || ASSETS.userImg}
            className='w-full h-full object-cover'
            alt=''
          />
        </div>
        {resume ? (
          <div className='2xl:my-[100px] my-[25px]  flex items-center justify-between w-full '>
            <div>
              <p className='2xl:text-xl text-lg font-medium'>{resume?.name}</p>
              <p className='text-sm text-placeholder 2xl:mt-3 mt-1'>
                Uploaded on {dayjs().format('MMM DD, YYYY')}
              </p>
            </div>
            <div className=' relative'>
              <div
                className='wrapper cursor-pointer'
                onClick={toggleResumeMenu}
              >
                <img src={ASSETS.menu} alt='' />
              </div>
              {resumeMenu && (
                <ClickAwayListener onClickAway={closeResumeMenu}>
                  <div
                    className='absolute rounded right-0 cursor-pointer bg-white w-[171px] flex items-center gap-2 px-6 h-11 shadow-popup'
                    onClick={handleDeleteResume}
                  >
                    <img src={ASSETS.delete} alt='' />
                    <p className='text-sm font-medium'>Delete </p>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        ) : (
          <div className='flex flex-col items-center gap-2 2xl:mt-14 mt-6'>
            <img src={ASSETS.doc} className='w-10 2xl:w-auto' alt='' />
            <p className='2xl:text-2xl text-lg text-primary'>
              No resume uploaded yet
            </p>
          </div>
        )}

        <label
          htmlFor={resumePicker}
          className='w-full max-w-[344px] mx-auto 2xl:mt-7 mt-3 cursor-pointer'
        >
          <Button
            label='Upload Resume'
            className='2xl:!text-22 !text-lg 2xl:!h-14 !w-full !pointer-events-none '
          />
          <input
            type='file'
            name=''
            id={resumePicker}
            className='hidden'
            accept='.doc,.docx,.pdf'
            onChange={handleResume}
          />
        </label>
        <p className='2xl:text-xl text-sm font-medium mt-4 2xl:mb-8 mb-0'>
          DOC, DOCX, PDF (5MB)
        </p>
      </div>
      <div className='bg-white border-[0.5px] border-grey-400 p-9  flex-col items-center  rounded-[12px] mt-3 !hidden'>
        {coverLetter ? (
          <div className='mt-[30px] flex items-center justify-between w-full'>
            <div>
              <p className='2xl:text-xl text-lg font-medium'>
                {coverLetter?.name}
              </p>
              <p className='text-sm text-placeholder 2xl:mt-3 mt-1'>
                Uploaded on {dayjs().format('MMM DD, YYYY')}
              </p>
            </div>
            <div className='relative'>
              <div
                className='wrapper cursor-pointer'
                onClick={toggleCoverLetterMenu}
              >
                <img src={ASSETS.menu} alt='' />
              </div>
              {coverLetterMenu && (
                <ClickAwayListener onClickAway={closeCoverLetterMenu}>
                  <div
                    className='absolute rounded right-0 cursor-pointer bg-white w-[171px] flex items-center gap-2 px-6 h-11 shadow-popup'
                    onClick={handleDeleteCoverLetter}
                  >
                    <img src={ASSETS.delete} alt='' />
                    <p className='text-sm font-medium'>Delete </p>
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
        ) : (
          <p className='2xl:text-2xl text-lg font-medium'>
            Cover Letter / attachment{' '}
            <span className='text-placeholder italic'>(Optional)</span>
          </p>
        )}

        <label
          htmlFor={coverLetterPicker}
          className='w-full max-w-[344px] mx-auto 2xl:mt-7 mt-3 cursor-pointer'
        >
          <Button
            label='Upload Document'
            className='2xl:!text-22 !text-lg 2xl:!h-14 !w-full !pointer-events-none '
          />
          <input
            type='file'
            name=''
            id={coverLetterPicker}
            className='hidden'
            accept='.doc,.docx,.pdf'
            onChange={handleCoverLetter}
          />
        </label>
        <p className='2xl:text-xl text-sm font-medium mt-4'>
          DOC, DOCX, PDF (5MB)
        </p>
      </div>
      <div className='mt-12 px-12'>
        <Button
          label='Next'
          className='!w-full 2xl:!h-[56px] 2xl:!text-22 !text-lg'
          onClick={handleNext}
          disabled={!resume}
          loading={loading}
        />
      </div>
    </Container>
  );
};

export default UploadDocuments;

const Container = styled.div`
  width: 100%;
  max-width: 776px;
  margin-inline: auto;
  padding-bottom: 50px;
`;

import React from 'react';

import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import AutocompleteSelect from 'components/AutocompleteSelect/AutocompleteSelect';
import Button from 'components/Button/Button';
import { processCities } from 'app.config';

const country = processCities();

const experiencrOptions = [
  'Internship',
  'Entry Level ',
  'Associate ',
  'Mid-Senior Level ',
  'Director',
  'Executive',
];

const industryOptions = [
  'Architecture and Construction',
  'Arts, Design, Entertainment, Sports...',
  'Building and Grounds Cleaning and M...',
  'Business and Finance',
  'Community and Social Services',
  'Science, Technology, Engineering, ....',
  'Construction and Extraction',
  'Education, Training, and Library',
  'Farming, Fishing and Forrestry',
  'Food Preparation and Serving Related',
  'Education, Training, and Library',
  'Healthcare Practitioners and Technical',
  'Healthcare Support',
  'Installation, Maintenance and Repair',
  'Legal',
  'Life, Physical and Social Science',
  'Business Management and Administration',
  'Military',
  'Office and Administrative Support',
  'Government and Public Administration',
  'Transportation. Distribution and Logistics',
  'Personal Care and Services',
  'Protective and Security Service',
  'Marketing, Sales, and Service',
];

const SearchPreferences = () => {
  const { role } = useSelector((state: RootState) => state.auth);

  const formik = useFormik({
    initialValues: {
      location: [],
      jobRole: [],
      jobLevel: [],
      jobType: [],
    } as { [x: string]: any },
    onSubmit: values => {
      console.log(values);
    },
  });

  const handleChange = (val: string, name: string) => {
    const values = formik.values[name];
    if (values.includes(val)) {
      formik.setFieldValue(
        name,
        values.filter((v: string) => v !== val)
      );
    } else {
      formik.setFieldValue(name, [...values, val]);
    }
  };

  const handleClear = () => {
    formik.setValues({
      location: [],
      jobRole: [],
      jobLevel: [],
      jobType: [],
    });
  };

  return (
    <div className='flex-grow'>
      <div className='w-full max-w-[821px] bg-white 2xl:p-7 p-5 border-[0.5px] border-grey-600'>
        <div className='flex items-center justify-between'>
          <p className='2xl:text-32 text-2xl text-primary font-medium'>
            Search Preference
          </p>
          <p className='2xl:text-sm text-xs font-medium'>
            Filter Search (
            {formik.values.location?.length +
              formik.values.jobRole?.length +
              formik.values.jobLevel?.length +
              formik.values.jobType?.length}
            )
          </p>
        </div>
        <div className='flex flex-col 2xl:gap-6 gap-4 2xl:mt-[55px] mt-5'>
          <AutocompleteSelect
            selected={formik.values.location}
            options={country}
            placeholder='Search'
            lable='Location'
            onSelect={val => handleChange(val, 'location')}
            onDeselect={val => handleChange(val, 'location')}
          />
          <>
            {role === 'employer' ? (
              <>
                <AutocompleteSelect
                  options={experiencrOptions}
                  placeholder='Select'
                  lable='Experience'
                  searchIcon={false}
                  selected={formik.values.jobRole}
                  onSelect={val => handleChange(val, 'jobRole')}
                  onDeselect={val => handleChange(val, 'jobRole')}
                />
                <AutocompleteSelect
                  options={industryOptions}
                  placeholder='Select'
                  lable='Industry'
                  searchIcon={false}
                  selected={formik.values.jobLevel}
                  onSelect={val => handleChange(val, 'jobLevel')}
                  onDeselect={val => handleChange(val, 'jobLevel')}
                />
              </>
            ) : (
              <>
                <AutocompleteSelect
                  options={['Hourly', 'Contract', 'Full time', 'Part time']}
                  placeholder='Select'
                  lable='Job Role'
                  searchIcon={false}
                  selected={formik.values.jobRole}
                  onSelect={val => handleChange(val, 'jobRole')}
                  onDeselect={val => handleChange(val, 'jobRole')}
                />
                <AutocompleteSelect
                  options={['Associate', 'Internship']}
                  placeholder='Select'
                  lable='Job Level '
                  searchIcon={false}
                  selected={formik.values.jobLevel}
                  onSelect={val => handleChange(val, 'jobLevel')}
                  onDeselect={val => handleChange(val, 'jobLevel')}
                />
              </>
            )}
          </>

          <AutocompleteSelect
            options={['Remote', 'Onsite', 'Hybrid']}
            placeholder='Select'
            lable='Job Type '
            searchIcon={false}
            selected={formik.values.jobType}
            onSelect={val => handleChange(val, 'jobType')}
            onDeselect={val => handleChange(val, 'jobType')}
          />
        </div>
        <div className='flex flex-col items-center 2xl:mt-[81px] mt-7'>
          <Button
            label='Apply'
            className='w-full max-w-[369px] 2xl:!text-22 !text-lg'
          />
          <p
            className='2xl:text-2xl text-lg font-medium mt-4 cursor-pointer'
            onClick={handleClear}
          >
            Clear
          </p>
        </div>
      </div>
    </div>
  );
};

export default SearchPreferences;

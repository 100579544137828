import React from 'react';

const Safety = () => {
  return (
    <div className='2xl:px-6 px-4 2xl:py-9 py-5'>
      <p className='2xl:text-2xl text-xl font-bold'>Saftey & Privacy</p>
      <p className='2xl:mt-8 mt-4 text-lg font-medium'>Final Round Safety </p>
      <p className='2xl:text-base text-sm mt-3'>
        At Final Round, we prioritize the safety and security of our users. Our
        safety policy is designed to create a secure and trustworthy environment
        for your job-seeking journey. We employ robust measures to protect your
        personal information, utilizing encryption and secure storage practices.
        Rest assured that your data is accessible only to authorized personnel
        involved in the recruitment process. We adhere strictly to industry best
        practices, and your information will never be shared, sold, or rented to
        third parties without your explicit consent. Our commitment to your
        safety extends to the implementation of advanced security measures,
        continuously updated to prevent unauthorized access and protect against
        potential cyber threats.
      </p>
      <p className='2xl:mt-8 mt-4 text-lg font-medium'>
        Final Round Privacy Policy
      </p>
      <p className='2xl:text-base text-sm mt-3'>
        Respecting your privacy is fundamental for Final Round. Our privacy
        policy ensures the confidentiality of your personal information
        throughout your job-seeking journey. We collect only essential data
        required for the job search process, such as your resume, contact
        details, and professional preferences. This information is treated with
        the utmost confidentiality and is encrypted for secure storage. We
        strictly comply with applicable data protection laws, and your data will
        never be shared, sold, or rented to third parties without your explicit
        consent. Our commitment to privacy extends beyond compliance, as we
        continually update our privacy protocols to adapt to evolving standards.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
        occaecat cupidatat non proident, sunt in culpa qui officia deserunt
        mollit anim id est laborum. Lorem ipsum dolor sit amet, consectetur
        adipiscing elit. Proin accumsan nisl a ligula lobortis, id fringilla
        ante aliquam. Donec at ipsum sed mi aliquet elementum. Sed sit amet
        dolor et odio sagittis faucibus. Fusce malesuada urna eget augue
        ultricies, eget fermentum nunc malesuada. Duis venenatis ante id tellus
        vehicula, ac consectetur magna aliquet. Suspendisse vitae nisi quis
        felis luctus efficitur. Vivamus varius velit in augue tempor, ut
        tristique eros tincidunt. Sed vestibulum consectetur nisi, nec malesuada
        quam malesuada sit amet. Sed tristique rutrum nisl, eget aliquet enim
        suscipit ac. Suspendisse potenti. Integer rutrum aliquet ultrices. Nunc
        id nunc at justo sodales fringilla. Cras ac eros sit amet eros rutrum
        rhoncus. Sed vitae magna ut odio sodales tincidunt.
      </p>
    </div>
  );
};

export default Safety;

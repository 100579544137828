import ASSETS from 'assets';
import React, { FC } from 'react';

const CONFIG = [
  {
    name: 'Winsel Viene',
    job: 'Graphic Designer',
    time: '5 hours ago',
    img: ASSETS.user1,
  },
  {
    name: 'John Doe ',
    job: 'UX UI Designer',
    time: '2 days ago',
    img: ASSETS.video,
  },
  {
    name: 'David Lin',
    job: 'UX UI Expert',
    time: '3 days ago',
    img: ASSETS.other,
  },
  {
    name: 'Jacob Carter',
    job: 'Senior UX UI Designer',
    time: '3 days ago',
    img: ASSETS.user2,
  },
];

interface IProps {
  setSelected: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReceivedJobApp: FC<IProps> = ({ setSelected }) => {
  const handleSelect = () => setSelected(true);

  return (
    <div className='h-full flex flex-col'>
      <div className='2xl:pt-[33px] pt-5'>
        <div className='flex items-center 2xl:gap-[29px] gap-4 rounded-lg shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'>
          <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
            <img src={ASSETS.job} alt='' />
          </div>
          <div>
            <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
              Wardiere Software
            </p>
            <p className='2xl:text-lg text-base font-medium leading-6'>
              UX UI Designer
            </p>
            <p className='2xl:text-base text-sm'>Received Applications: (4)</p>
          </div>
        </div>
        <div className='mt-[32px] flex flex-col gap-2'>
          {CONFIG.map(user => (
            <div
              className='flex items-center rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white hover:bg-[#90BDEA30] cursor-pointer'
              onClick={handleSelect}
            >
              <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                <img src={user.img} alt='' />
              </div>
              <div>
                <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                  {user.name}
                </p>
                <p className='2xl:text-lg text-base font-medium leading-6'>
                  {user.job}
                </p>
                <p className='2xl:text-base text-sm'>{user.time}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReceivedJobApp;

import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useNavigate } from 'react-router-dom';

import Select from 'components/Select/Select';
import VideoIntro from './components/VideoIntro';
import EducationAndEmployment from './components/EducationAndEmployment';
import JobApplications from './components/JobApplications';
import ApplcantProfile from './components/ApplcantProfile';
import EmployerProfile from './components/EmployerProfile';
import CompanyDescription from './components/CompanyDescription';
import OpenJobs from './components/OpenJobs';
import { ROLES } from 'types/types';
import { updateRole } from 'http/userService';

const options = [
  { label: 'Applicant', value: 'A' },
  { label: 'Recruiting Agent for Applicant', value: 'RA' },
  { label: 'Staffing Agent for Applicant', value: 'SA' },
  { label: 'Employer', value: 'E' },
  { label: 'Recruiting Agent for Employer', value: 'RE' },
  { label: 'Staffing Agent for Employer', value: 'SE' },
];

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [profile, setProfile] = useState('applicant');

  const { user } = useSelector((state: RootState) => state.auth);

  const [selectedRole, setSelectedRole] = useState(user?.role || 'A');

  const handleChangeProfile = async (value: ROLES) => {
    try {
      await updateRole(value);
      dispatch.auth.handleGetCurrentUser();
      navigate('/explore');
      setSelectedRole(value);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    const role = user?.role || 'A';
    if (role === 'A' || role === 'RA' || role === 'SA') {
      setProfile('applicant');
    } else {
      setProfile('employer');
    }
    setSelectedRole(role);
  }, [user]);

  useEffect(() => {
    if (
      selectedRole === 'A' ||
      selectedRole === 'RA' ||
      selectedRole === 'SA'
    ) {
      setProfile('applicant');
    } else {
      setProfile('employer');
    }
  }, [selectedRole]);

  return (
    <Container>
      {profile === 'applicant' ? <ApplcantProfile /> : <EmployerProfile />}
      <div className='flex justify-end py-4'>
        <div className='max-w-[340px] w-full'>
          <Select
            onChange={val => handleChangeProfile(val as ROLES)}
            selected={selectedRole}
            options={options}
          />
        </div>
      </div>
      {profile === 'applicant' ? (
        <>
          <VideoIntro />
          <EducationAndEmployment />
          <JobApplications />
        </>
      ) : (
        <>
          <CompanyDescription />
          <OpenJobs />
        </>
      )}
    </Container>
  );
};

export default Profile;

const Container = styled.div`
  width: 100%;
  max-width: 776px;
  margin-inline: auto;
  padding-bottom: 100px;
`;

import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  colors: {
    black: '#110C22',
    'grey-400': '#D8DADC',
    'grey-100': '#F6F6F6',
    primary: '#008080',
    secondary: '#FBBC05',
    placeholder: '#3E3E3E',
    error: '#B1281F',
    white: '#FFFFFF',
    placeholderColor: '#8D8A95',
    success: '#039855',
    warning: '#CF9E10',
  },
  breakpoints: {
    '3xl': '1920px',
    '2xl': '1536px',
    xl: '1280px',
    lg: '1024px',
    md: '768px',
    sm: '576px',
    xs: '320px',
  },
};

export default theme;

import Button from 'components/Button/Button';
import CompanySelector from './CompanySelector';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useEffect, useState } from 'react';

const SelectCompany = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const dispatch = useDispatch<Dispatch>();

  const { selected } = useSelector((state: RootState) => state.companies);

  const handleClick = () => {
    dispatch.companies.setSelected(null);
    navigate('/create-company');
  };

  const handleNext = () => {
    if (selected) {
      navigate('/create-company');
    } else {
      setError('Company name is required');
    }
  };

  useEffect(() => {
    if (selected) {
      setError('');
    }
  }, [selected]);

  return (
    <div className='flex-grow'>
      <div className='w-full max-w-[776px] mx-auto'>
        <div className='border-[0.5px] border-grey-600 rounded-[12px] bg-white 2xl:px-11 2xl:pt-[70px] 2xl:pb-[60px] px-7 pt-10 pb-12'>
          <CompanySelector error={error} />
          <Button
            label='Next'
            className='2xl:!text-22 !text-lg w-full 2xl:mt-16 mt-10'
            onClick={handleNext}
          />
        </div>
        <div className='h-[1px] bg-grey-600 flex items-center justify-center 2xl:mt-[64px] 2xl:mb-[72px] my-12'>
          <span className='2xl:text-2xl text-xl font-medium bg-grey-100 px-3'>
            Or
          </span>
        </div>
        <Button
          label='Add Company'
          variant='outlined'
          className='2xl:!text-22 !text-lg 2xl:mt-16 mt-8 !w-[calc(100%_-_88px)] mx-auto'
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default SelectCompany;

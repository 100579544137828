import React, { useState } from 'react';

import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';
import { Tooltip } from 'react-tooltip';

import Checkbox from 'components/Checkbox/Checkbox';

import ASSETS from 'assets';

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [active, setActive] = useState(false);
  const [warn, setWarn] = useState(false);

  const handleChange = (checked: boolean) => {
    setWarn(false);
    setActive(checked);
  };

  const handleSignUpWithEmail = () => {
    if (active) {
      navigate('/sign-up/create-account');
      dispatch.auth.setAuthType('E');
    } else {
      setWarn(true);
    }
  };

  const handleSignUpWithPhone = () => {
    if (active) {
      navigate('/sign-up');
      dispatch.auth.setAuthType('P');
    } else {
      setWarn(true);
    }
  };

  return (
    <Container
      className='h-full no-pad w-full bg-no-repeat bg-cover bg-center flex justify-end items-center p-12'
      style={{ backgroundImage: `url(${ASSETS.loginBg})` }}
    >
      <div className='w-full max-w-[484px] bg-grey-100 px-6 2xl:pb-12 pb-8 2xl:pt-[116px] pt-[65px] rounded-[16px] shadow-popup'>
        <div className='flex flex-col gap-[17px]'>
          <button
            onClick={handleSignUpWithEmail}
            className='w-full bg-white shadow-mm rounded-3xl h-11 flex items-center justify-center gap-3'
          >
            <img src={ASSETS.email} alt='' />
            <p className='text-base font-medium'>Sign Up with Email</p>
          </button>

          <button className='w-full bg-red shadow-mm rounded-3xl h-11 flex items-center justify-center gap-3'>
            <img src={ASSETS.googleLogo} alt='' />
            <p className='text-base font-medium text-white'>
              Sign Up with Google
            </p>
          </button>
          <button className='w-full bg-blue shadow-mm rounded-3xl h-11 flex items-center justify-center gap-3'>
            <img src={ASSETS.facebook} alt='' />
            <p className='text-base font-medium text-white'>
              Sign Up with Facebook
            </p>
          </button>
        </div>
        <p className='text-base text-center mt-[14px] '>
          Or continue with your{' '}
          <span className='font-bold underline'>
            <span className='cursor-pointer' onClick={handleSignUpWithPhone}>
              Phone Number
            </span>
          </span>
        </p>
        <p className='text-base text-center font-medium 2xl:mt-12 mt-6 2xl:mb-[59px] mb-8'>
          Already have an account?{' '}
          <span className='font-medium underline'>
            <Link to='/sign-in' className='text-blue'>
              Sign In
            </Link>
          </span>
        </p>
        <div className='flex items-start gap-[6px]'>
          <div className='pt-1' id='tooltip-auth'>
            <Checkbox
              className='[&_img]:w-[32px] [&_img]:h-32px'
              onChange={handleChange}
            />
          </div>
          <Tooltip
            className='max-w-[360px] !bg-white shadow-xl !text-black break-words !opacity-100 !z-50 font-bold'
            anchorSelect={`#tooltip-auth`}
            content={'Please acknowledge the terms and conditions'}
            isOpen={warn}
            place='top-start'
          ></Tooltip>
          <p className='text-base font-medium'>
            By signing up, you agree to our{' '}
            <span className='underline cursor-pointer'>Terms Of Use</span>,{' '}
            <span className='underline cursor-pointer'>Cookies Policy</span> and{' '}
            <span className='underline cursor-pointer'>Privacy Policy</span>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Main;

const Container = styled.div``;

import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';
import { useFormik } from 'formik';
import { object, string, number } from 'yup';

import TextField from 'components/TextField/TextField';
import Button from 'components/Button/Button';

import ASSETS from 'assets';
import DatePicker from 'components/DatePicker/DatePicker';

import dayjs from 'dayjs';
import { IUpdateApplicantDetails } from 'types/api';

const schema = object({
  company_name: string().optional().label('Current Employer'),
  start_date: string().optional().label('Start Date'),
  end_date: string().optional().label('End Date'),
  years_of_exp: number()
    .positive()
    .typeError('Years of Experience must be a proper year')
    .optional()
    .label('Years of Experience'),
  degree: string().optional().label('Degree'),
  school: string().optional().label('School'),
  graduation_year: number()
    .positive('Graduation Year must be a number')
    .test(
      'len',
      'Graduation Year must be exactly 4 digits',
      val => !val || val?.toString().length === 4
    )
    .optional()
    .label('Graduation Year'),
});

const Education = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { user } = useSelector((state: RootState) => state.auth);
  const { loading } = useSelector((state: RootState) => state.user);

  const formik = useFormik({
    initialValues: {
      company_name: '',
      start_date: '',
      end_date: '',
      years_of_exp: '',
      degree: '',
      school: '',
      graduation_year: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      const val: { [x: string]: any } = {};

      Object.keys(values).forEach(key => {
        // @ts-ignore
        if (values[key]) {
          // @ts-ignore
          val[key] = values[key];
        } else {
          val[key] = null;
        }
      });
      console.log(val);
      const data = {
        ...val,
        start_date: values.start_date
          ? dayjs(values.start_date).format('MM-DD-YYYY')
          : null,
        end_date: values.end_date
          ? dayjs(values.end_date).format('MM-DD-YYYY')
          : null,
      };
      dispatch.user.handleUpdateApplicantProfile({
        values: data as IUpdateApplicantDetails,
        navigate,
      });
    },
  });

  useEffect(() => {
    if (user?.userDetails) {
      formik.setValues({
        company_name: user.userDetails.company_name || '',
        start_date: user.userDetails.start_date || '',
        end_date: user.userDetails.end_date || '',
        years_of_exp: user.userDetails.years_of_exp || '',
        degree: user.userDetails.degree || '',
        school: user.userDetails.school || '',
        graduation_year: user.userDetails.graduation_year || '',
      });
    }
    // eslint-disable-next-line
  }, [user]);

  return (
    <Container>
      <div className='bg-white border-[0.5px] border-grey-400 rounded-[12px] 2xl:px-11 px-5 2xl:pt-6 2xl:pb-12 pt-5 pb-6'>
        <div className='flex justify-center'>
          <div className='2xl:size-[180px] size-[140px] rounded-full overflow-hidden border-[2px] border-black'>
            <img
              src={user?.image_url || ASSETS.userImg}
              className='w-full h-full object-cover'
              alt=''
            />
          </div>
        </div>
        <p className='2xl:text-2xl text-xl text-center mt-[25px]'>
          Education & Employement
        </p>
        <form
          onSubmit={e => e.preventDefault()}
          className='w-full flex flex-col gap-[15px] mt-5'
        >
          <TextField
            containerClassName='2xl:!h-[70px] !h-[48px]'
            inputClassName='2xl:!text-lg'
            placeholder='Current Employer'
            name='company_name'
            value={formik.values.company_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.company_name && formik.errors.company_name
                ? formik.errors.company_name
                : ''
            }
          />
          <div className='grid grid-cols-2 gap-[42px]'>
            <div className='col-span-1'>
              <DatePicker
                noLabel
                placeholder='Start Date'
                value={formik.values.start_date}
                onChange={val => formik.setFieldValue('start_date', val)}
                error={
                  formik.touched.start_date && formik.errors.start_date
                    ? formik.errors.start_date
                    : ''
                }
                containerClassName='2xl:!h-[70px] !h-[48px]'
              />
              {/* <TextField
                containerClassName='2xl:!h-[70px] !h-[48px]'
                inputClassName='2xl:!text-lg'
                placeholder='Start Date'
                type='date'
                name='start_date'
                value={formik.values.start_date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.start_date && formik.errors.start_date
                    ? formik.errors.start_date
                    : ''
                }
              /> */}
            </div>
            <div className='col-span-1'>
              <DatePicker
                noLabel
                placeholder='End Date'
                value={formik.values.end_date}
                onChange={val => formik.setFieldValue('end_date', val)}
                error={
                  formik.touched.end_date && formik.errors.end_date
                    ? formik.errors.end_date
                    : ''
                }
                containerClassName='2xl:!h-[70px] !h-[48px]'
              />
              {/* <TextField
                containerClassName='2xl:!h-[70px] !h-[48px]'
                inputClassName='2xl:!text-lg'
                placeholder='End Date'
                type='date'
                name='end_date'
                value={formik.values.end_date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.end_date && formik.errors.end_date
                    ? formik.errors.end_date
                    : ''
                }
              /> */}
            </div>
          </div>

          <TextField
            containerClassName='2xl:!h-[70px] !h-[48px]'
            inputClassName='2xl:!text-lg'
            placeholder='Years of Experience'
            name='years_of_exp'
            value={formik.values.years_of_exp}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.years_of_exp && formik.errors.years_of_exp
                ? formik.errors.years_of_exp
                : ''
            }
          />
          <TextField
            containerClassName='2xl:!h-[70px] !h-[48px]'
            inputClassName='2xl:!text-lg'
            placeholder='Degree'
            name='degree'
            value={formik.values.degree}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.degree && formik.errors.degree
                ? formik.errors.degree
                : ''
            }
          />
          <TextField
            containerClassName='2xl:!h-[70px] !h-[48px]'
            inputClassName='2xl:!text-lg'
            placeholder='School Name'
            name='school'
            value={formik.values.school}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.school && formik.errors.school
                ? formik.errors.school
                : ''
            }
          />
          <TextField
            containerClassName='2xl:!h-[70px] !h-[48px]'
            inputClassName='2xl:!text-lg'
            placeholder='Graduation Year'
            name='graduation_year'
            value={formik.values.graduation_year}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.graduation_year && formik.errors.graduation_year
                ? formik.errors.graduation_year
                : ''
            }
          />
          <Button
            label='Next'
            className='!w-full 2xl:!h-[56px] 2xl:!text-22 !text-lg 2xl:mt-12 mt-6'
            onClick={() => formik.handleSubmit()}
            type='submit'
            loading={loading}
          />
        </form>
      </div>
    </Container>
  );
};

export default Education;

const Container = styled.div`
  width: 100%;
  max-width: 776px;
  margin-inline: auto;
  padding-bottom: 50px;
`;

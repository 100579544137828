import React from 'react';
import Accordion from './Accordion';

const FAQs = () => {
  return (
    <div className='2xl:px-6 px-4 2xl:py-9 py-5'>
      <p className='2xl:text-2xl text-xl font-bold'>FAQs</p>
      <div className='flex flex-col 2xl:gap-5 gap-4  2xl:mt-[55px] mt-6'>
        <Accordion
          question='What is Final Round?'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
        <Accordion
          question='Is Final Round free to use?'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
        <Accordion
          question='How do I get started with Final Round?'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
        <Accordion
          question='How does Final Round benefit job seekers?'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
        <Accordion
          question='How can recruiters use Final Round?'
          answer='Final Round offers both free and premium subscription options, allowing users to choose the level of service that best meets their needs.'
        />
      </div>
    </div>
  );
};

export default FAQs;

import React, { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Accordion from './Accordion';
import { jobLevels } from 'app.config';
import ASSETS from 'assets';

const JobLevel = () => {
  const dispatch = useDispatch<Dispatch>();

  const { jobLevel: selected } = useSelector(
    (state: RootState) => state.filters
  );
  const handleSelect = (op: string) => {
    dispatch.filters.setListFilters({ name: 'jobLevel', value: op });
  };

  return (
    <Accordion label='Job Level'>
      <div className='px-2 pb-1'>
        <div className='flex flex-col rounded overflow-hidden'>
          {jobLevels.map(j => (
            <div
              className='flex items-center gap-1 cursor-pointer py-1 border-b-[0.5px] flex-grow overflow-auto px-1 border-grey-400 bg-white [&:last-of-type]:border-none'
              onClick={() => handleSelect(j)}
            >
              <img
                src={
                  selected.includes(j) ? ASSETS.checkboxCheck : ASSETS.checkbox
                }
                className='w-4'
                alt=''
              />
              <p className='text-sm'>{j}</p>
            </div>
          ))}
        </div>
      </div>
    </Accordion>
  );
};

export default JobLevel;

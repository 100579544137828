import { createModel } from '@rematch/core';
import { RootModel } from '.';
import { ICreateCompanyPayload, IUpdateCompany } from 'types/modals';
import {
  createCompany,
  getAllCompanies,
  updateCompanyDetails,
  updateCompanyImage,
} from 'http/companyService';
import { ICompany } from 'types/interfaces';

interface IState {
  loading: boolean;
  companies: Array<ICompany>;
  selected: ICompany | null;
}

export const companies = createModel<RootModel>()({
  name: 'companies',
  state: {
    loading: false,
    selected: null,
    companies: [],
  } as IState,
  reducers: {
    setLoading(state, payload: boolean) {
      state.loading = payload;
    },
    setSelected(state, payload: ICompany | null) {
      state.selected = payload;
    },
    setCompanies(state, payload: ICompany[]) {
      state.companies = payload;
    },
  },
  effects: dispatch => ({
    async HandleCreateCompany(payload: ICreateCompanyPayload) {
      const { values, navigate } = payload;
      try {
        dispatch.companies.setLoading(true);
        const { data } = await createCompany(values);
        dispatch.companies.setSelected(data.data);
        dispatch.auth.handleGetCurrentUser();
        navigate('/create-job');
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.companies.setLoading(false);
      }
    },
    async handleUpdateCompany(payload: IUpdateCompany) {
      const { id, navigate, values, formData, setOpen } = payload;
      try {
        dispatch.companies.setLoading(true);
        await updateCompanyDetails(values, id);
        if (formData) {
          await updateCompanyImage(id, formData);
        }
        dispatch.auth.handleGetCurrentUser();
        if (navigate) {
          navigate('/profile');
        }
        if (setOpen) {
          setOpen(false);
        }
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.companies.setLoading(false);
      }
    },
    async handleGetCompanies() {
      try {
        dispatch.companies.setLoading(true);
        const { data } = await getAllCompanies();
        dispatch.companies.setCompanies(data.data.companies);
      } catch (err: any) {
        console.log(err.message);
      } finally {
        dispatch.companies.setLoading(false);
      }
    },
  }),
});

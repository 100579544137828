import React, { FC, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import DialogLayout from 'Dialogs/DialogLayout';
import Button from 'components/Button/Button';
import { IVideoQuestion } from 'types/interfaces';
import ASSETS from 'assets';
import GoPremium from 'Dialogs/GoPremium/GoPremium';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedQuestion: IVideoQuestion;
}

const Timer: FC<IProps> = ({ open, setOpen, selectedQuestion }) => {
  const dispatch = useDispatch<Dispatch>();

  const { data } = useSelector((state: RootState) => state.video);
  const [time, setTime] = useState({
    seconds: '00',
    mints: '00',
  });

  const [premium, setPremium] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTime({ ...time, [name]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDone = () => {
    const seconds = +time.seconds;
    const minutes = +time.mints * 60;
    const totalSeconds = seconds + minutes;

    let duration = totalSeconds;

    const videoData = data.filter(v => v.id !== selectedQuestion.id);

    videoData.forEach(v => {
      duration += +v.duration;
    });

    if (duration <= 60) {
      dispatch.video.setDuration({
        id: selectedQuestion.id,
        value: totalSeconds.toString(),
      });
      handleClose();
    } else {
      setPremium(true);
    }
  };

  useEffect(() => {
    if (selectedQuestion?.duration) {
      const seconds = +selectedQuestion?.duration;
      setTime({
        seconds: (seconds % 60).toString(),
        mints: Math.floor(seconds / 60).toString(),
      });
    }
  }, [selectedQuestion]);

  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[484px] max-w-[380px] bg-grey-100 rounded-[12px] pt-[20px] 2xl:px-[30px] px-5 2xl:pb-[32px] pb-5'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={handleClose}
            alt=''
          />
        </div>
        <p className='2xl:text-32 text-2xl text-primary font-medium 2xl:mt-[18px] mt-2'>
          Set the timer
        </p>
        <p className='2xl:text-lg text-base 2xl:mt-2 mt-1'>
          <span className='font-medium'>Prompt:</span> What I am looking for{' '}
        </p>
        <div className='flex items-center justify-between 2xl:mt-[33px] mt-5'>
          <p className='text-lg font-medium'>Enter time</p>
          <div className='flex items-start'>
            <div>
              <input
                type='number'
                className='h-12 2xl:w-[65px]  w-[55px]  outline-none border border-grey-400 focus:border-secondary rounded-[6px] px-3 2xl:text-2xl text-xl bg-transparent'
                name='mints'
                value={time.mints === '0' ? '00' : time.mints}
                onChange={handleChange}
                id=''
              />
              <p className='text-sm text-placeholder'>Minutes</p>
            </div>
            <p className='text-[38px] leading-10 px-2'>:</p>
            <div>
              <input
                type='number'
                className='h-12 2xl:w-[65px]  w-[55px]  outline-none border border-grey-400 focus:border-secondary rounded-[6px] px-3 2xl:text-2xl text-xl bg-transparent'
                name='seconds'
                value={time.seconds}
                onChange={handleChange}
                id=''
                max={59}
                min={0}
              />
              <p className='text-sm text-placeholder'>Seconds</p>
            </div>
          </div>
        </div>
        <Button
          label='Done'
          className='!w-full 2xl:!text-22 !text-lg 2xl:mt-10 mt-8'
          onClick={handleDone}
        />
      </div>
      {premium && (
        <GoPremium
          open={premium}
          setOpen={setPremium}
          message='Get a premium account to unlock more duration for intro video and other features!                                           '
          title='Want more duration?'
        />
      )}
    </DialogLayout>
  );
};

export default Timer;

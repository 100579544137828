import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

import GoPremium from 'Dialogs/GoPremium/GoPremium';
import Button from 'components/Button/Button';

import ASSETS from 'assets';
import Timer from 'Dialogs/Timer/Timer';
import { IVideoQuestion } from 'types/interfaces';
import { renderSeconds } from 'utils';

const CreateVideo = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<Dispatch>();

  const [openPremium, setOpenPremium] = useState(false);
  const [openTimer, setOpenTimer] = useState(false);
  const [selected, setSelected] = useState<IVideoQuestion | null>(null);

  const { data } = useSelector((state: RootState) => state.video);
  const [disableNext, setDisableNext] = useState(true);

  const handleChange = (value: string, id: number) => {
    dispatch.video.setQuestion({ id, value });
  };

  const handleRemove = (id: number) => {
    dispatch.video.removeQuestion(id);
  };

  const handleAddQuestion = () => {
    if (data.length === 4) {
      setOpenPremium(true);
    } else {
      dispatch.video.addNewQuestion();
    }
  };

  const handleOpenTimer = (q: IVideoQuestion) => {
    setOpenTimer(true);
    setSelected(q);
  };

  const handleClick = () => {
    navigate('/upload-or-record');
  };

  useEffect(() => {
    let hasError = false;
    data.forEach(question => {
      if (!question.question || !question.duration) {
        hasError = true;
      }
    });
    setDisableNext(hasError);
  }, [data]);

  return (
    <div className='w-full flex-grow overflow-auto '>
      <div className='w-full max-w-[776px] mx-auto bg-white border border-grey-600 rounded 2xl:p-[35px] p-4 px-5 '>
        <p className='2xl:text-32 text-2xl text-primary font-medium'>
          Upload or Record a Video
        </p>
        <p className='text-lg font-medium 2xl:mt-[30px] mt-[10px]'>
          How it works:{' '}
        </p>
        <div className='flex flex-col gap-1 mt-3'>
          <div className='flex items-center gap-2'>
            <div className='w-1 h-1 bg-placeholder rounded-full'></div>
            <p className='2xl:text-base text-sm'>
              You have up to one minute to record a video.
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <div className='w-1 h-1 bg-placeholder rounded-full'></div>
            <p className='2xl:text-base text-sm'>
              You can submit a video with or without prompts.
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <div className='w-1 h-1 bg-placeholder rounded-full'></div>
            <p className='2xl:text-base text-sm'>
              You can use the default prompts or edit them and create your own.
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <div className='w-1 h-1 bg-placeholder rounded-full'></div>
            <p className='2xl:text-base text-sm'>
              You can submit multiple answers by cropping the length of the
              prompts.
            </p>
          </div>
        </div>
        {/* videos section */}
        <div className='2xl:mt-12 mt-6'>
          <div className='flex items-center gap-2'>
            <p className='2xl:text-lg text-base font-medium'>
              Default Prompts for Video{' '}
              <span className='text-placeholder italic !font-normal'>
                (Optional)
              </span>
            </p>
            <div
              className='2xl:size-6 size-5 grid place-items-center bg-secondary rounded cursor-pointer'
              role='button'
              onClick={handleAddQuestion}
            >
              <p className='text-sm font-medium text-white'>+</p>
            </div>
          </div>
        </div>

        <div className='flex flex-col gap-3 mt-2'>
          {data.map((question, index: number) => (
            <div className='flex items-center gap-[14px]' key={question.id}>
              <div className='flex items-center  flex-grow'>
                <p className='text-base font-medium w-[22px] text-center'>
                  {index + 1}.
                </p>
                <div className='flex-grow 2xl:h-[77px] h-[45px] flex items-center border border-black rounded-lg  relative'>
                  <input
                    value={question.question}
                    onChange={e => handleChange(e.target.value, question.id)}
                    type='text'
                    className='w-full h-full p-4 outline-none 2xl:text-base text-sm rounded-lg'
                  />
                  <img
                    src={ASSETS.closeSmall}
                    className='absolute top-[-5px] right-[-5px] cursor-pointer'
                    alt=''
                    onClick={() => handleRemove(question.id)}
                  />
                </div>
              </div>
              {!question.duration ? (
                <div className='min-w-10'>
                  <img
                    src={ASSETS.clock}
                    className='2xl:w-6 w-5 cursor-pointer'
                    onClick={() => handleOpenTimer(question)}
                    alt=''
                  />
                </div>
              ) : (
                <p
                  className='text-base font-medium w-10 cursor-pointer'
                  onClick={() => handleOpenTimer(question)}
                >
                  {renderSeconds(+question.duration)}
                </p>
              )}
            </div>
          ))}
        </div>
        <Button
          label='Next'
          className='!w-[calc(100%_-_70px)] ml-5 2xl:!text-22 !text-lg mt-4'
          disabled={disableNext}
          onClick={handleClick}
        />
      </div>
      {openPremium && (
        <GoPremium
          open={openPremium}
          setOpen={setOpenPremium}
          title='Want to add more prompts?'
          message='Get a premium account to unlock unlimited prompts and other features!                                           '
        />
      )}
      {openTimer && selected && (
        <Timer
          selectedQuestion={selected}
          open={openTimer}
          setOpen={setOpenTimer}
        />
      )}
    </div>
  );
};

export default CreateVideo;

import React, { useCallback, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import { getTags } from 'http/jobService';
import { ITag } from 'types/interfaces';
import debounce from 'utils';

import Accordion from './Accordion';
import ASSETS from 'assets';

const Tags = () => {
  const dispatch = useDispatch<Dispatch>();

  const { tags: selected } = useSelector((state: RootState) => state.filters);

  const [search, setSearch] = useState('');
  const [tags, setTags] = useState<Array<ITag>>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleSelect = (op: string) => {
    dispatch.filters.setListFilters({ name: 'tags', value: op });
    setSearch('');
  };

  const fetchTags = async (search: string) => {
    try {
      const { data } = await getTags(search);
      setTags(data.data.tags);
    } catch (err: any) {
      console.log(err.message);
    }
  };

  // eslint-disable-next-line
  const debounceFetchTags = useCallback(
    debounce((searchTerm: string) => fetchTags(searchTerm), 300),
    []
  );

  const handleFetchTags = (search: string) => {
    debounceFetchTags(search);
  };

  useEffect(() => {
    handleFetchTags(search);
  }, [search]);

  return (
    <Accordion label='Tags'>
      <div className='px-2 pb-2 flex flex-col gap-1'>
        <div className='flex items-center gap-1 flex-wrap'>
          {selected.map(s => (
            <div className='bg-secondary px-[7px] h-7 flex items-center rounded'>
              <p className='text-[10px] font-medium'>{s}</p>
              <img
                src={ASSETS.cross}
                className='w-3 h-3 cursor-pointer'
                onClick={() => handleSelect(s)}
                alt=''
              />
            </div>
          ))}
        </div>

        <div className='border border-grey-400 h-9 rounded-md bg-white overflow-hidden relative'>
          <input
            type='search'
            name=''
            placeholder='Search'
            className='w-full h-full text-sm outline-none border-none pl-5 pr-3'
            id=''
            value={search}
            onChange={handleChange}
          />
          <div className='absolute left-0 top-0 h-full w-[20px] aspect-square grid place-items-center'>
            <img src={ASSETS.search} className='w-4' alt='' />
          </div>
        </div>
        <div className='flex flex-col rounded bg-white max-h-[300px] empty:hidden overflow-auto'>
          {tags.map(op => (
            <div
              key={op.id}
              className='flex items-center min-h-8 gap-2 px-2 border-b border-b-grey-400
                  last-of-type:border-b-0
                  '
              onClick={() => handleSelect(op.name)}
            >
              <img
                src={
                  selected.includes(op.name)
                    ? ASSETS.checkboxCheck
                    : ASSETS.checkbox
                }
                className='w-5'
                alt=''
              />
              <p className='text-sm'>{op.name}</p>
            </div>
          ))}
        </div>
      </div>
    </Accordion>
  );
};

export default Tags;

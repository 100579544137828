import React, { useState, FC, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import DialogLayout from 'Dialogs/DialogLayout';
import Select from 'components/Select/Select';
import Button from 'components/Button/Button';

import ASSETS from 'assets';
import { ROLES } from 'types/enums';

interface IProps {
  open: boolean;
}

const applicantOptions = [
  { label: 'Applicant', value: ROLES.Applicant },
  {
    label: 'Recruiting Agent for Applicant',
    value: ROLES['Recruiting Agent for Applicant'],
  },
  {
    label: 'Staffing Agent for Applicant',
    value: ROLES['Staffing Agent for Applicant'],
  },
];

const employerOptions = [
  { label: 'Employer', value: ROLES.Employer },
  {
    label: 'Recruiting Agent for Employer',
    value: ROLES['Recruiting Agent for Employer'],
  },
  {
    label: 'Staffing Agent for Employer',
    value: ROLES['Staffing Agent for Employer'],
  },
];

const ChoosePath: FC<IProps> = ({ open }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();
  const [role, setRole] = useState<'applicant' | 'employer' | ''>('');
  const [roleCategory, setRoleCategory] = useState<string>('');
  const [options, setOptions] = useState(applicantOptions);

  const { loading } = useSelector((state: RootState) => state.user);
  const { user } = useSelector((state: RootState) => state.auth);

  const handleRole = (value: string) => {
    setRole(value as typeof role);
    setErrors(prev => ({ roleCategory: '', role: '' }));
    setRoleCategory('');
  };
  const handleRoleCategory = (value: string) => {
    setRoleCategory(value);
    setErrors(prev => ({ ...prev, roleCategory: '' }));
  };
  const handleClose = () => dispatch.utils.setRoleModal(false);

  const [errors, setErrors] = useState({
    role: '',
    roleCategory: '',
  });

  const handleClick = () => {
    const newErrors = {
      role: '',
      roleCategory: '',
    };
    let hasError = false;
    if (!role) {
      newErrors.role = 'Select identity';
      hasError = true;
    }
    if (!roleCategory) {
      newErrors.roleCategory = 'Select identity';
      hasError = true;
    }

    if (!hasError) {
      dispatch.user.handleUpdateRole({
        role: roleCategory as ROLES,
        navigate,
      });
    } else {
      setErrors(newErrors);
    }
  };

  useEffect(() => {
    if (role) {
      if (role === 'applicant') {
        // setRoleCategory('A');
        setOptions(applicantOptions);
      } else if (role === 'employer') {
        setOptions(employerOptions);
        // setRoleCategory('E');
      }
    }
  }, [role, options]);

  useEffect(() => {
    if (user?.role) {
      if (
        user.role === ROLES.Applicant ||
        user.role === ROLES['Recruiting Agent for Applicant'] ||
        user.role === ROLES['Staffing Agent for Applicant']
      ) {
        setRole('applicant');
        setRoleCategory(user.role);
      } else {
        setRole('employer');
      }
      setRoleCategory(user.role);
    }
  }, [user]);

  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[484px] max-w-[420px] bg-grey-100 rounded-[16px] pt-[20px] 2xl:px-[30px] 2xl:pb-[32px] px-5 pb-6'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={handleClose}
            alt=''
          />
        </div>
        <div className='2xl:px-[26px] px-2 flex flex-col items-center'>
          <p className='2xl:text-32 text-2xl text-primary'>Choose Your Path</p>
          <div className='w-full 2xl:mt-11 mt-6'>
            <Select
              onChange={handleRole}
              selected={role}
              options={[
                { label: 'Applicant', value: 'applicant' },
                { label: 'Employer ', value: 'employer' },
              ]}
              error={errors.role}
            />
          </div>
          <div className='w-full 2xl:mt-[33px] mt-5'>
            <Select
              onChange={handleRoleCategory}
              selected={roleCategory}
              disabled={!role}
              options={
                role === 'applicant' ? applicantOptions : employerOptions
              }
              error={errors.roleCategory}
            />
          </div>
          <Button
            label='Continue'
            className='!w-full 2xl:!h-[56px] !text-lg 2xl:mt-[58px] mt-9'
            onClick={handleClick}
            loading={loading}
          />
        </div>
      </div>
    </DialogLayout>
  );
};

export default ChoosePath;

import React, { FC } from 'react';

import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';
import Button from 'components/Button/Button';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onStartRecording: () => void;
}

const RecordingTips: FC<IProps> = ({ open, setOpen, onStartRecording }) => {
  const handleClose = () => {
    setOpen(false);
  };
  const handleStart = () => {
    onStartRecording();
    handleClose();
  };
  return (
    <DialogLayout open={open}>
      <div className='w-full 2xl:max-w-[704px] max-w-[650px] 2xl:p-6 p-5 bg-grey-100 rounded-[15px]'>
        <div className='flex justify-end'>
          <img
            src={ASSETS.cross}
            className='cursor-pointer'
            onClick={handleClose}
            alt=''
          />
        </div>
        <div className='flex flex-col items-center'>
          <img src={ASSETS.tips} className='w-20 2xl:w-auto' alt='' />
          <p className='2xl:text-32 text-2xl text-primary font-medium mt-5'>
            Quick Tips
          </p>
        </div>
        <div className='flex flex-col gap-[4.5px] 2xl:mt-[26px] mt-5 px-[36px]'>
          <div className='flex items-center gap-[10px]'>
            <img src={ASSETS.checkCircle} className='w-5' alt='' />
            <p className='2xl:text-22 text-lg'>Choose a quiet setting</p>
          </div>
          <div className='flex items-center gap-[10px]'>
            <img src={ASSETS.checkCircle} className='w-5' alt='' />
            <p className='2xl:text-22 text-lg'>
              Be sure your responses are clear and concise
            </p>
          </div>
          <div className='flex items-center gap-[10px]'>
            <img src={ASSETS.checkCircle} className='w-5' alt='' />
            <p className='2xl:text-22 text-lg'>
              Wear suitable attire to the position you're applying for
            </p>
          </div>
          <div className='flex items-center gap-[10px]'>
            <img src={ASSETS.checkCircle} className='w-5' alt='' />
            <p className='2xl:text-22 text-lg'>
              Articulate your words and speak at a moderate pace
            </p>
          </div>
          <div className='flex items-center gap-[10px]'>
            <img src={ASSETS.checkCircle} className='w-5' alt='' />
            <p className='2xl:text-22 text-lg'>Look directly into the camera</p>
          </div>
          <div className='flex items-center gap-[10px]'>
            <img src={ASSETS.checkCircle} className='w-5' alt='' />
            <p className='2xl:text-22 text-lg'>
              Explain why you're right for the opportunity
            </p>
          </div>
          <Button
            label='Start Recording'
            className='2xl:!text-22 !text-lg  2xl:mt-11 mt-7'
            onClick={handleStart}
          />
        </div>
      </div>
    </DialogLayout>
  );
};

export default RecordingTips;

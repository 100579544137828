import ASSETS from 'assets';
import classNames from 'classnames';
import React, { FC, TextareaHTMLAttributes } from 'react';

interface IProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  error?: string;
}

const Textarea: FC<IProps> = ({ label, error, ...rest }) => {
  return (
    <div className='w-full'>
      <p className='text-base font-medium mb-[6px]'>{label}</p>
      <textarea
        {...rest}
        className={classNames(
          'w-full border border-grey-400 outline-none resize-none px-[18px] py-[13px] text-base  rounded-[10px] focus:border-secondary placeholder:text-black placeholder:opacity-85',
          error && '!border-red'
        )}
      ></textarea>
      {error && (
        <div className='flex items-center gap-1 mt-1'>
          <img src={ASSETS.errorInfo} alt='' />
          <p className='text-xs text-error'>{error}</p>
        </div>
      )}
    </div>
  );
};

export default Textarea;

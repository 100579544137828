import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { RootState, Dispatch } from 'store';

import Post from 'pages/Explore/components/Post/Post';
import styled from 'styled-components';

const Following = () => {
  const dispatch = useDispatch<Dispatch>();
  const { user } = useSelector((state: RootState) => state.auth);
  const { posts } = useSelector((state: RootState) => state.posts);

  useEffect(() => {
    dispatch.posts.handleGetVideos('?isFollowing=true');
    // eslint-disable-next-line
  }, [user]);
  return (
    <Container className=''>
      {posts.map((post, i) => (
        <Post key={i} post={post} index={i} following />
      ))}
    </Container>
  );
};

export default Following;

const Container = styled.div`
  flex-grow: 1;
  overflow: auto;
`;

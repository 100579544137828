import React, { useState } from 'react';
import ASSETS from 'assets';
import Textarea from 'components/Textarea/Textarea';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';

const options = [
  'No reason',
  'Inappropriate Content',
  'Someone is in danger',
  'Profile is scam or fake ',
  'Minor or Underage',
  'Other',
];

const Report = () => {
  const navigate = useNavigate();

  const [selected, setSelected] = useState('');

  const handleBack = () => navigate(-1);
  return (
    <>
      <div className='w-full'>
        <div className='w-full  max-w-[749px] rounded bg-white border-[0.5px] border-grey-600 2xl:px-6 2xl:pt-9 2xl:pb-6 p-5'>
          <p className='2xl:text-2xl text-xl font-bold'>Select an action</p>
          <div className='flex flex-col gap-2 mt-5'>
            {options.map(opt => (
              <div
                key={opt}
                className='flex justify-between items-center bg-grey-100 2xl:h-12 h-10 px-6 border-b-[0.5px] border-b-[#8D8A95] cursor-pointer'
                onClick={() => setSelected(opt)}
              >
                <p className='2xl:text-lg text-base font-medium'>{opt}</p>
                <img src={ASSETS.arrowRight} alt='' />
              </div>
            ))}
          </div>
          {!selected ? (
            <p className='text-18 text-center 2xl:my-[142px] my-[50px] max-w-[40ch] mx-auto'>
              Help us keep the Final Round safe by telling us why you are
              reporting this job
            </p>
          ) : (
            <div className='2xl:mt-10 mt-6'>
              <p className='2xl:text-2xl text-lg font-bold'>Write a report</p>
              <p className='2xl:text-lg text-base font-normal mt-2'>
                The more details you give the better we can evaluate your report
              </p>
              <div className='mt-[13px]'>
                <Textarea
                  label='Feedback'
                  placeholder='Write your feedback...'
                  rows={6}
                />
              </div>
              <Button
                label='Submit'
                className='w-full 2xl:!text-22 !text-lg mt-[22px]'
                onClick={handleBack}
              />
            </div>
          )}
        </div>
        <div className='h-11'></div>
      </div>
    </>
  );
};

export default Report;

import { FC } from 'react';
import DialogLayout from 'Dialogs/DialogLayout';
import ASSETS from 'assets';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';
import { CONSTANTS } from 'app.config';

interface IProps {}

const EmailVerified: FC<IProps> = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();

  const { emailVerified } = useSelector((state: RootState) => state.utils);
  const { user, accessToken, email } = useSelector(
    (state: RootState) => state.auth
  );

  const handleSignIn = () => {
    const type = emailVerified.type;

    switch (type) {
      case 'email':
        dispatch.utils.setEmailVerified({
          open: false,
          type: 'email',
        });
        if (user?.signup_type === 'P') {
          localStorage.setItem(CONSTANTS.TOKEN, accessToken);
          return navigate('/explore');
        }
        if (user?.signup_type === 'E') {
          return navigate('/sign-up');
        }
        break;
      case 'phone':
        dispatch.utils.setEmailVerified({
          open: false,
          type: 'email',
        });
        navigate('/sign-up/create-account');
        break;
      case 'forgot-password':
        dispatch.utils.setEmailVerified({
          open: false,
          type: 'email',
        });
        navigate('/sign-in');
        break;
      case 'forgot-email':
        dispatch.utils.setEmailVerified({
          open: false,
          type: 'email',
        });
    }
    // dispatch.utils.setEmailVerified({
    //   open: false,
    //   type: 'email',
    // });
    // navigate('/sign-in');
  };

  return (
    <DialogLayout open={emailVerified.open}>
      <Container className='w-full 2xl:max-w-[484px] max-w-[420px] relative 2xl:py-[56px]  px-[21px] p-5'>
        <div
          className='close-btn absolute top-5 right-5 cursor-pointer'
          onClick={handleSignIn}
        >
          <img src={ASSETS.close} alt='' />
        </div>
        <img src={ASSETS.verified} alt='' />
        <p className='2xl:text-32 text-2xl text-primary font-medium !m-0 2xl:!mt-[54px] !mt-7'>
          {emailVerified.type === 'forgot-password'
            ? 'Password Changed'
            : emailVerified.type === 'forgot-email'
            ? 'Email Recovered'
            : emailVerified.type === 'phone'
            ? 'Phone Number Verified'
            : 'Email Verified'}
        </p>
        <p className='2xl:text-lg text-base text-placeholder mt-[10px]'>
          {emailVerified.type === 'forgot-password'
            ? 'Your password has been changed successfully!'
            : emailVerified.type === 'forgot-email'
            ? `Your Recovered Email is: ${email}`
            : emailVerified.type === 'phone'
            ? 'Your phone number has been verified successfully'
            : 'Your email has been verified successfully'}
        </p>
        <Button
          label={
            emailVerified.type === 'phone'
              ? 'Sign up'
              : emailVerified.type === 'forgot-password'
              ? 'Continue'
              : 'Continue'
          }
          className='2xl:mt-[54px] mt-7 w-full !text-xl'
          onClick={handleSignIn}
        />
      </Container>
    </DialogLayout>
  );
};

export default EmailVerified;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors['grey-100']};
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px #00000040;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

import React from 'react';
import Chats from './Components/Chats';
import Messages from './Components/Messages';

const Chat = () => {
  return (
    <div className='w-full flex-grow flex flex-col'>
      <div className='w-full flex-grow flex items-stretch border-[0.5px] border-grey-600 rounded-lg overflow-hidden'>
        <div className='2xl:min-w-[350px] min-w-[340px] 2xl:w-[380px] w-[350px] h-full bg-white border-r-[0.5px] border-r-grey-600 flex flex-col'>
          <div className='px-[31px] flex items-center 2xl:h-[79.5px] h-[64.5px]'>
            <p className='2xl:text-[28px] text-xl text-primary font-medium'>
              Inbox
            </p>
          </div>
          <Chats />
        </div>
        <Messages />
      </div>
    </div>
  );
};

export default Chat;

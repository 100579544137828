import { useState, useRef, FC, useEffect } from 'react';

import classNames from 'classnames';
import ReactQuill, { Value } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ASSETS from 'assets';
import { t } from 'i18next';

interface IProps {
  error?: string;
  onChange?: (value: string) => void;
  value?: string;
}

const CustomToolbar = () => {
  return (
    <div id='toolbar'>
      <button className='ql-bold'></button>
      <button className='ql-list' value='bullet'></button>
    </div>
  );
};

const QuilEditor: FC<IProps> = ({ error, onChange, value }) => {
  const quillRef = useRef<ReactQuill>(null);
  const [editordelta, setEditorDelta] = useState<Value | undefined>(undefined);
  const [contentLength, setContentLength] = useState(0);
  const [active, setActive] = useState(false);
  const [valueFetched, setValueFetched] = useState(false);
  const handleChange = (
    content: string,
    delta: any,
    source: any,
    editor: any
  ) => {
    const deltaFormat = editor.getContents();
    setEditorDelta(deltaFormat);
    setContentLength(editor.getText().length);
    if (typeof onChange === 'function') {
      if (editor.getText().length - 1) {
        onChange(JSON.stringify(deltaFormat.ops));
      } else {
        onChange('');
      }
    }
  };

  useEffect(() => {
    if (value && !valueFetched) {
      // @ts-ignore
      setEditorDelta({ ops: JSON.parse(value) });
      setValueFetched(true);
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <>
      <div className='relative'>
        <ReactQuill
          value={editordelta}
          onChange={handleChange}
          modules={{
            toolbar: {
              container: '#toolbar',
            },
            //   toolbar: [['bold'], [{ list: 'bullet' }], [{ header: [1] }]],
          }}
          className={classNames(
            'custom-quill',
            active && 'active',
            error && 'error'
          )}
          placeholder={t('Enter Description')}
          theme='snow'
          ref={quillRef}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        />
        <CustomToolbar />
      </div>
      <div className='flex items-center justify-between mt-2'>
        <div className='flex items-center gap-1 mt-1'>
          {error && (
            <>
              <img src={ASSETS.errorInfo} alt='' />
              <p className='text-xs text-error'>{error}</p>
            </>
          )}
        </div>
        <p className='text-base text-placeholder text-right'>
          {contentLength === 0 ? contentLength : contentLength - 1}/2000
        </p>
      </div>
    </>
  );
};

export default QuilEditor;

import React from 'react';

const InappropriateContent = () => {
  return (
    <div className='2xl:px-6 px-4 2xl:py-9 py-5'>
      <p className='2xl:text-2xl text-xl font-bold'>Inappropriate Content</p>
      <p className='text-lg font-medium 2xl:mt-[30px] mt-4'>
        Rules & Regulation
      </p>
      <p className='2xl:text-base text-sm 2xl:mt-[21px] mt-3'>
        In order to maintain a safe and respectful environment for all users,
        Final Round, has implemented a strict policy regarding inappropriate
        content.
      </p>
      <div className='flex flex-col gap-2 mt-5'>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Users are prohibited from posting, sharing, or engaging in any
            content that is considered offensive, discriminatory, harassing, or
            otherwise inappropriate. This includes, but is not limited to,
            content that is defamatory, hateful, or violates any applicable laws
            or regulations.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            If any content is found to be in violation of our policy, it will be
            promptly removed, and the responsible user may face consequences,
            including account suspension or termination.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Reports will be thoroughly investigated, and appropriate action will
            be taken to maintain a positive and inclusive community.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            We encourage our users to report any inappropriate content they come
            across while using Final Round
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Any content uploaded by users, such as resumes or job postings, must
            comply with copyright laws and not infringe on the rights of others.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Users are responsible for safeguarding their account credentials and
            should not share them with others.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Any misuse of the platform, including spamming, phishing, or other
            fraudulent activities, will result in immediate termination of the
            account.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            The job app reserves the right to moderate content and remove any
            inappropriate or offensive material.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Users should adhere to all applicable laws and regulations governing
            employment practices and data privacy.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Users must provide accurate and up-to-date information in their
            profiles.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Respectful and professional behavior is expected when communicating
            with employers or other users.
          </p>
        </div>
        <div className='flex items-start gap-2'>
          <div className='h-6 flex items-center'>
            <div className='w-1 h-1 rounded-full bg-black'></div>
          </div>
          <p className='2xl:text-base text-sm text-placeholder'>
            Users should not engage in any form of discrimination or harassment
            based on race, gender, religion, nationality, or any other
            characteristic.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InappropriateContent;

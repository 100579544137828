import styled from 'styled-components';
import ASSETS from 'assets';

const CONFIG = [
  {
    img: ASSETS.job1,
    company: 'Wardiere Software',
    job: 'UX UI Designer ',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job2,
    company: 'Dragon Zone',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
  // {
  //   img: ASSETS.job3,
  //   company: 'Gamer Zone',
  //   job: 'Viusal Designer ',
  //   location: 'Florida, United States',
  // },
  {
    img: ASSETS.job4,
    company: 'Skull Expert ',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job5,
    company: 'Gitex Gaming Studio',
    job: 'UX UI Expert',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job6,
    company: 'Crabite Games',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
];

const SavedJobs = () => {
  return (
    <Container className='2xl:pt-[33px] pt-5'>
      <JobsSection>
        {CONFIG.map((job, i) => (
          <Job
            key={i}
            className='rounded-lg 2xl:gap-[29px] gap-4 shadow-mm 2xl:h-[96px] h-[82px] px-3 overflow-auto bg-white'
          >
            <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
              <img src={job.img} alt='' />
            </div>
            <div>
              <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                {job.company}
              </p>
              <p className='2xl:text-lg text-base font-medium leading-6'>
                {job.job}
              </p>
              <p className='2xl:text-base text-sm'>{job.location}</p>
            </div>
          </Job>
        ))}
      </JobsSection>
    </Container>
  );
};

export default SavedJobs;

const Container = styled.div`
  max-width: 820px;
  width: 100%;
  margin-inline: auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const JobsSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Job = styled.div`
  display: flex;
  align-items: center;
`;

import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Dispatch } from 'store';

import ASSETS from 'assets';

const ApplicationSubmit = () => {
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    dispatch.utils.setPath('profile');
    // eslint-disable-next-line
  }, []);

  return (
    <div className='w-full max-w-[900px] bg-white border-[0.5px] border-grey-600 rounded px-10 py-[50px] mx-[auto]'>
      <div className='flex flex-col items-center mt-[7px]'>
        <img src={ASSETS.submit} alt='' />
        <p className='text-2xl font-medium text-primary mt-6'>
          Application Submitted!
        </p>
        <p className='text-lg mt-[11px]'>
          Here is an external link to the job you applied
        </p>
        <p className='text-lg underline text-blue'>
          https://www.examplewebsite123.com/fakepage
        </p>
      </div>
      <p className='text-lg font-medium mb-[18px] mt-[32px]'>
        Recommended Jobs
      </p>
      <div className='flex flex-col gap-2'>
        {Array.from({ length: 4 }, (_, i) => i).map(i => (
          <div
            key={i}
            className='flex items-center gap-[29px] rounded-lg border border-grey-600 overflow-auto bg-grey-100'
          >
            <div className='img w-[96px] aspect-[1/1]'>
              <img src={ASSETS.job} alt='' />
            </div>
            <div>
              <p className='text-22 text-placeholder font-bold leading-6'>
                Wardiere Software
              </p>
              <p className='text-lg font-medium leading-6'>UX UI Designer</p>
              <p className='text-base'>Florida, United States</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplicationSubmit;

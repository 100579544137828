import React from 'react';
import ASSETS from 'assets';

const NotFound = () => {
  return (
    <div className='flex-grow w-full flex justify-center items-center '>
      <div className='flex flex-col items-center 2xl:mt-[100px] mt-[50px]'>
        <img src={ASSETS.notFound} alt='' />
        <p className='text-32 text-primary font-medium mt-10'>
          No Results Found
        </p>
      </div>
    </div>
  );
};

export default NotFound;

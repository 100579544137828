import { ROLES } from 'types/types';
import api from './index';
import {
  IApiResponse,
  IUpdateApplicantDetails,
  IUpdateProfile,
} from 'types/api';
import { ISearchData, IUser } from 'types/interfaces';

export const updateRole = (role: ROLES) => api.patch('/v1/user/role', { role });

export const updateProfile = (data: IUpdateProfile) =>
  api.patch<IApiResponse<IUser>>('/v1/user', data);

export const updateProfileImage = (data: FormData) =>
  api.patch('/v1/user/image', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const uploadFiles = (data: FormData) =>
  api.put('/v1/user/files', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const updateApplicantDetails = (data: IUpdateApplicantDetails) =>
  api.put('/v1/user/details', data);

export const searchUsers = ({ text = '', location = '' }) => {
  let query = '?';
  if (text) query += `text=${text}&`;
  if (location) query += `location=${location}&`;

  return api.get<IApiResponse<ISearchData>>(`/v1/user/search${query}`);
};

export const blockUser = (id: string) =>
  api.post('/v1/user/block', {
    user_id: id,
    isReport: false,
  });

import { useState } from 'react';

import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import Button from 'components/Button/Button';
import PhoneInput from 'components/PhoneInput/PhoneInput';

import ASSETS from 'assets';
import { isPhoneExist } from 'http/authService';

const regex =
  /^\+?[1-9]\d{1,2}[ .-]?\(?\d+\)?([ .-]?\d+)*([ ]?(ext|x|extension)\s?\d+)?$/;

const Phone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const { loading, authType } = useSelector((state: RootState) => state.auth);

  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');

  const handleBack = () => navigate(-1);

  const handleClick = async () => {
    try {
      if (regex.test(phone)) {
        if (authType === 'P') {
          dispatch.auth.HandlePhoneSignUp({
            values: { type: 'P', mobile: phone },
          });
        } else {
          dispatch.auth.setLoading(true);
          const { data } = await isPhoneExist({ mobile: phone });
          if (data.data.isMobileExists) {
            setError('Phone number already exist');
            dispatch.auth.setLoading(false);
            return;
          }
          dispatch.auth.setLoading(false);
          dispatch.auth.handleAddPhone({ phone, navigate });
        }
      } else {
        setError('Please enter a valid phone number');
      }
    } catch (err: any) {
      console.log(err.message);
    }
  };
  return (
    <Container className='2xl:max-w-[484px] max-w-[420px] bg-grey-100 2xl:p-6 2xl:pb-10 p-5 '>
      <BackButton className='bg-gray-300 cursor-pointer' onClick={handleBack}>
        <img src={ASSETS.chevronLeft} alt='' />
      </BackButton>

      <p className='2xl:text-32 text-2xl text-primary font-medium 2xl:mt-[35px] 2xl:mb-[24px] mt-[20px] mb-[16px]'>
        Phone Number
      </p>
      <div className='flex mb-3'>
        <PhoneInput phone={phone} setPhone={setPhone} error={error} />
      </div>
      <p className='text-sm'>
        Final Round will send you a text with a <b>Verification code</b>
      </p>
      <p className='text-xs text-grey-600'>Message and data rates may apply</p>
      <Button
        label='Continue'
        className='2xl:!text-xl !text-lg w-full 2xl:mt-10 mt-6'
        onClick={handleClick}
        loading={loading}
      />
    </Container>
  );
};

export default Phone;

const Container = styled.div`
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const BackButton = styled.div`
  height: 35px;
  width: 35px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  display: grid;
  place-items: center;
`;

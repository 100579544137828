import ASSETS from 'assets';
import Button from 'components/Button/Button';
import React from 'react';

const Premium = () => {
  return (
    <div className='flex-grow flex items-center justify-center'>
      <div
        className='w-full max-w-[450px] border border-secondary rounded-[34px] overflow-hidden
      '
      >
        <div className='h-[80px] flex items-center justify-center bg-premium'>
          <p className='text-32 font-medium'>Premium</p>
        </div>
        <div className='py-6 px-5'>
          <p className='text-[36px] font-bold text-center'>$19.99</p>
          <div className='flex flex-col gap-[15px]'>
            <div className='flex items-start gap-2'>
              <div className='flex h-[28px] items-center min-w-5'>
                <img src={ASSETS.checkP} className='w-5 h-5' alt='' />
              </div>
              <p className='text-xl'>Increase the time of questions</p>
            </div>
            <div className='flex items-start gap-3'>
              <div className='flex h-[28px] items-center min-w-5'>
                <img src={ASSETS.checkP} className='w-5 h-5' alt='' />
              </div>
              <p className='text-xl'>
                Video rating and accepted final round call for applicants
              </p>
            </div>
            <div className='flex items-start gap-3'>
              <div className='flex h-[28px] items-center min-w-5'>
                <img src={ASSETS.checkP} className='w-5 h-5' alt='' />
              </div>
              <p className='text-xl'>Unposted or Draft Videos</p>
            </div>
            <div className='flex items-start gap-3 '>
              <div className='flex h-[28px] items-center min-w-5'>
                <img src={ASSETS.checkP} className='w-5 h-5' alt='' />
              </div>
              <p className='text-xl'>See their previous application videos</p>
            </div>
            <div className='flex items-start gap-3'>
              <div className='flex h-[28px] items-center min-w-5'>
                <img src={ASSETS.checkP} className='w-5 h-5' alt='' />
              </div>
              <p className='text-xl'>Send message to any employer</p>
            </div>
          </div>
          <Button
            label='Get Premium'
            className='!h-[56px] !text-[22px] w-full  mx-auto mt-[56px]'
          />
        </div>
      </div>
    </div>
  );
};

export default Premium;

import { IApiResponse, IApplyJob, ICreateJob } from 'types/api';
import api from './index';
import {
  IApplication,
  IJob,
  IJobData,
  IJobTitle,
  ITag,
} from 'types/interfaces';

export const getJobByid = (id: string) =>
  api.get<IApiResponse<IJob>>(`/v1/job/${id}`);

export const getTags = (search: string) =>
  api.get<
    IApiResponse<{ tags: ITag[]; currentPage: number; totalPages: number }>
  >(`/v1/job/tags?search=${search}`);

export const getJobTitles = (search: string) =>
  api.get<
    IApiResponse<{
      job_titles: IJobTitle[];
      totalPages: number;
      currentPage: number;
    }>
  >(`/v1/job/title?search=${search}`);

export const createJob = (data: ICreateJob) =>
  api.post<IApiResponse<IJobData>>('/v1/job', data);

export const getJobById = (job_id: string) =>
  api.get<IApiResponse<IJob>>(`/v1/job/${job_id}`);

export const uploadJobDocs = (data: FormData) =>
  api.post('/v1/job/upload', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const applyJob = (data: IApplyJob) =>
  api.post<IApiResponse<IApplication>>('/v1/job/apply', data);

export const followCompany = (id: string) =>
  api.post('/v1/user/following', { follow_to: id });

import React, { useState } from 'react';

import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button/Button';
import TimeField from 'components/TimeField/TimeField';
import GoPremium from 'Dialogs/GoPremium/GoPremium';
import DatePicker from 'components/DatePicker/DatePicker';

const schema = object({
  start_date: string().required('Start date is required').label('Start date'),
  start_time: string().required('Start time is required').label('Start time'),
  end_time: string().required('End time is required').label('End time'),
});

const convertTimeToDate = (time: string) => {
  const date = new Date();
  const [timePart, modifier] = time.split(' ');
  let [hours, minutes] = timePart.split(':').map(Number);

  if (modifier === 'PM' && hours !== 12) {
    hours += 12;
  } else if (modifier === 'AM' && hours === 12) {
    hours = 0;
  }

  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

const Schedular = () => {
  const navigate = useNavigate();

  const [premium, setPremium] = useState(false);

  const formik = useFormik({
    initialValues: {
      start_date: '',
      start_time: '',
      end_time: '',
    },
    validationSchema: schema,
    onSubmit: values => {
      const startDate = convertTimeToDate(values.start_time);
      const endDate = convertTimeToDate(values.end_time);

      const diffInMilliseconds = endDate.getTime() - startDate.getTime();
      const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
      console.log(diffInHours);
      if (diffInHours > 1) {
        return setPremium(true);
      } else {
        setPremium(false);
      }
      navigate(-1);
    },
  });

  return (
    <div className='flex-grow'>
      <div className='mx-auto w-full max-w-[566px] bg-white border border-grey-400 2xl:px-10 px-6 2xl:pt-[47px] pt-7  2xl:pb-[66px] pb-10 rounded'>
        <p className='2xl:text-32 text-2xl text-primary text-center font-medium'>
          Final Round Scheduler
        </p>
        <div className='2xl:mt-[52px] mt-10 flex flex-col gap-[18px]'>
          <DatePicker
            label=''
            value={formik.values.start_date}
            onChange={val => formik.setFieldValue('start_date', val)}
            error={
              formik.touched.start_date && formik.errors.start_date
                ? formik.errors.start_date
                : ''
            }
          />
          <div className='grid grid-cols-12 gap-[14px]'>
            <div className='col-span-6'>
              <TimeField
                label='Start time*'
                placeholder='Start time'
                onChange={val => {
                  formik.setFieldValue('start_time', val);
                }}
                error={
                  formik.touched.start_time && formik.errors.start_time
                    ? formik.errors.start_time
                    : ''
                }
              />
            </div>
            <div className='col-span-6'>
              <TimeField
                label='End time*'
                placeholder='End time'
                onChange={val => formik.setFieldValue('end_time', val)}
                error={
                  formik.touched.end_time && formik.errors.end_time
                    ? formik.errors.end_time
                    : ''
                }
              />
            </div>
          </div>
        </div>
        <Button
          label='Schedule Video Call'
          className='w-full 2xl:mt-[76px] !text-lg mt-14'
          type='submit'
          onClick={() => formik.handleSubmit()}
        />
      </div>
      {premium && (
        <GoPremium
          open={premium}
          setOpen={setPremium}
          title='Increase Scheduling Time?'
          message='Get a premium account to unlock time limit and other features!'
        />
      )}
    </div>
  );
};

export default Schedular;

import React, { useEffect, useState } from 'react';

import ClickAwayListener from 'react-click-away-listener';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'store';

import Button from 'components/Button/Button';
import ASSETS from 'assets';
import { blockCompany, getCompanyById } from 'http/companyService';
import { ICompany } from 'types/interfaces';
import QuillViewer from 'components/QuillViewer/QuillViewer';
import { applicantRoles } from 'app.config';
const OtherEmployerProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<Dispatch>();

  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bLoading, setBloading] = useState(false);
  const [company, setCompany] = useState<ICompany | null>(null);

  const toggleMenu = () => setOpenMenu(!openMenu);
  const closeMenu = () => setOpenMenu(false);

  const { user } = useSelector((state: RootState) => state.auth);

  const handleMessage = () => {
    navigate('/chat');
  };

  const handleBlock = () => {
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Block Profile',
      message: 'Are you sure you want to block this profile?',
      buttonTitle: 'Block',
      loading: bLoading,
      onClick: async () => {
        try {
          setBloading(true);
          if (company) {
            await blockCompany(company.id);
          }
          dispatch.utils.closeActionDialog();
          navigate('/explore');
        } catch (err: any) {
        } finally {
          setBloading(false);
        }
      },
    });
  };

  const handleReport = () => {
    dispatch.utils.openActionDialog({
      open: true,
      title: 'Report Profile',
      message: 'Are you sure you want to report this profile?',
      buttonTitle: 'Report',
      onClick: () => {
        if (company) {
          dispatch.reports.setType('company');
          dispatch.reports.setId(company.id);
        }
        navigate('/report');
        dispatch.utils.closeActionDialog();
      },
    });
  };

  const handleGetCompany = async () => {
    try {
      setLoading(true);
      const { data } = await getCompanyById(id!);
      setCompany(data.data);
    } catch (err: any) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      handleGetCompany();
    }
  }, [id]);
  return (
    <div className='w-full flex-grow'>
      <div className='w-full max-w-[776px] flex flex-col gap-3 mx-auto'>
        <div className='bg-black pt-[14px] pb-6 px-6 flex flex-col items-center relative'>
          <div className='2xl:size-[162px] size-[140px] rounded-full overflow-hidden'>
            <img src={company?.company_logo || ASSETS.preview} alt='' />
          </div>
          <p className='2xl:text-2xl text-xl text-white font-medium mt-[5px]'>
            {company?.name}
          </p>
          <p className='2xl:text-base text-sm text-white font-medium'>
            {company?.location}
          </p>
          {applicantRoles.includes(user?.role || '') && (
            <div className='flex items-center gap-2 mt-2'>
              <Button
                label='Message'
                className='!bg-grey-100 !text-black !h-10 !text-lg !pl-[10px] !pr-[20px]'
                leftIcon={ASSETS.send}
                leftIconClassName='w-5 h-5'
                onClick={handleMessage}
              />
              <button className='h-10 w-10 rounded-full grid place-items-center bg-grey-100 border border-secondary'>
                <img src={ASSETS.bookmarkYellow} alt='' />
              </button>
            </div>
          )}
          {applicantRoles.includes(user?.role || '') && (
            <>
              <div className='absolute right-10 top-10'>
                <img
                  src={ASSETS.menuYellow}
                  className='cursor-pointer'
                  onClick={toggleMenu}
                  alt=''
                />
              </div>
              {openMenu && (
                <ClickAwayListener onClickAway={closeMenu}>
                  <div className='menu absolute  w-[171px] shadow-lg rounded-[8px] bg-white right-10 top-[65px] flex flex-col gap-[2px] overflow-hidden'>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[10px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleBlock}
                    >
                      <img src={ASSETS.blockCircle} alt='' />
                      <p className='text-base font-medium'>Block </p>
                    </div>
                    <div
                      className='pl-5 pr-2 flex items-center gap-[10px] py-3 hover:bg-grey-100 cursor-pointer'
                      onClick={handleReport}
                    >
                      <img src={ASSETS.flag} alt='' />
                      <p className='text-base font-medium'>Report </p>
                    </div>
                  </div>
                </ClickAwayListener>
              )}
            </>
          )}
        </div>
        {/* Company details */}
        <div className='border-[0.5px] border-grey-600 bg-white rounded-[12px] p-12'>
          <p className='2xl:text-2xl text-xl font-medium text-center'>
            Company Introduction
          </p>
          <QuillViewer description={company?.description || '{}'} />
          {/* <p className='text-base mt-[18px]'>
            At Computing Solutions, we offer unlimited perks and benefits,
            security, and IT consulting. Our approach is rooted in collaboration
            and a deep understanding of our clients' objectives. We believe in
            the transformative power of technology to drive efficiency, enhance
            user experiences, and unlock new possibilities for businesses across
            diverse industries. We are building the next gen travel platform and
            is looking for a super talented UI / UX designer to become the
            mastermind behind our visual identity, crafting experiences that
            captivate users across our mobile web and native app platforms.
          </p> */}
        </div>
        {/* Open jobs */}
        <div className='border-[0.5px] border-grey-600 bg-white rounded-[12px] p-11'>
          <p className='2xl:text-2xl text-xl font-medium text-center'>
            Open Jobs
          </p>
          <div className='flex justify-center mt-[31px] mb-[25px]'>
            <Button
              label='Open'
              className='2xl:!h-11 !h-10 !text-lg 2xl:w-[155px] w-[120px]'
            />
          </div>
          <div className='flex flex-col gap-2'>
            <div className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white'>
              <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                <img src={ASSETS.job} alt='' />
              </div>
              <div>
                <div className='flex items-center gap-2'>
                  <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                    Wardiere Software
                  </p>
                </div>
                <p className='2xl:text-lg text-base font-medium leading-6'>
                  UX UI Designer
                </p>
                <p className='2xl:text-base text-sm'>Florida, United States</p>
              </div>
            </div>
            <div className='flex items-center gap-[29px] rounded-lg shadow-mm h-[96px] px-3 overflow-auto bg-white'>
              <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
                <img src={ASSETS.job} alt='' />
              </div>
              <div>
                <div className='flex items-center gap-2'>
                  <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
                    Wardiere Software
                  </p>
                </div>
                <p className='2xl:text-lg text-base font-medium leading-6'>
                  UX UI Designer
                </p>
                <p className='2xl:text-base text-sm'>Florida, United States</p>
              </div>
            </div>
          </div>
        </div>
        <div className='h-11'></div>
      </div>
    </div>
  );
};

export default OtherEmployerProfile;

import React, { useState } from 'react';
import EditCompany from 'Dialogs/EditCompany/EditCompany';
import ASSETS from 'assets';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ICompany } from 'types/interfaces';

const EmployerProfile = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const company: ICompany = user?.company?.length ? user.company[0] : null;
  const [edit, setEdit] = useState(false);
  const handleEdit = () => {
    setEdit(true);
  };

  return (
    <div className='bg-black pt-6 pb-4 px-4 flex flex-col items-center'>
      <div className='image-picker 2xl:size-[162px] size-[130px]'>
        <img
          src={company?.company_logo || ASSETS.preview}
          className='2xl:size-[162px] size-[130px] rounded-full'
          alt=''
        />
      </div>
      <div className='flex items-center gap-1 mt-2'>
        <p className='2xl:text-2xl text-xl text-white font-bold leading-6'>
          {company?.name}
        </p>
        <img
          src={ASSETS.editCircle}
          onClick={handleEdit}
          className='cursor-pointer w-5 2xl:w-auto'
          alt=''
        />
      </div>
      <p className='2xl:text-base text-sm text-white font-bold mt-2'>
        {company?.location}
      </p>
      {edit && <EditCompany open={edit} setOpen={setEdit} />}
    </div>
  );
};

export default EmployerProfile;

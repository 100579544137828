import React from 'react';
import ASSETS from 'assets';

const CONFIG = [
  {
    img: ASSETS.job1,
    company: 'Wardiere Software',
    job: 'UX UI Designer ',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job2,
    company: 'Dragon Zone',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
  // {
  //   img: ASSETS.job3,
  //   company: 'Gamer Zone',
  //   job: 'Viusal Designer ',
  //   location: 'Florida, United States',
  // },
  {
    img: ASSETS.job4,
    company: 'Skull Expert ',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job5,
    company: 'Gitex Gaming Studio',
    job: 'UX UI Expert',
    location: 'Florida, United States',
  },
  {
    img: ASSETS.job6,
    company: 'Crabite Games',
    job: 'Graphic Designer ',
    location: 'Florida, United States',
  },
];

// eslint-disable-next-line
const ReportedJob = () => {
  return (
    <div className='rounded-lg border border-grey-600 bg-white flex items-center gap-[29px]'>
      <div className='img w-[96px] h-[96px] aspect-[1/1]'>
        <img src={ASSETS.job} alt='' />
      </div>
      <div>
        <p className='text-22 text-placeholder font-bold leading-6'>
          Wardiere Software
        </p>
        <p className='text-lg font-medium leading-6'>UX UI Designer</p>
        <p className='text-base'>Florida, United States</p>
      </div>
    </div>
  );
};

const ReportedJobs = () => {
  return (
    <div className='flex-grow px-2 2xl:mt-11 mt-6 h-0 overflow-auto flex flex-col 2xl:gap-4 gap-3 pb-3'>
      {CONFIG.map((job, i) => (
        <div
          key={i}
          className='flex 2xl:gap-[29px] gap-4 items-center rounded-lg shadow-mm min-h-[96px] px-3 overflow-auto bg-white'
        >
          <div className='img 2xl:size-[80px] size-[65px] rounded-full overflow-hidden'>
            <img src={job.img} alt='' />
          </div>
          <div>
            <p className='2xl:text-22 text-lg text-placeholder font-bold leading-6'>
              {job.company}
            </p>
            <p className='2xl:text-lg text-base font-medium leading-6'>
              {job.job}
            </p>
            <p className='2xl:text-base text-sm'>{job.location}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReportedJobs;

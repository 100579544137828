import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import styled from 'styled-components';
import SavedJobs from './components/SavedJobs';
import AppliedJobs from './components/AppliedJobs';
import JobsViewed from './components/JobsViewed';
import SavedProfiles from './components/SavedProfiles';
import Interviews from './components/Interviews';
import ReceivedJobApp from './components/ReceivedJobApp';
import EmployerInterViews from './components/EmployerInterViews';
import SelectedApplication from './components/SelectedApplication';

type TAB =
  | 'saved'
  | 'applied'
  | 'interview'
  | 'job-viewed'
  | 'saved-profile'
  | 'received-job-applications';

const Myjobs: FC = () => {
  const [tab, setTab] = useState<TAB>('saved');

  const { role } = useSelector((state: RootState) => state.auth);
  const [selectedApplication, setSelectedApplication] = useState(false);

  const handleTab = (tab: TAB) => {
    setTab(tab);
    setSelectedApplication(false);
  };

  useEffect(() => {
    if (role === 'employer') {
      setTab('received-job-applications');
    }
  }, [role]);

  return (
    <Container>
      <div className='w-full max-w-[821px] mx-auto'>
        <div className='tabs flex items-center gap-2 '>
          {role === 'applicant' && (
            <>
              <Tab
                onClick={() => handleTab('saved')}
                className={classNames(
                  '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
                  tab === 'saved'
                    ? 'bg-primary text-white'
                    : 'bg-white border border-grey-600'
                )}
              >
                <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
                  Saved Jobs (5)
                </p>
              </Tab>
              <Tab
                onClick={() => handleTab('applied')}
                className={classNames(
                  '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
                  tab === 'applied'
                    ? 'bg-primary text-white'
                    : 'bg-white border border-grey-600'
                )}
              >
                <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
                  Applied Jobs (5)
                </p>
              </Tab>
            </>
          )}
          {role === 'employer' && (
            <Tab
              onClick={() => handleTab('received-job-applications')}
              className={classNames(
                '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
                tab === 'received-job-applications'
                  ? 'bg-primary text-white'
                  : 'bg-white border border-grey-600'
              )}
            >
              <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
                Received Job Applications (4)
              </p>
            </Tab>
          )}
          <Tab
            onClick={() => handleTab('interview')}
            className={classNames(
              '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
              tab === 'interview'
                ? 'bg-primary text-white'
                : 'bg-white border border-grey-600'
            )}
          >
            <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
              Final Round Interview (2)
            </p>
          </Tab>
          {role === 'applicant' && (
            <Tab
              onClick={() => handleTab('job-viewed')}
              className={classNames(
                '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
                tab === 'job-viewed'
                  ? 'bg-primary text-white'
                  : 'bg-white border border-grey-600'
              )}
            >
              <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
                Jobs Viewed (5)
              </p>
            </Tab>
          )}
          <Tab
            onClick={() => handleTab('saved-profile')}
            className={classNames(
              '2xl:h-12 h-10 px-3 rounded-3xl  grid place-items-center ',
              tab === 'saved-profile'
                ? 'bg-primary text-white'
                : 'bg-white border border-grey-600'
            )}
          >
            <p className='2xl:text-[15px] text-[13px] text-inherit font-medium'>
              Saved Profiles (4)
            </p>
          </Tab>
        </div>
      </div>
      <div className='flex-grow h-0 overflow-auto'>
        <div className='w-full h-full max-w-[821px] mx-auto'>
          {selectedApplication ? (
            <SelectedApplication />
          ) : (
            <>
              {tab === 'received-job-applications' && (
                <ReceivedJobApp setSelected={setSelectedApplication} />
              )}
              {tab === 'saved' && <SavedJobs />}
              {tab === 'applied' && <AppliedJobs />}
              {tab === 'interview' && (
                <>
                  {role === 'employer' ? (
                    <EmployerInterViews />
                  ) : (
                    <Interviews />
                  )}
                </>
              )}
              {tab === 'job-viewed' && <JobsViewed />}
              {tab === 'saved-profile' && <SavedProfiles />}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Myjobs;

const Container = styled.div`
  width: 100%;
  margin-inline: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Tab = styled.div`
  cursor: pointer;
  transition: 0.4s ease;
`;
